import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';

const domain = process.env.REACT_APP_DOMAIN_NAME;
const apiVersion = process.env.REACT_APP_API_VERSION2;
const apiVersion3 = process.env.REACT_APP_API_VERSION3;
const domainUrl = `${domain}${apiVersion}`;
const domainUrlV3 = `${domain}${apiVersion3}`;

const axiosInstance = setupCache(
  axios.create({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHTTPRequest',
    },
  }),
  {
    ttl: 0,
  },
);

// Build HTTP authorized headers
function getAuthHeaders() {
  try {
    const authorization_token = localStorage.getItem('authorization_token');
    const authorization_guest_token = localStorage.getItem('authorization_guest_token');

    return {
      Authorization: `Bearer ${authorization_token || authorization_guest_token}`,
    };
  } catch (e) {
    console.error('axios-handler::httpHeadersGet:Error', e);
  }
}

//Authorized post
export const axiosPost = (url: string, data: any, type: string = 'v2', config?: any) =>
  axiosInstance.post((type === 'v2' ? domainUrl : domainUrlV3) + url, data, {
    ...config,
    headers: getAuthHeaders(),
  });

export const axiosPostV3 = (url: string, data: any, config?: any) =>
  axiosInstance.post(domainUrlV3 + url, data, {
    ...config,
    headers: getAuthHeaders(),
  });

//Authorized delete
export const axiosDelete = (url: string, data: any) =>
  axiosInstance.delete(domainUrl + url, {
    data: data,
    headers: getAuthHeaders(),
  });

//Authorized put
export const axiosPut = (url: string, data: any) =>
  axiosInstance.put(domainUrl + url, data, {
    headers: getAuthHeaders(),
  });

//Authorized get
export const axiosGet = (url: string, data: any, config?: Record<string | number | symbol, unknown>) =>
  axiosInstance.get(domainUrl + url, {
    params: data,
    headers: getAuthHeaders(),
    ...config,
  });

// API version 3
export const axiosGetV3 = (url: string, data: any) =>
  axiosInstance.get(domainUrlV3 + url, {
    params: data,
    headers: getAuthHeaders(),
  });
