import { Button } from '@kindlyhuman/component-library';
import { ReactNode } from 'react';

export type TabHeaderProps = {
  header: ReactNode;

  subHeader?: ReactNode;
  buttonText?: string;
  isButtonDisabled?: boolean;
};

export const TabHeader = ({ header, subHeader, buttonText, isButtonDisabled }: TabHeaderProps) => (
  <div className="flex items-center justify-between mb-6">
    <div className="font-manrope leading-tight space-y-2">
      <p className="text-2xl font-bold">{header}</p>
      {subHeader && <p className="text-neutral-700 text-sm font-medium">{subHeader}</p>}
    </div>
    {buttonText && (
      <Button variant="primary" type="submit" disabled={isButtonDisabled}>
        {buttonText}
      </Button>
    )}
  </div>
);
