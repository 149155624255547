import { useQuery } from '@tanstack/react-query';
import { axiosGet, axiosGetV3 } from '../api/axios-handler';

export interface ExternalProviderPlan {
  name: string;
  provider: string;
  id: number;
  display_name: string;
  code: string;
  provider_types: string[];
}
const getExternalProviderPlan = async (): Promise<ExternalProviderPlan[]> => {
  return await axiosGetV3(`/external_provider_plans/`, {}).then((resourceResponse) => resourceResponse.data);
};

export const useExternalProviderPlan = () =>
  useQuery<ExternalProviderPlan[]>(['externalProviderPlan'], () => getExternalProviderPlan(), {
    staleTime: Infinity,
  });

export interface RedirectUrl {
  redirect_url: string;
}

const getTelemedRedirect = async (userId: number): Promise<RedirectUrl> => {
  return await axiosGet(`/users/${userId}/telemed_sso_redirect`, {}).then((resourceResponse) => resourceResponse.data);
};

export const useTelemedRedirect = (userId: number) =>
  useQuery<RedirectUrl>(['telemedRedirect', userId], () => getTelemedRedirect(userId));

const getCounselingRedirect = async (userId: number): Promise<RedirectUrl> => {
  return await axiosGet(`/users/${userId}/counseling_sso_redirect`, {}).then(
    (resourceResponse) => resourceResponse.data,
  );
};

export const useCounselingRedirect = (userId: number) =>
  useQuery<RedirectUrl>(['counselingRedirect', userId], () => getCounselingRedirect(userId), { staleTime: Infinity });
