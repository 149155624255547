import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';

import { Content } from '@kindlyhuman/component-library';

import { useRecommendedResourcesTextSearch } from '../../hooks/useResources';
import { useExternalProviderPlan } from '../../hooks/useExternalProviderPlans';
import { useTagGroups } from '../../hooks/useTagGroups';

import {
  TelemedDetailPng,
  CounselingDetailPng,
  PLUSCare,
  PLUSCounseling,
  PLUSVirtualPrimaryCare,
  PLUSVirtualUrgentCare,
} from '../../assets';

import { ROUTE_PATH } from '../../routes/route-paths';

import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { Spinner } from '../../components/common';
import { useUser } from '../../hooks/useUser';
import { ResourceTypes } from '../../components/resources/resources-section';
import { useMediaQuery } from '../../hooks/useMediaQuery';

export const ResourcePage = () => {
  const { data: user, isLoading: userLoading } = useUser();
  const state = useLocation().state as { query: string; from: ResourceTypes } | undefined;
  const { search: urlQuery } = useLocation();
  const deepLinkSearch = new URLSearchParams(urlQuery).get('search');

  const navigate = useHistory();

  const { getParentByParentId } = useTagGroups();

  const dfMdMedia = useMediaQuery('md');
  const { data: providerPlans, isLoading: loadingProviderPlan } = useExternalProviderPlan();

  // we defer first to deepLinkSearch, then to the state query, then to the user search query
  const textSearchResourcesQuery = useRecommendedResourcesTextSearch({
    limit: 100,
    query: deepLinkSearch || state?.query || user?.search_queries?.[0]?.query,
  });

  let displayedResources = textSearchResourcesQuery.data?.data;
  switch (state?.from) {
    case ResourceTypes.GROUP:
      displayedResources = textSearchResourcesQuery?.data?.data?.filter((resource) => resource.client_id);
      break;
    case ResourceTypes.GLOBAL:
      displayedResources = textSearchResourcesQuery?.data?.data?.filter((resource) => !resource.client_id);
      break;
    default:
      break;
  }

  if (loadingProviderPlan || textSearchResourcesQuery.isLoading || userLoading) return <Spinner />;

  const providerPlan = providerPlans?.find((plan) => {
    return plan.id === user?.caller_role.active_subscription?.package.external_plan_id;
  });

  return (
    <>
      {!userLoading && !state?.query && !user?.search_queries?.[0] ? <Redirect to={ROUTE_PATH.LOGIN} /> : null}
      <div className="w-full h-screen flex flex-col overflow-hidden bg-neutral-700 bg-opacity-5">
        <DesktopMenuHeader />
        <MobileMenuHeader prompt={true} onBack={() => navigate.goBack()} />
        <div className="w-full overflow-y-auto">
          <div className="w-100 mx-auto py-5 md:w-auto md:max-w-7xl md:px-6">
            <div className="px-4 pb-7 space-y-2 md:px-0 md:pb-8">
              <p className="text-gray-800 text-2xl font-bold leading-loose">
                {!dfMdMedia ? 'Available Resources' : 'Resources list'}
              </p>
              <p className="text-stone-500 text-sm font-medium leading-tight md:text-base">
                Resources based on your areas of interest
              </p>
            </div>
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 md:gap-6">
              {providerPlan?.provider_types.includes('primary') && (
                <Link to={ROUTE_PATH.TELEMED} className="bg-white border-neutral-200 md:p-3 md:rounded-lg md:border">
                  <Content
                    caption="Find out more about +Telemed today."
                    displayName="+Telemed"
                    imageUrl={providerPlan.provider === 'dialcare' ? TelemedDetailPng : PLUSCare}
                    variant={dfMdMedia ? 'desktopWide' : 'wide'}
                  />
                </Link>
              )}
              {providerPlan?.provider_types.includes('counseling') && (
                <Link to={ROUTE_PATH.COUNSELING} className="bg-white border-neutral-200 md:p-3 md:rounded-lg md:border">
                  <Content
                    caption="Find out more about +Counseling now"
                    displayName="+Counseling"
                    imageUrl={providerPlan.provider === 'dialcare' ? CounselingDetailPng : PLUSCounseling}
                    variant={dfMdMedia ? 'desktopWide' : 'wide'}
                  />
                </Link>
              )}
              {!userLoading && providerPlan?.provider_types.includes('crisis_lite') && (
                <Link to={ROUTE_PATH.COUNSELING} className="bg-white border-neutral-200 md:p-3 md:rounded-lg md:border">
                  <Content
                    caption="Find out more about Primary care now"
                    displayName="+Primary Care"
                    imageUrl={PLUSVirtualPrimaryCare}
                    variant={dfMdMedia ? 'desktopWide' : 'wide'}
                  />
                </Link>
              )}
              {!userLoading && providerPlan?.provider_types.includes('crisis') && (
                <Link to={ROUTE_PATH.COUNSELING} className="bg-white border-neutral-200 md:p-3 md:rounded-lg md:border">
                  <Content
                    caption="Find out more about Urgent Care now"
                    displayName="+Urgent Care"
                    imageUrl={PLUSVirtualUrgentCare}
                    variant={dfMdMedia ? 'desktopWide' : 'wide'}
                  />
                </Link>
              )}
              {displayedResources?.map((resource) => (
                <Link
                  key={`${resource.id} - ${resource.url}`}
                  to={`${ROUTE_PATH.RESOURCE}/${resource.id}_${resource.name.replaceAll(' ', '-')}`}
                  className="bg-white border-neutral-200 md:p-3 md:rounded-lg md:border"
                >
                  <Content
                    caption={resource.description.substring(0, 50) + '...'}
                    displayName={resource.name}
                    imageUrl={resource.image_url}
                    variant={dfMdMedia ? 'desktopWide' : 'wide'}
                    // @ts-ignore
                    areas={resource.challenge_areas?.map((id) => getParentByParentId(id)?.name)}
                  />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
