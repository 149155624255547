import { useHistory } from 'react-router-dom';
import { Button } from '@kindlyhuman/component-library';
import { ChangePasswordForm } from '../../components/common/change_password_form';
import { DesktopMenuHeader, MobileMenuHeader } from '../../components/MenuHeader';

export const ChangePasswordPage = () => {
  const navigate = useHistory();

  return (
    <div className="bg-neutral-700 min-h-screen bg-opacity-5">
      <DesktopMenuHeader />
      <MobileMenuHeader prompt onBack={() => navigate.goBack()} />
      <Header />
      <ChangePasswordForm
        className="bg-white py-8 px-3 space-y-3"
        bottomActionButton={({ isDirty, isSubmitting, isValid }) => (
          <div className="fixed bottom-0 left-0 right-0 py-4 bg-white border-t border-neutral-200 flex items-center justify-center">
            <Button
              variant="primary"
              type="submit"
              disabled={!isDirty || isSubmitting || !isValid}
              loading={isSubmitting}
            >
              Change password
            </Button>
          </div>
        )}
      />
    </div>
  );
};

const Header = () => (
  <div className=" px-4 py-4 flex-col justify-center items-start gap-8 flex">
    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
      <div className="self-stretch text-gray-800 text-2xl font-bold leading-loose">Change Password</div>
    </div>
  </div>
);
