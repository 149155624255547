import React, { useEffect, useState } from 'react';
import { Button, Input, EyeIcon, LockIcon, analytics, EventTypes } from '@kindlyhuman/component-library';

import { useUser } from '../../../hooks/useUser';
import { Checkbox, Modal, Typography } from '@mui/material';
import { CloseIcon } from '../../../components/common/svgs';
import { useAppSettings } from '../../../hooks/useAppSettings';
import { WelcomeSlogan } from '../../../components/common/WelcomeSlogan';
import moment from 'moment';
import { useHubspotEventMutation } from '../../../hooks/useEvent';

export const SetPassword: React.FC<{
  onComplete: () => void;
}> = ({ onComplete }) => {
  const { updateUser, data: user } = useUser();
  const { data: appSettings } = useAppSettings();
  const hubspotMutation = useHubspotEventMutation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [termsConditionsChecked, setTermsConditionsChecked] = useState(false);
  const [messaging, setMessaging] = useState(false);
  const [modal, setModal] = useState<'t&c' | 'privacy' | undefined>();
  const [skipPassword, setSkipPassword] = useState(false);

  const handleCheckboxChange = () => {
    setTermsConditionsChecked(!termsConditionsChecked);
  };

  const validatePassword = () => {
    if (skipPassword) return;

    const passwordRegex = /^(?=.*\d).{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError('Password must be at least 8 characters long and contain a number');
    } else {
      setPasswordError('');
    }
  };

  const validateConfirmPassword = () => {
    if (skipPassword) return;

    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords must match');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleContinue = async () => {
    const onSuccess = () => {
      const age = Math.floor(moment().diff(user?.date_of_birth, 'years') / 10) * 10;
      hubspotMutation.mutate({ event: 'onboarding_completed' });
      analytics.trackEvent(
        EventTypes.STARTED_ONBOARDING,
        user?.client_name,
        user?.gender,
        // getting the nearest decade
        // check if the user has a date of birth before calculating age
        age > 150 ? undefined : age,
      );
      onComplete();
    };

    if (skipPassword) {
      onSuccess();
      return;
    } else {
      setShowPassword(false);
      updateUser.mutate(
        {
          password: password,
        },
        {
          onSuccess: onSuccess,
          onError: (error: any) => {
            if (error?.response?.data?.description) {
              setPasswordError(error.response.data.description);
            } else {
              setPasswordError('');
            }
          },
        },
      );
    }
  };

  useEffect(() => {
    if (user?.is_sso || !user?.needs_password) {
      setSkipPassword(true);
    }
  }, [user]);

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 md:h-screen md:w-screen md:fixed md:top-0 md:left-0 md:z-20">
        <WelcomeSlogan className="hidden md:block" />
        <div className="pt-8 pb-32 md:bg-whiteSmoke md:flex md:items-center md:justify-center">
          <div className="px-5 space-y-4 lg:px-30 md:px-15">
            {!skipPassword && (
              <div className="space-y-1">
                <p className="text-gray-900 text-2xl not-italic font-bold leading-8 font-manrope">
                  Finish creating your account
                </p>
                <p className="text-gray-900 text-base not-italic font-normal leading-6">Set a password below</p>
              </div>
            )}
            <div className="space-y-4">
              {!skipPassword && (
                <>
                  {/* This is to help tools like 1password know what the email is */}
                  <Input
                    required
                    className="hidden"
                    label="username"
                    id="username"
                    name="username"
                    placeholder="Username..."
                    autoComplete="username"
                    defaultValue={user?.email_address}
                    onIconClick={() => {}}
                  />
                  <Input
                    required
                    endIcon={<EyeIcon />}
                    label="Password"
                    id="password"
                    placeholder="Password..."
                    autoComplete="new-password"
                    name="password"
                    startIcon={<LockIcon />}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={validatePassword}
                    error={passwordError !== ''}
                    helperText={passwordError}
                    type={showPassword ? 'text' : 'password'}
                    onIconClick={() => setShowPassword(!showPassword)}
                  />
                </>
              )}
              {!skipPassword && (
                <Input
                  required
                  id="password2"
                  endIcon={<EyeIcon />}
                  label="Re-enter Password"
                  placeholder="Re-enter your Password..."
                  startIcon={<LockIcon />}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onBlur={validateConfirmPassword}
                  error={confirmPasswordError !== ''}
                  helperText={confirmPasswordError}
                  type={showPasswordConfirm ? 'text' : 'password'}
                  onIconClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                />
              )}
              <div>
                <label className="block">
                  <Checkbox checked={termsConditionsChecked} onChange={handleCheckboxChange} />I have read and accept
                  these{' '}
                  <Typography component="a" href="#" onClick={() => setModal('t&c')} color="primary">
                    Terms & Conditions
                  </Typography>{' '}
                  and{' '}
                  <Typography component="a" href="#" onClick={() => setModal('privacy')} color="primary">
                    Privacy Policy
                  </Typography>
                  .
                </label>
                <label className="block">
                  <Checkbox checked={messaging} onChange={() => setMessaging(!messaging)} />I agree to receive
                  communications from Kindly Human about this service.
                </label>
              </div>
              <Button
                className="w-full"
                variant="primary"
                onClick={handleContinue}
                disabled={
                  skipPassword
                    ? !termsConditionsChecked || updateUser.isLoading
                    : passwordError !== '' ||
                      confirmPasswordError !== '' ||
                      !termsConditionsChecked ||
                      updateUser.isLoading
                }
                loading={updateUser.isLoading}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal open={modal === 't&c'} onClose={() => setModal(undefined)} aria-labelledby="terms-modal-title">
        <div className="bg-white p-4 overflow-auto scrollable-height m-4">
          <div className="flex justify-end">
            <CloseIcon className="text-primary cursor-pointer w-4 h-4" onClick={() => setModal(undefined)} />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: appSettings?.TERMS_OF_SERVICE ?? '',
            }}
          ></div>
          <Button onClick={() => setModal(undefined)} className="w-full my-4" variant="primary">
            Close
          </Button>
        </div>
      </Modal>

      <Modal open={modal === 'privacy'} onClose={() => setModal(undefined)} aria-labelledby="privacy-modal-title">
        <div className="bg-white p-4 overflow-auto scrollable-height m-4">
          <div className="flex justify-end">
            <CloseIcon className="text-primary cursor-pointer w-4 h-4" onClick={() => setModal(undefined)} />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: appSettings?.PRIVACY_POLICY ?? '',
            }}
          ></div>
          <Button onClick={() => setModal(undefined)} className="w-full my-4" variant="primary">
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};
