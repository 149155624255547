import { Link } from 'react-router-dom';

import { ExitIcon, Dialog, DialogProps, CallCard, Button, SpinnerIcon } from '@kindlyhuman/component-library';

import { ActiveCall, useEndCallMutation } from '../../../hooks/useCalls';
import { useUser } from '../../../hooks/useUser';
import { useTagGroups } from '../../../hooks/useTagGroups';
import { usePhoneFormat } from '../../../hooks/usePhoneFormat';
import moment from 'moment';
import { ROUTE_PATH } from '../../../routes/route-paths';
import { CALL_UNITS_CALL_DEFAULT_LENGTH } from '../../scheduling-modal/scheduling-modal';

export interface ActiveCallModalProps extends DialogProps {
  activeCall?: ActiveCall;
}

export const ActiveCallModal = ({ activeCall, onClose, ...rest }: ActiveCallModalProps) => {
  const { data: user, isLoading: isUserLoading } = useUser();
  const { data: tagGroups, isLoading: isTagGroupsLoading } = useTagGroups();
  const formattedPhone = usePhoneFormat(activeCall?.phone_number ?? '');
  const endCallMutation = useEndCallMutation();
  const isCallUnits = user?.caller_role.is_call_units;

  return (
    <Dialog
      className="rounded-lg w-full max-w-[620px] shadow-modal p-0 bg-white backdrop:bg-modalBackdropColor"
      onClose={onClose}
      {...rest}
    >
      <div className="flex items-center justify-between p-6 border-b border-b-neutral-200">
        <div className="space-y-2">
          <p className="text-2xl font-bold text-dark">Active call</p>
          <p className="text-sm font-medium text-neutral-700">This call is starting soon or currently active</p>
        </div>
        <button onClick={onClose}>
          <ExitIcon color="#22282F" />
        </button>
      </div>
      <div className="font-manrope">
        {(isUserLoading || isTagGroupsLoading) && activeCall ? (
          <div className="w-full h-50 flex items-center justify-center">
            <SpinnerIcon />
          </div>
        ) : (
          <>
            <div className="py-8 px-6 space-y-8">
              <CallCard
                variant="scheduled"
                className="max-w-full border-0"
                available
                // @ts-ignore
                areaLabelVariants={
                  tagGroups
                    ?.filter((tagGroup) => activeCall?.request.tag_group_ids?.includes(tagGroup.id))
                    ?.map((tagGroup) => tagGroup.name) ?? []
                }
                // @ts-ignore
                img={activeCall?.listener_role.profile_photo_square_file_url}
                // @ts-ignore
                title={activeCall?.listener_role.user.display_name}
                secondaryText={moment(activeCall?.request.created_at).format(`[Now,] hh:mm a`)}
              />
              {!user?.onUnlimitedPlan && (
                <div className="flex items-center justify-between text-sm font-bold">
                  Your balance
                  <span className="text-neutral-700 font-medium">
                    {isCallUnits
                      ? `${user?.caller_role.payment_data.available_minutes / CALL_UNITS_CALL_DEFAULT_LENGTH} calls`
                      : `${user?.caller_role.payment_data.available_minutes} minutes`}
                  </span>
                </div>
              )}
              <div className="flex items-center justify-between text-sm font-bold">
                Disconnected? Call
                <a href={`tel:${activeCall?.phone_number}`} className="text-[#240089]">
                  {formattedPhone}
                </a>
              </div>
            </div>
            <div className="flex items-center justify-between border-t border-t-neutral-200 p-6">
              <Button
                onClick={async () => {
                  if (activeCall) {
                    await endCallMutation.mutateAsync(activeCall?.id);
                  }

                  onClose();
                }}
                variant="secondary"
                loading={endCallMutation.isLoading}
              >
                End Call
              </Button>
              <Link to={`${ROUTE_PATH.HELP}?option=call-report&call_id=${activeCall?.id}`}>
                <Button variant="secondary">Report</Button>
              </Link>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};
