import { useQuery } from '@tanstack/react-query';
import { axiosGetV3 } from '../api/axios-handler';
import logoSquare from '../assets/images/logo_square.jpg';
import { analytics, EventTypes } from '@kindlyhuman/component-library';

export const resourceKeys = {
  all: ['resources'] as const,
};

export interface Resource {
  client_id: number | null;
  description: string;
  id: number;
  image_url: string;
  name: string;
  notes: string;
  phone_number: string;
  sort_weight: number;
  status: string;
  url: string;
  url_description: string;
  subject_areas: number[] | null;
  challenge_areas: number[] | null;
  long_description?: string;
}

export interface ResourcesResponse {
  data: Resource[];
  count: number;
}

const getResources = async (): Promise<ResourcesResponse> => {
  return await axiosGetV3(`/resources/recommended`, {}).then((resourceResponse) => resourceResponse.data);
};

export const useResources = (fetchData: boolean = true) => {
  return useQuery<ResourcesResponse | null>(
    resourceKeys.all,
    () => (fetchData ? getResources() : Promise.resolve(null)),
    {
      staleTime: Infinity,
      enabled: fetchData,
    },
  );
};

export const useRecommendedResourcesTextSearch = ({
  limit = 10,
  page = undefined,
  query,
  enabled = true,
  onComplete,
}: {
  limit: number;
  page?: number;
  query?: string;
  enabled?: boolean;
  onComplete?: () => void;
}) => {
  return useQuery<ResourcesResponse>(
    ['textSearchResources', query, limit, page],
    () =>
      axiosGetV3('/resources/search', {
        query,
        limit,
        page,
      }).then((response) => response.data),
    {
      enabled,
      staleTime: Infinity,
      onSuccess: onComplete,
      select: (data: ResourcesResponse) => {
        return {
          ...data,
          data: data.data.map((resource) => {
            resource.image_url = resource.image_url || logoSquare;
            return resource;
          }),
        };
      },
    },
  );
};

const getResourceByID = async (id: number): Promise<Resource> => {
  analytics.trackEvent(EventTypes.VISIT_RESOURCE, id);
  return await axiosGetV3(`/resources/${id}`, {}).then((resourceResponse) => resourceResponse.data);
};

export const useResourceByID = (id: number | undefined) => {
  return useQuery<Resource>(
    [...resourceKeys.all, id],
    () => (id ? getResourceByID(id) : Promise.resolve({} as Resource)),
    {
      staleTime: Infinity,
      select: (data: Resource) => {
        return { ...data, image_url: data.image_url || logoSquare };
      },
    },
  );
};
