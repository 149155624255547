import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { twMerge } from 'tailwind-merge';

import { Content, Experience, LeftArrowIcon } from '@kindlyhuman/component-library';

import { Spinner } from '../../components/common';
import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { SchedulingModal } from '../../components/scheduling-modal/scheduling-modal';
import { BlockHorizontalScroll } from '../../components/common/block_horizontal_scroll';
import { FormBlock, formBlockClassName } from '../../components/common/form_block';

import {
  PeerDetail,
  PeerDetailsExperience,
  peersQueryKeys,
  useAddFavoritePeer,
  usePeerDetail,
  useRemoveFavoritePeer,
} from '../../hooks/usePeers';
import { useListenerAudioPlayMutation, useListenerAudioPlaybackMutation } from '../../hooks/useListenerAudio';
import {
  FavoriteExperiencesTopicTag,
  useFavoriteExperience,
  useUnfavoriteExperience,
} from '../../hooks/useExperiences';
import { ResourcesResponse, useRecommendedResourcesTextSearch, useResources } from '../../hooks/useResources';
import { useTagGroups } from '../../hooks/useTagGroups';
import { useAppSettings } from '../../hooks/useAppSettings';

import { ROUTE_PATH } from '../../routes/route-paths';

import PeerDetailCard from './peer-detail-card';
import { useProfilePageView } from '../../hooks/usePageView';
import { ResourcesSection } from '../../components/resources/resources-section';
import { useExternalProviderPlan } from '../../hooks/useExternalProviderPlans';
import { useUser } from '../../hooks/useUser';
import { Features, useFeatureFlag } from '../../hooks/useFeatureFlag';

export const PeerDetailPage = () => {
  const [playerOpen, setPlayerOpen] = useState<number>();
  const [playing, setPlaying] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [isNow, setIsNow] = useState(false);
  const navigate = useHistory();
  const viewedPageMutation = useProfilePageView();
  const { data: user, isLoading: userLoading } = useUser();
  const textSearchResourcesQuery = useRecommendedResourcesTextSearch({
    limit: 100,
    query: user?.search_queries![0].query,
    enabled: true,
  });
  const { data: resources } = useResources();
  const groupLevelResources = textSearchResourcesQuery?.data?.data?.filter((resource) => resource.client_id);
  const globalResources = textSearchResourcesQuery?.data?.data?.filter((resource) => !resource.client_id);
  const { data: providerPlans } = useExternalProviderPlan();
  const textSearchFlag = useFeatureFlag(Features.TEXT_SEARCH);
  const providerPlan =
    providerPlans?.find((plan) => {
      return plan.id === user?.caller_role.active_subscription?.package.external_plan_id;
    }) ?? null;

  const searchParams = new URLSearchParams(useLocation().search);
  const listenerId: number = Number(searchParams.get('listenerId'));

  const { data: peerDetails, isLoading: peerLoading } = usePeerDetail(listenerId);
  const { isLoading: settingsLoading } = useAppSettings();
  const addFavoritePeer = useAddFavoritePeer(peersQueryKeys.peerDetail(listenerId));
  const removeFavoritePeer = useRemoveFavoritePeer(peersQueryKeys.peerDetail(listenerId));

  const dataLoading = userLoading || peerLoading || settingsLoading;

  const canTakeCalls = Boolean(peerDetails?.can_take_calls && peerDetails?.is_active);
  const isFollowing = Boolean(peerDetails?.favorite_id);

  const handleFollowing = async () => {
    const favoriteId = peerDetails?.favorite_id ?? undefined;

    if (favoriteId) {
      await removeFavoritePeer.mutateAsync(listenerId);
    } else {
      await addFavoritePeer.mutateAsync(listenerId);
    }
  };

  const hasMyStory = (peerDetails?: PeerDetail) => {
    const tagNames = peerDetails?.listener_audio?.flatMap(
      (listener_audio: PeerDetailsExperience) =>
        listener_audio.topic_tags?.map((topic_tags: FavoriteExperiencesTopicTag) => topic_tags?.tag?.name) ?? [],
    );
    const filterTagNames = tagNames?.filter((item: string) => item === 'My Story');
    if (filterTagNames && filterTagNames?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(
    () => {
      viewedPageMutation.mutate({ listenerRoleId: listenerId });
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (dataLoading) {
    return (
      <div className="w-full z-10 overflow-y-auto min-h-screen bg-neutral-700 bg-opacity-5">
        <DesktopMenuHeader />
        <MobileMenuHeader onBack={() => navigate.goBack()} prompt={true} />
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="w-full z-10 overflow-y-auto min-h-screen bg-neutral-700 bg-opacity-5">
        <DesktopMenuHeader />
        <MobileMenuHeader onBack={() => navigate.goBack()} prompt={true} />
        <div className="mx-auto md:max-w-7xl md:px-6">
          <button onClick={() => navigate.goBack()} className="hidden md:flex items-center gap-2 my-6">
            <LeftArrowIcon />
            Back
          </button>
          <PeerDetailCard
            peerDetails={peerDetails}
            canTakeCalls={canTakeCalls}
            handleFollowing={handleFollowing}
            isFollowing={isFollowing}
            onScheduleCallButtonClick={() => {
              setScheduleModal(true);
            }}
            onConnectNowButtonClick={() => {
              setIsNow(true);
              setScheduleModal(true);
            }}
          />
          <div className="mt-2 space-y-2 md:mt-0 md:space-y-6 md:pb-34">
            {peerDetails?.about_me && (
              <AboutMeSection aboutMe={peerDetails.about_me} name={peerDetails.user.display_name} />
            )}
            {hasMyStory(peerDetails) && (
              <StorySection
                name={peerDetails?.user.display_name}
                experience={peerDetails?.listener_audio}
                imageUrl={peerDetails?.profile_photo_square_file_url}
                playerOpen={playerOpen}
                setPlayerOpen={setPlayerOpen}
                listenerId={listenerId}
                playing={playing}
                setPlaying={setPlaying}
              />
            )}
            {peerDetails?.listener_audio && (
              <ExperienceSection
                listenerName={peerDetails.user.display_name}
                experiences={peerDetails?.listener_audio}
                imageUrl={peerDetails.profile_photo_square_file_url}
                isAvailable={peerDetails.available_now}
                isListener={canTakeCalls}
                playerOpen={playerOpen}
                setPlayerOpen={setPlayerOpen}
                listenerId={listenerId}
                playing={playing}
                setPlaying={setPlaying}
              />
            )}
            {textSearchFlag.enabled ? (
              <>
                {localStorage.getItem('hasGroupResources') === 'true' && (
                  <ResourcesSection
                    resourcesQuery={textSearchResourcesQuery}
                    groupLevelResources={groupLevelResources}
                    title="My Resources"
                    providerPlan={providerPlan}
                    textSearchFlag={textSearchFlag.enabled}
                  />
                )}
                <ResourcesSection
                  query={user?.search_queries![0].query}
                  resourcesQuery={textSearchResourcesQuery}
                  availableResources={globalResources}
                  textSearchFlag={textSearchFlag.enabled}
                  title={
                    groupLevelResources || localStorage.getItem('hasGroupResources') === 'true'
                      ? 'More Resources'
                      : 'Resources'
                  }
                />
              </>
            ) : (
              resources && <ResourceSection resources={resources} peerDetails={peerDetails} />
            )}
          </div>
        </div>
        <div className="h-6" />
        {playerOpen && <div className="h-24" />}
      </div>
      {scheduleModal && (
        <SchedulingModal
          open={scheduleModal}
          isNow={isNow}
          onExit={() => {
            setScheduleModal(false);
            setIsNow(false);
          }}
          listenerId={listenerId}
        />
      )}
    </>
  );
};

const AboutMeSection: React.FC<{
  name: string;
  aboutMe: string;
}> = ({ name, aboutMe }) => (
  <FormBlock className="border-t border-b md:space-y-6">
    <div className="text-gray-800 text-xl font-bold md:text-2xl">About {name}</div>
    <div className="flex-col justify-start items-start gap-3 flex">
      <div className="pl-3 py-1 border-l-2 border-lime-300 justify-start items-start gap-2 inline-flex">
        <div className="grow shrink basis-0 text-gray-800 text-sm font-medium leading-tight">“{aboutMe}...”</div>
      </div>
    </div>
  </FormBlock>
);

const StorySection: React.FC<{
  name?: string;
  experience?: PeerDetailsExperience[];
  imageUrl?: string;
  playerOpen: number | undefined;
  setPlayerOpen: React.Dispatch<React.SetStateAction<number | undefined>>;
  playing: boolean;
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  listenerId: number;
}> = ({ name, experience, imageUrl, playerOpen, setPlayerOpen, playing, setPlaying, listenerId }) => {
  const myStory = experience?.find((listener_audio: PeerDetailsExperience) =>
    listener_audio.topic_tags?.find((topic_tags: FavoriteExperiencesTopicTag) => topic_tags?.tag.name === 'My Story'),
  );
  const queryClient = useQueryClient();
  const playBackMutation = useListenerAudioPlaybackMutation();
  const playMutation = useListenerAudioPlayMutation();
  const [currentPlaybackId, setCurrentPlaybackId] = useState<number>();
  const favoriteMutation = useFavoriteExperience();
  const unfavoriteMutation = useUnfavoriteExperience();
  const { getTagGroupByTagId } = useTagGroups();

  const audioStopAll = () => {
    document.querySelectorAll('audio').forEach((el) => {
      el.pause();
      el.currentTime = 0;
    });
  };

  return (
    <FormBlock className="border-t border-b md:space-y-6">
      <div className="text-gray-800 text-xl font-bold md:text-2xl">{name}’s Story</div>
      <div className="flex-col justify-start items-start gap-3 flex w-full">
        {myStory && (
          <Experience
            experienceId={myStory.id}
            variant="myStory"
            imageUrl={imageUrl || ''}
            displayName={myStory.name}
            audioUrl={myStory.file_url}
            caption={myStory.excerpt}
            favorited={myStory.is_favorite}
            duration={myStory.duration}
            hideFollow
            // @ts-ignore
            areas={[...new Set(myStory.topic_tags?.map((x) => getTagGroupByTagId(x.tag.id)?.name))]}
            onFollow={() => {
              if (myStory.is_favorite) {
                unfavoriteMutation.mutate(
                  {
                    tagId: myStory.topic_tags[0].id,
                    listenerId: listenerId,
                    audioId: myStory.id,
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries(['peerDetail', listenerId]);
                    },
                  },
                );
              } else {
                favoriteMutation.mutate(
                  {
                    tagId: myStory.topic_tags[0].id,
                    listenerId: listenerId,
                    audioId: myStory.id,
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries(['peerDetail', listenerId]);
                    },
                  },
                );
              }
            }}
            playing={playerOpen === myStory.id && playing}
            setPlaying={setPlaying}
            onPlay={() => {
              audioStopAll();
              setPlayerOpen(myStory.id);
              playMutation.mutate(
                {
                  audioId: myStory.id,
                  listenerId,
                  tagIds: myStory.topic_tags.map((tag) => tag.id),
                },
                {
                  onSuccess: (listenerAudioPlayback) => {
                    setCurrentPlaybackId(listenerAudioPlayback.id);
                  },
                },
              );
            }}
            key={myStory.id}
            openPlayer={playerOpen === myStory.id}
            setOpenPlayer={setPlayerOpen}
            onTimeUpdate={(e: React.SyntheticEvent<HTMLAudioElement>) => {
              const percent = Math.round((e.currentTarget.currentTime / myStory.duration) * 100);
              // if the percent is divisible by 10
              if (percent % 10 === 0) {
                currentPlaybackId &&
                  playBackMutation.mutate({
                    audioId: currentPlaybackId,
                    percentCompleted: percent,
                  });
              }
            }}
          />
        )}
      </div>
    </FormBlock>
  );
};

const ExperienceSection: React.FC<{
  experiences: PeerDetailsExperience[];
  imageUrl: string;
  isAvailable: boolean;
  isListener: boolean;
  playerOpen: number | undefined;
  listenerId: number;
  listenerName: string;
  setPlayerOpen: React.Dispatch<React.SetStateAction<number | undefined>>;
  playing: boolean;
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  experiences,
  imageUrl,
  isAvailable,
  isListener,
  playerOpen,
  setPlayerOpen,
  listenerName,
  listenerId,
  playing,
  setPlaying,
}) => {
  const queryClient = useQueryClient();
  const playBackMutation = useListenerAudioPlaybackMutation();
  const playMutation = useListenerAudioPlayMutation();
  const [currentPlaybackId, setCurrentPlaybackId] = useState<number>();
  const favoriteMutation = useFavoriteExperience();
  const unfavoriteMutation = useUnfavoriteExperience();
  const { getTagGroupByTagId } = useTagGroups();

  const audioStopAll = () => {
    document.querySelectorAll('audio').forEach((el) => {
      el.pause();
      el.currentTime = 0;
    });
  };

  // Remove the My Story experience from the list — this prevents a bug where the duplicate ID was causing the player not to sync to the story.
  const experienceList = experiences.filter(
    (experience) =>
      !experience.topic_tags?.find((topic_tags: FavoriteExperiencesTopicTag) => topic_tags?.tag.name === 'My Story'),
  );

  return (
    <BlockHorizontalScroll
      title={`${listenerName}’s Experiences`}
      className={twMerge(formBlockClassName, 'p-0 md:px-0')}
      showViewAll={false}
    >
      {experienceList.map((experience) => (
        <Experience
          experienceId={experience.id}
          variant="medium"
          imageUrl={imageUrl}
          displayName={experience.name}
          audioUrl={experience.file_url}
          caption={experience.excerpt}
          favorited={experience.is_favorite}
          duration={experience.duration}
          hideFollow
          // @ts-ignore
          areas={[...new Set(experience.topic_tags?.map((x) => getTagGroupByTagId(x.tag.id)?.name))]}
          onFollow={() => {
            if (experience.is_favorite) {
              unfavoriteMutation.mutate(
                {
                  tagId: experience.topic_tags[0].id,
                  listenerId: listenerId,
                  audioId: experience.id,
                },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries(['peerDetail', listenerId]);
                  },
                },
              );
            } else {
              favoriteMutation.mutate(
                {
                  tagId: experience.topic_tags[0].id,
                  listenerId: listenerId,
                  audioId: experience.id,
                },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries(['peerDetail', listenerId]);
                  },
                },
              );
            }
          }}
          playing={playerOpen === experience.id && playing}
          setPlaying={setPlaying}
          onPlay={() => {
            audioStopAll();
            setPlayerOpen(experience.id);
            playMutation.mutate(
              {
                audioId: experience.id,
                listenerId,
                tagIds: experience.topic_tags.map((tag) => tag.id),
              },
              {
                onSuccess: (listenerAudioPlayback) => {
                  setCurrentPlaybackId(listenerAudioPlayback.id);
                },
              },
            );
          }}
          key={experience.id}
          openPlayer={playerOpen === experience.id}
          setOpenPlayer={setPlayerOpen}
          onTimeUpdate={(e: React.SyntheticEvent<HTMLAudioElement>) => {
            const percent = Math.round((e.currentTarget.currentTime / experience.duration) * 100);
            // if the percent is divisible by 10
            if (percent % 10 === 0) {
              currentPlaybackId &&
                playBackMutation.mutate({
                  audioId: currentPlaybackId,
                  percentCompleted: percent,
                });
            }
          }}
          available={isAvailable}
          isPeerListener={isListener}
        />
      ))}
    </BlockHorizontalScroll>
  );
};

export const ResourceSection: React.FC<{
  resources: ResourcesResponse;
  peerDetails?: PeerDetail;
}> = ({ resources, peerDetails }) => {
  const history = useHistory();
  const { getParentByParentId } = useTagGroups();
  const availableResources =
    resources.data &&
    resources.data
      .filter((resource) => resource.image_url)
      .sort((a, b) => (a.id === peerDetails?.resource_id ? -1 : b.id === peerDetails?.resource_id ? 1 : 0));
  if (!availableResources || availableResources.length === 0) {
    return null;
  }
  return (
    <BlockHorizontalScroll
      title="More help options"
      subtitle={
        availableResources &&
        `${availableResources.length} resource${availableResources.length > 1 ? 's' : ''} to explore`
      }
      className={twMerge(formBlockClassName, 'p-0 md:px-0')}
      onViewAll={() => {
        history.push({ pathname: ROUTE_PATH.RESOURCES });
      }}
    >
      {availableResources &&
        availableResources
          .slice(0, 6) // we only want first five resources on this page
          .map((resource) => (
            <Link key={resource.id} to={`${ROUTE_PATH.RESOURCE}/${resource.id}_${resource.name.replaceAll(' ', '-')}`}>
              <Content
                caption={resource.description.substring(0, 50) + '...'}
                displayName={resource.name}
                imageUrl={resource.image_url}
                variant={'medium'}
                // @ts-ignore
                areas={resource.challenge_areas?.map((id) => getParentByParentId(id)?.name)}
              />
            </Link>
          ))}
    </BlockHorizontalScroll>
  );
};
