import { Avatar, Button, Dialog, ExitIcon, LoadingDotsIcon, LogoWideIcon } from '@kindlyhuman/component-library';

import { useEffect, useRef } from 'react';
import { JaniceAvatar } from '../../assets';
import { useLockBody } from '../../hooks/useLockBody';
import { useMediaQuery } from '../../hooks/useMediaQuery';

export interface SearchQueryModalProps {
  onClose: () => void;
  needsSearchQuery?: boolean;
  search: (text: string) => void;
  isLoading: boolean;
}

export const SearchQueryModal = ({ onClose, needsSearchQuery = false, search, isLoading }: SearchQueryModalProps) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  useLockBody(true);
  const dfMdMedia = useMediaQuery('md');

  // this dosn't work, but without it the textarea takes two clicks to focus. no idea why
  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  const content_wide = (
    <>
      <div className="w-full h-64 md:h-40 bg-[#081D40] relative mb-32">
        {' '}
        {/* modal container */}
        <div className="flex flex-row">
          <Avatar className="pl-4 pt-12 md:pt-12 mx-10" variant={'huge'} image={JaniceAvatar} />
          <div>
            {' '}
            {/* everything to the right of the avatar */}
            <div className={`justify-center pt-16 ${!needsSearchQuery && 'pl-8'}`}>
              {' '}
              {/* Logo and 'x' (header, kind of) */}
              <LogoWideIcon letterColor="#FFFFFF" className="w-1/2 h-16" />
              {!needsSearchQuery && (
                <div className="absolute top-5 right-5">
                  <button onClick={onClose}>
                    <ExitIcon className="w-8 h-8" />
                  </button>
                </div>
              )}
            </div>
            <div className="pt-8">
              {' '}
              {/* "you're talking to a human" intro */}
              <div className="text-gray-100 md:text-gray-500 text-xl md:pt-6 font-extralight font-['Manrope'] leading-loose">
                Hello! I'm Janice, a Kindly Human listener
              </div>
            </div>
            <div
              className={`text-gray-800 text-3xl font-semibold font-['Manrope'] leading-9 md:text-center ${isLoading && 'md:pt-14'}`}
            >
              {' '}
              {/* Call To Action */}
              {!isLoading && 'Where can we help you find support today?'}
            </div>
            {!isLoading && (
              <div className="mt-4 flex flex-col overflow-auto w-full justify-evenly">
                <textarea
                  onClick={() => ref.current && ref.current.focus()}
                  ref={ref}
                  placeholder="Type here..."
                  className="bg-[#AFFF54] bg-opacity-25 border-none focus:outline-none resize-none h-5/6 w-full px-6 pt-4 text-[#535353] text-lg font-semibold font-['Manrope'] leading-loose"
                />
                <Button
                  onClick={() => ref.current && search(ref.current?.value.trim())}
                  type="submit"
                  variant="primary"
                  className="w-1/3 my-6 min-w-max flex place-self-end"
                >
                  Search for Support
                </Button>
                <div className="flex flex-col space-y-4 text-gray-500 font-extralight font-['Manrope'] text-xl">
                  {' '}
                  {/* Examples */}
                  <p className="text-sm text-royalAzure">EXAMPLES</p>
                  <p>“I'm feeling isolated working from home”</p>
                  <p>“My wife was just diagnosed with breast cancer”</p>
                  <p>“Caring for my parents is starting to affect my work”</p>
                  <p>“I just need to vent”</p>
                </div>
              </div>
            )}
          </div>
        </div>
        {isLoading && (
          <>
            <div
              className={
                "text-gray-800 text-3xl font-semibold font-['Manrope'] leading-9 md:text-center md:pt-14 mx-10"
              }
            >
              Got it. Let's find you some individuals who have faced similar situations!
            </div>
            <div className="sm:mt-24 mt-8 flex flex-col flex-grow overflow-auto w-full space-y-6">
              <LoadingDotsIcon />
              <div className="w-full text-center text-sky-950 text-base font-bold font-['Manrope'] leading-normal">
                Looking for just the right matches...
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );

  const content_mobile = (
    <div className="overflow-auto h-full">
      <div className="w-full h-50 md:h-40 bg-[#081D40] relative mb-28">
        <div className={`flex justify-center pt-8 ${!needsSearchQuery && 'pl-8'}`}>
          <LogoWideIcon letterColor="#FFFFFF" className="w-2/3 h-16" />
          {!needsSearchQuery && (
            <div className="absolute top-5 right-5">
              <button onClick={onClose}>
                <ExitIcon className="w-8 h-8" />
              </button>
            </div>
          )}
        </div>
        <div className="flex">
          <Avatar className="pl-4 pt-6 md:pt-2" variant={'x-large'} image={JaniceAvatar} />
          <div className="pt-8 pl-8">
            <div className="text-gray-100 md:text-gray-500 text-xl font-normal font-['Manrope']">
              Hello, I'm
              <div className="text-[#AFFF54] font-bold">Janice</div>
            </div>
            <div className="pt-8 w-28 text-gray-800 text-md font-medium font-['Manrope'] leading-tight">
              Kindly Human Listener
            </div>
          </div>
        </div>
      </div>
      <div
        className={`text-gray-800 text-xl font-normal font-['Manrope'] md:text-center px-6 ${isLoading ? 'md:pt-14' : ''}`}
      >
        {!isLoading
          ? 'Where can we help you find support today?'
          : "Got it. Let's find you some individuals who have faced similar situations!"}
      </div>
      {!isLoading ? (
        <div className="mt-8 flex flex-col h-1/2 mb-6 w-full justify-evenly">
          <div className="mx-4 text-gray-500 font-extralight font-['Manrope'] text-base mb-3">
            <p className="text-sm text-royalAzure">EXAMPLES</p>
            <p>“I'm feeling isolated working from home”</p>
            <p>“My wife was just diagnosed with breast cancer”</p>
            <p>“Caring for my parents is starting to affect my work”</p>
            <p>“I just need to vent”</p>
          </div>
          <textarea
            onClick={() => ref.current && ref.current.focus()}
            ref={ref}
            placeholder="Type here..."
            className="bg-[#AFFF54] bg-opacity-25 border-none focus:outline-none resize-none flex-grow min-h-[100px] w-full px-6 pt-4 text-[#535353] text-lg font-semibold font-['Manrope'] leading-loose"
          />
          <Button
            onClick={() => ref.current && search(ref.current?.value.trim())}
            type="submit"
            variant="primary"
            className="w-1/2 my-6 min-w-24 flex place-self-center"
          >
            Search
          </Button>
        </div>
      ) : (
        <div className="sm:mt-24 mt-8 flex flex-col flex-grow w-full space-y-6">
          <LoadingDotsIcon />
          <div className="w-full text-center text-sky-950 text-base font-bold font-['Manrope'] leading-normal">
            Looking for just the right matches...
          </div>
        </div>
      )}
    </div>
  );

  if (dfMdMedia) {
    return (
      <Dialog
        open={true}
        className="rounded-lg w-full max-w-5xl h-180 p-0 backdrop:bg-modalBackdropColor backdrop-filter backdrop-blur-sm"
        contentClassName="flex h-full"
        onClose={onClose}
        closeOnOutsideClick={!needsSearchQuery}
      >
        <div className="w-full overflow-y-scroll">{content_wide}</div>
      </Dialog>
    );
  }

  return (
    <div className="fixed inset-0 z-50 bg-white flex h-full flex-col items-center justify-center">{content_mobile}</div>
  );
};
