import { Dispatch, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MobileMenuHeader, DesktopMenuHeader, SubHeader } from '../../components/MenuHeader';
import { useAvailableListeners } from '../../hooks/usePeers';
import { Avatar, AvatarSkeleton, Experience, PeerCard, SpinnerIcon } from '@kindlyhuman/component-library';
import { ROUTE_PATH } from '../../routes/route-paths';
import { useTagGroups } from '../../hooks/useTagGroups';
import { SchedulingModal } from '../../components/scheduling-modal/scheduling-modal';
import { Features, useFeatureFlag } from '../../hooks/useFeatureFlag';
import {
  FavoriteExperience,
  RecommendedExperienceResponse,
  useExperienceByAvailablity,
  useFavoriteExperience,
  useRecommendedExperiencesTextSearch,
  useUnfavoriteExperience,
} from '../../hooks/useExperiences';
import { User, useUser } from '../../hooks/useUser';
import { ExperienceSection } from '../home/home';
import { UseQueryResult, useQueryClient } from '@tanstack/react-query';
import { useListenerAudioPlayMutation, useListenerAudioPlaybackMutation } from '../../hooks/useListenerAudio';
import { Skeleton } from '@mui/material';
import { ActiveCall, useAllCalls } from '../../hooks/useCalls';
import { useMediaQuery } from '../../hooks/useMediaQuery';

// TODO: This page is very similar to other peer listings pages.
// They should be consolidated into a single PeerListing page into which a list of peers is injected.

interface LocationState {
  supportQuery?: string;
  user?: User;
}

const AvailableListenersPage = () => {
  const navigate = useHistory();
  const textSearchFlag = useFeatureFlag(Features.TEXT_SEARCH);
  const dfMdMedia = useMediaQuery('md');
  const { data: user } = useUser();
  const supportQuery = user?.search_queries?.[0]?.query;
  const [scheduleModal, setScheduleModal] = useState<number>();

  /* Usually a user reaches this page from the home page, so if they did not have a search query they would be forced to enter one.
  However, we may deep link to this page, and if the user doesn't have a search query, the page will break.
  In this case, we should redirect them to the home page, where they will be prompted to enter a search query.
   */
  if (user && supportQuery === undefined) {
    navigate.push(ROUTE_PATH.HOME);
  }

  return (
    <>
      <div className="bg-neutral-700 bg-opacity-5 min-h-screen flex flex-col">
        <MobileMenuHeader prompt onBack={() => navigate.goBack()} />
        <DesktopMenuHeader />
        <div className="w-full space-y-3 md:space-y-6 md:max-w-7xl md:mx-auto md:px-6 md:pb-10 ">
          {textSearchFlag.enabled ? (
            <RecentConnections user={user} />
          ) : (
            <SubHeader
              title="Peers Available to Connect"
              subtitle="Talk now or schedule a time that's convenient"
              className="px-4 pt-5 pb-7 md:px-0 md:pt-6 md:pb-8"
              onBackButtonClick={dfMdMedia ? () => navigate.goBack() : undefined}
            />
          )}
          <Body
            textSearchFlag={textSearchFlag}
            supportQuery={supportQuery}
            user={user}
            dfMdMedia={dfMdMedia}
            setScheduleModal={setScheduleModal}
          />
        </div>
      </div>
      {scheduleModal && (
        <SchedulingModal
          open={!!scheduleModal}
          isNow={true}
          onExit={() => {
            setScheduleModal(undefined);
          }}
          listenerId={scheduleModal}
        />
      )}
    </>
  );
};

export const RecentConnections: React.FC<{
  user?: User;
}> = ({ user }) => {
  const navigate = useHistory();
  const dfMdMedia = useMediaQuery('md');
  const { isLoading: callsLoading, data: calls } = useAllCalls(user?.caller_role_id);

  return (
    <div className="bg-white border-t border-b md:border md:rounded-lg pb-3">
      <div className="flex items-start justify-between px-4 py-3 md:px-5 md:pt-5">
        <div>
          <p className="font-manrope font-bold text-xl md:text-2xl text-gray-800 flex-grow">Recent Connections</p>

          <div className="font-manrope text-stone-500 text-sm md:text-base md:mt-1 font-medium">
            {calls && calls.filter((call) => call.listener_role.is_active).length > 0
              ? `Peers you've recently connected with`
              : 'No recent connections'}
          </div>
        </div>
      </div>
      <div
        className="flex overflow-x-scroll gap-x-3
        md:flex-wrap md:gap-x-5 md:-mb-4"
      >
        {callsLoading &&
          [...Array(10).keys()].map((int) => (
            <div key={int} className="max-w-full flex flex-col gap-y-3 pl-3 justify-baseline items-start md:mb-4">
              <AvatarSkeleton variant={dfMdMedia ? 'normal' : 'large'} isPeerListener />
              <Skeleton variant="text" className="w-20 md:w-15 h-5" />
            </div>
          ))}
        {calls &&
          calls
            .reduce<ActiveCall[]>((unique, call) => {
              return unique.some((item) => item.listener_role_id === call.listener_role_id)
                ? unique
                : [...unique, call];
            }, [])
            .filter((call) => call.listener_role.is_active)
            .sort((a, b) =>
              b.listener_role.is_available === a.listener_role.is_available ? 0 : b.listener_role.is_available ? 1 : -1,
            )
            .map((call) => (
              <div
                key={call.listener_role_id}
                className="min-w-max flex flex-col gap-y-3 pl-3 justify-baseline items-center cursor-pointer md:mb-4"
                onClick={() => {
                  navigate.push(`${ROUTE_PATH.PEER_DETAILS}?listenerId=${call.listener_role_id}`);
                }}
              >
                <Avatar
                  variant={dfMdMedia ? 'normal' : 'large'}
                  available={call.listener_role.is_available}
                  isPeerListener={call.listener_role.is_active}
                  image={call.listener_role.profile_photo_square_file_url}
                />
                <p
                  data-testid={`peer-${call.listener_role.user.display_name}`}
                  className="
                  text-center text-gray-800 text-sm font-medium leading-tight truncate max-w-[100px]
                  md:max-w-[64px]
                "
                >
                  {call.listener_role.user.display_name}
                </p>
              </div>
            ))}
      </div>
    </div>
  );
};

const Body: React.FC<{
  textSearchFlag: ReturnType<typeof useFeatureFlag>;
  supportQuery?: string;
  user?: User;
  dfMdMedia: boolean;
  setScheduleModal: Dispatch<number | undefined>;
}> = ({ textSearchFlag, supportQuery, user, dfMdMedia, setScheduleModal }) => {
  const navigate = useHistory();
  const { getParentByParentId } = useTagGroups();
  const [playerOpen, setPlayerOpen] = useState<number>();
  const audioStopAll = () => {
    document.querySelectorAll('audio').forEach((el) => {
      el.pause();
      el.currentTime = 0;
    });
  };

  const onCallListeners = useExperienceByAvailablity(true, 20, 1);
  const offCallListeners = useExperienceByAvailablity(false, 20, 1);

  const { data: availableListeners, isLoading: isAvailableListenersLoading } = useAvailableListeners();
  let content;

  if (!textSearchFlag.enabled && isAvailableListenersLoading) {
    content = (
      <div className="w-screen max-w-full h-full py-26 flex items-center justify-center">
        <SpinnerIcon />
      </div>
    );
  } else if (textSearchFlag.enabled) {
    const ExperienceSectionComponent = dfMdMedia ? ExperienceSection : ExperienceSectionVertical;
    content = (
      <div className="col-span-5 order-2 space-y-3 md:order-1 md:space-y-6">
        <ExperienceSectionComponent
          user={user}
          textSearch={textSearchFlag.enabled}
          setPlayerOpen={setPlayerOpen}
          playerOpen={playerOpen}
          audioStopAll={audioStopAll}
          experiences={onCallListeners}
          title="Available Connections"
          subtitle={
            onCallListeners?.data && onCallListeners.data.count > 0
              ? 'Connect with someone available to talk now'
              : 'No listeners are available to talk now'
          }
        />
        <ExperienceSectionComponent
          user={user}
          textSearch={textSearchFlag.enabled}
          setPlayerOpen={setPlayerOpen}
          playerOpen={playerOpen}
          audioStopAll={audioStopAll}
          experiences={offCallListeners}
          title="Connect Later"
          subtitle="Schedule a time to talk"
        />
      </div>
    );
  } else {
    content = (
      <div className="grid gap-3 lg:grid-cols-2 md:gap-6">
        {availableListeners?.data?.map((peer) => (
          <PeerCard
            key={peer.listener_role_id}
            className="overflow-hidden rounded-lg md:border md:border-neutral-200 md:p-5"
            // @ts-ignore
            areasLabels={peer?.challenge_areas?.map((challengeArea) => challengeArea.name)}
            // @ts-ignore
            subjectChips={peer?.subject_areas?.map((subject) => {
              return {
                name: subject.name,
                variant: getParentByParentId(subject.parent_id)?.key,
              };
            })}
            available={peer.is_available}
            isPeerListener={peer.is_listener}
            description={peer.about_me}
            img={peer.profile_photo_url_square}
            name={peer.display_name}
            traits={peer.tags.map((tag) => tag.name)}
            onClick={() =>
              navigate.push({
                pathname: ROUTE_PATH.PEER_DETAILS,
                search: `?listenerId=${peer.listener_role_id}`,
              })
            }
            onConnect={
              peer.is_available
                ? () => {
                    setScheduleModal(peer.listener_role_id);
                  }
                : undefined
            }
            variant="wide"
          />
        ))}
      </div>
    );
  }

  return content;
};

export const ExperienceSectionVertical: React.FC<{
  textSearch: boolean;
  user?: User;
  experiences: UseQueryResult<RecommendedExperienceResponse | null, unknown>;
  audioStopAll: () => void;
  playerOpen?: number;
  setPlayerOpen: Dispatch<React.SetStateAction<number | undefined>>;
  title?: string;
  subtitle?: string;
}> = ({ user, experiences: recommendedExperiences, audioStopAll, playerOpen, setPlayerOpen, title, subtitle }) => {
  const playBackMutation = useListenerAudioPlaybackMutation();
  const playMutation = useListenerAudioPlayMutation();
  const queryClient = useQueryClient();
  const favoriteMutation = useFavoriteExperience();
  const unfavoriteMutation = useUnfavoriteExperience();
  const { getParentByParentId } = useTagGroups();
  const peerClientFlag = useFeatureFlag(Features.PEER_CLIENT_RELATIONSHIP);

  const [currentPlaybackId, setCurrentPlaybackId] = useState<number>();
  const [playing, setPlaying] = useState(false);

  const [scheduleModal, setScheduleModal] = useState<number>();
  const [scheduleAvailableNow, setScheduleAvailableNow] = useState(false);

  const history = useHistory();

  const dfMdMedia = useMediaQuery('md');

  const playerContainerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className="bg-white border-t border-b md:border md:rounded-lg pb-3">
        {title && (
          <div className="flex items-start justify-between px-4 py-3 md:px-5 md:pt-5">
            <div>
              <p className="font-manrope font-bold text-xl md:text-2xl text-gray-800 flex-grow">
                {title ? title : peerClientFlag ? 'Connections' : 'Experiences for you'}
              </p>
              {(recommendedExperiences.isLoading || recommendedExperiences.isRefetching) && (
                <Skeleton variant="text" className="w-40 h-5 md:h-6 md:mt-1" />
              )}
              {subtitle && !recommendedExperiences.isLoading && !recommendedExperiences.isRefetching && (
                <div className="font-manrope text-stone-500 text-sm md:text-base md:mt-1 font-medium">
                  {subtitle
                    ? subtitle
                    : recommendedExperiences?.data?.data.length
                      ? 'Find and connect with someone who has been there'
                      : 'No experiences found matching your search filters'}
                </div>
              )}
            </div>
          </div>
        )}
        <div className="flex flex-col items-center gap-4 py-4">
          {(recommendedExperiences.isLoading || recommendedExperiences.isRefetching) &&
            [1, 2, 3, 4].map((int) => (
              <div key={int}>
                <Skeleton className="h-80 w-80 pt-5" variant="rounded" />
                <Skeleton variant="text" className="w-24 h-5 pt-4" />
                <Skeleton variant="text" className="w-40 h-5 pt-4" />
                <Skeleton variant="text" className="w-24 h-5 pt-4" />
              </div>
            ))}

          {recommendedExperiences.data &&
            recommendedExperiences.data.data.map((experience, idx) => (
              <Experience
                experienceId={experience.id}
                className="shrink-0"
                variant={peerClientFlag.enabled ? 'experiencePeer' : dfMdMedia ? 'desktopSmall' : 'small'}
                imageUrl={experience.profile_photo_square_file_url}
                displayName={experience.name}
                audioUrl={experience.audio_url}
                caption={experience.excerpt}
                goToProfile={() => {
                  history.push(`${ROUTE_PATH.PEER_DETAILS}?listenerId=${experience.listener_role_id}`);
                }}
                // @ts-ignore
                areas={experience.area_ids?.map((x) => getParentByParentId(x)?.name)}
                favorited={experience.is_favorite}
                onFollow={() => {
                  if (peerClientFlag.enabled) {
                    if (experience.is_favorite) {
                      unfavoriteMutation.mutate(
                        {
                          tagId: 0,
                          listenerId: experience.listener_role_id,
                          audioId: experience.id,
                        },
                        {
                          onSuccess: () => {
                            /*
                            here I'm manually setting is_favorite to false on success so we keep the item in the list.
                            this way if they accidently unfavorited they can refavorite it easily
                            */
                            queryClient.setQueryData(['favoriteExperiences'], (oldFavoritesData: any) => {
                              let foundExperience: FavoriteExperience = oldFavoritesData.find(
                                (oldExperience: FavoriteExperience) => oldExperience.id === experience.id,
                              );
                              foundExperience.is_favorite = false;
                              return [
                                ...oldFavoritesData.map((oldExperience: FavoriteExperience) =>
                                  oldExperience.id !== experience.id ? oldExperience : foundExperience,
                                ),
                              ];
                            });
                          },
                        },
                      );
                    } else {
                      favoriteMutation.mutate(
                        {
                          tagId: 0,
                          listenerId: experience.listener_role_id,
                          audioId: experience.id,
                        },
                        {
                          onSuccess: () => {
                            queryClient.invalidateQueries(['favoriteExperiences']);
                          },
                        },
                      );
                    }
                    experience.is_favorite = !experience.is_favorite;
                  } else {
                    return undefined;
                  }
                }}
                playing={playerOpen === experience.id && playing}
                setPlaying={setPlaying}
                onPlay={() => {
                  audioStopAll();
                  setPlayerOpen(experience.id);
                  playMutation.mutate(
                    {
                      audioId: experience.id,
                      listenerId: experience.listener_role_id,
                    },
                    {
                      onSuccess: (listenerAudioPlayback) => {
                        setCurrentPlaybackId(listenerAudioPlayback.id);
                      },
                    },
                  );
                }}
                key={idx}
                openPlayer={playerOpen === experience.id}
                setOpenPlayer={setPlayerOpen}
                onTimeUpdate={(e: React.SyntheticEvent<HTMLAudioElement>) => {
                  const percent = Math.round((e.currentTarget.currentTime / experience.duration) * 100);
                  // if the percent is divisible by 10
                  if (percent % 10 === 0) {
                    currentPlaybackId &&
                      playBackMutation.mutate({
                        audioId: currentPlaybackId,
                        percentCompleted: percent,
                      });
                  }
                }}
                available={experience.is_available}
                isPeerListener={experience.is_listener}
                playerContainer={playerContainerRef?.current}
                onConnect={() => {
                  if (peerClientFlag.enabled) {
                    setScheduleModal(experience.listener_role_id);
                    setScheduleAvailableNow(experience.is_available);
                  } else {
                    return undefined;
                  }
                }}
                displayClient={user?.client_id === experience.client_id}
                clientLogo={experience.client_image_url}
                backgroundTags={experience.background_tags}
              />
            ))}
        </div>
      </div>

      <div ref={playerContainerRef} />

      {scheduleModal && (
        <SchedulingModal
          open={!!scheduleModal}
          isNow={scheduleAvailableNow}
          onExit={() => {
            setScheduleModal(undefined);
          }}
          listenerId={scheduleModal}
        />
      )}
    </>
  );
};

export default AvailableListenersPage;
