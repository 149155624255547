import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AreaLabel, Button, SpinnerIcon } from '@kindlyhuman/component-library';

import { useResourceByID } from '../../hooks/useResources';
import { useTagGroups } from '../../hooks/useTagGroups';

import { ROUTE_PATH } from '../../routes/route-paths';

import { MobileMenuHeader, DesktopMenuHeader, SubHeader } from '../../components/MenuHeader';
import { FormBlock } from '../../components/common/form_block';

import NotFound404 from '../not-found/not-found-404';
import { useMediaQuery } from '../../hooks/useMediaQuery';

export const ResourceDetailPage = () => {
  const navigate = useHistory();
  const { pathname } = useLocation();

  const resourceID = useMemo(() => parseInt(pathname.match(/\/resource\/(\d+)_.*/)?.[1] ?? ''), [pathname]);

  const { getParentByParentId } = useTagGroups();
  const { data: resource, isLoading: isResourceLoading } = useResourceByID(resourceID);

  const dfMdMedia = useMediaQuery('md');

  const bodyComponent = useMemo(() => {
    if (isResourceLoading) {
      return (
        <div className="w-full h-screen flex items-center justify-center">
          <SpinnerIcon />
        </div>
      );
    }

    if (!resource) {
      return <NotFound404 />;
    }

    return (
      <>
        <div className="hidden md:block max-w-7xl px-6 mx-auto">
          <SubHeader
            className="hidden md:block pt-6 pb-8"
            onBackButtonClick={dfMdMedia ? () => navigate.goBack() : undefined}
          />
          <FormBlock className="flex justify-between gap-12 space-y-0">
            <div className="font-manrope text-dark">
              <p className="text-xl font-bold">{resource.name}</p>
              <p className="font-medium mt-2">{resource.long_description || resource.description}</p>
              {resource.challenge_areas && (
                <div className="flex flex-col gap-2 mt-8">
                  {resource.challenge_areas.map((id) => (
                    <AreaLabel
                      key={id}
                      // @ts-ignore
                      variant={getParentByParentId(id)?.name}
                    />
                  ))}
                </div>
              )}
              {(resource.url || resource.phone_number) && (
                <div className="flex gap-6 mt-8">
                  {resource.url && (
                    <a href={resource.url} target="_blank" rel="noreferrer" aria-label={resource.url_description}>
                      <Button className="w-40" variant="primary">
                        Visit Online
                      </Button>
                    </a>
                  )}
                  {resource.phone_number && (
                    <a href={`tel:${resource.phone_number}`}>
                      <Button className="w-40" variant="secondary">
                        Call
                      </Button>
                    </a>
                  )}
                </div>
              )}
            </div>
            <img className="rounded-lg max-w-72 self-start" src={resource.image_url} alt={resource.name} />
          </FormBlock>
        </div>
        <div className="md:hidden">
          <div className="flex flex-col gap-4 px-4">
            <img className="relative rounded-lg" src={resource.image_url} alt={resource.description} />
            <div className="text-gray-800 text-xl font-bold leading-[30px]">{resource.name}</div>
            {resource.long_description && (
              <div className=" text-gray-800 text-base font-medium leading-normal">{resource.long_description}</div>
            )}
            <div className="flex flex-col gap-3">
              {resource.challenge_areas?.map((id) => (
                <AreaLabel
                  key={id}
                  // @ts-ignore
                  variant={getParentByParentId(id)?.name}
                />
              ))}
            </div>
          </div>
          <div className="pt-40"></div>
          <div className="fixed bottom-0 left-0 right-0 py-4 bg-white border-t border-b border-neutral-200 justify-center items-start gap-6 flex">
            {resource.url && (
              <a href={resource.url} target="_blank" rel="noreferrer" aria-label={resource.url_description}>
                <Button className="w-40" variant="primary">
                  Visit Online
                </Button>
              </a>
            )}
            {resource.phone_number && (
              <a href={`tel:${resource.phone_number}`}>
                <Button className="w-40" variant="secondary">
                  Call
                </Button>
              </a>
            )}
          </div>
        </div>
      </>
    );
  }, [isResourceLoading, resource, getParentByParentId, navigate, dfMdMedia]);

  useEffect(() => {
    if (!resourceID) {
      navigate.replace(ROUTE_PATH.RESOURCES);
    }
  }, [navigate, resourceID]);

  return (
    <div className="md:bg-neutral-700 md:bg-opacity-5 h-screen md:pb-8 flex flex-col">
      <DesktopMenuHeader />
      <MobileMenuHeader prompt onBack={() => navigate.goBack()} />
      {bodyComponent}
    </div>
  );
};
