import { Dispatch } from 'react';
import { twMerge } from 'tailwind-merge';

import { FilterIcon, FILTER_CHIP_VARIANT, FilterChip, Bubble } from '@kindlyhuman/component-library';

import { User } from '../../hooks/useUser';
import { TagGroup } from '../../hooks/useTagGroups';

import { HorizontalScrollSection } from '../common/horizontal-scroll';

import { TagFilters } from './FomSelectionFilter';
import { SearchIcon } from '../../assets/SearchIcon';

interface FilterBarProps {
  setShowFilterMenu: Dispatch<React.SetStateAction<boolean>>;
  user?: User;
  getTagGroupByName: (name: string) => TagGroup | undefined;
  className?: string;
  handleFilterChange: ({ tag_group_ids, tag_ids }: TagFilters) => void;
  openTextSearch?: (open: boolean) => void;
  open?: boolean;
  disabled?: boolean;
}

export const FilterBar: React.FC<FilterBarProps> = ({
  setShowFilterMenu,
  user,
  getTagGroupByName,
  handleFilterChange,
  className,
  openTextSearch,
  open,
  disabled,
}) => {
  return (
    <div className={twMerge('my-3 bg-white border-t border-b md:px-0', className)}>
      <HorizontalScrollSection className="md:max-w-7xl md:mx-auto md:px-6" center>
        {openTextSearch && (
          <button disabled={disabled} onClick={() => openTextSearch(!open)} className="pr-2 relative cursor-pointer">
            <Bubble className="text-2xs left-2 -top-1 absolute h-2.5 w-6">New</Bubble>
            <SearchIcon className="w-5 h-5" />
          </button>
        )}
        <FilterIcon
          className="shrink-0 cursor-pointer"
          data-testid="filter-icon-button"
          onClick={() => {
            setShowFilterMenu(true);
          }}
        />
        {Object.keys(FILTER_CHIP_VARIANT)
          .sort((areaName) =>
            user?.caller_role.tag_group_ids?.includes(getTagGroupByName(areaName)?.id ?? 0) ? -1 : 1,
          )
          .map((areaName: string) => (
            <span key={areaName} className={'min-w-max'}>
              <FilterChip
                data-testid={`${areaName}-chip`}
                // @ts-ignore
                variant={areaName}
                onClick={() => {
                  const challengeArea = getTagGroupByName(areaName);
                  if (challengeArea) {
                    handleFilterChange({
                      tag_group_ids: user?.caller_role.tag_group_ids?.includes(challengeArea.id)
                        ? user?.caller_role.tag_group_ids?.filter((tag_group_id) => tag_group_id !== challengeArea.id)
                        : [...(user?.caller_role?.tag_group_ids ?? []), challengeArea.id],
                      tag_ids: user?.caller_role.tag_ids ?? [],
                    });
                  }
                }}
                selected={
                  user?.caller_role.tag_group_ids?.includes(
                    // made up index it can't possibly be
                    getTagGroupByName(areaName)?.id ?? -1,
                  ) ?? false
                }
              />
            </span>
          ))}
      </HorizontalScrollSection>
    </div>
  );
};
