import { Dispatch, useCallback, useState, useRef, useEffect } from 'react';
import { useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { Link, useHistory, useLocation } from 'react-router-dom';
import React from 'react';
import { Skeleton } from '@mui/material';
import moment from 'moment-timezone';
import { twMerge } from 'tailwind-merge';

import {
  Avatar,
  Experience,
  PeerCard,
  CallCard,
  TrashIcon,
  PlusWithCircle,
  Actionable,
  CommentsIcon,
  SupportBubbleIcon,
  AREA_LABEL_VARIANT,
  AreaChip,
  AvatarSkeleton,
  CircledArrowRightIcon,
} from '@kindlyhuman/component-library';

import { ROUTE_PATH } from '../../routes/route-paths';

import { PaymentTile, ReflectionTile } from '../../assets';

import { useListenerAudioPlaybackMutation, useListenerAudioPlayMutation } from '../../hooks/useListenerAudio';
import { CallRequest, useActiveCalls, useCallRequests } from '../../hooks/useCalls';
import { useTagGroups } from '../../hooks/useTagGroups';
import { useRecommendedResourcesTextSearch, useResources } from '../../hooks/useResources';
import {
  PeerResponse,
  AvailableListenersResponse,
  useAvailableListeners,
  useFavoritedPeers,
  useNewPeers,
} from '../../hooks/usePeers';
import { User, useUser } from '../../hooks/useUser';
import {
  FavoriteExperience,
  RecommendedExperienceResponse,
  useFavoriteExperience,
  useUnfavoriteExperience,
  useRecommendedExperiencesTextSearch,
  SearchFilterOptions,
  useExperiences,
} from '../../hooks/useExperiences';
import { useExternalProviderPlan } from '../../hooks/useExternalProviderPlans';
import { usePostCalls, useUpdatePostCall } from '../../hooks/usePostCall';

import { FilterMenuModal, TagFilters } from '../../components/filter/FomSelectionFilter';
import { BlockHorizontalScroll } from '../../components/common/block_horizontal_scroll';
import { FilterBar } from '../../components/filter/FilterBar';
import { CALL_UNITS_CALL_DEFAULT_LENGTH, SchedulingModal } from '../../components/scheduling-modal/scheduling-modal';
import ReflectionsModal from '../../components/reflections-modal/reflections-modal';
import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { ActiveCallCard } from '../../components/common/CallCards/ActiveCallCard';
import { CallCardWithData } from '../../components/common/CallCards/CallCardWithData';

import IntroducesVideoCard from './introduces-video-card';
import { PostCallModal } from '../../components/PostCallModal';
import { PostCallFormRef } from '../../components/common/PostCallForm';
import { SearchQueryModal } from '../../components/search/search-query-modal';
import { Features, useFeatureFlag } from '../../hooks/useFeatureFlag';
import { SearchInput } from '../../components/search/search-input';
import { SearchFiltersModal } from '../../components/search/search-filters-modal';
import { ResourcesSection } from '../../components/resources/resources-section';
import { useReflections, useReflectionDismissMutation } from '../../hooks/useReflection';
import { useMediaQuery } from '../../hooks/useMediaQuery';

const NORMAL_CALL_LENGTH = 20;
const RESORUCE_LIMIT = 3;

export const HomePage = () => {
  const { data: user, updateTagPreferences } = useUser();
  const history = useHistory();
  const queryClient = useQueryClient();

  const dfMdMedia = useMediaQuery('md');

  const [isVideo, setIsVideo] = useState(true);
  const [dismissPaymentTile, setDismissPaymentTile] = useState(false);
  const [showPaymentTile, setShowPaymentTile] = useState(false);

  const textSearchFlag = useFeatureFlag(Features.TEXT_SEARCH);
  const [showSearchQueryModal, setShowSearchQueryModal] = useState(false);

  const changeSearchModalStatus = (open: boolean) => {
    setShowSearchQueryModal(open);
  };
  const { search: urlQuery } = useLocation();

  const deepLinkSearch = new URLSearchParams(urlQuery).get('search');

  const searchQueries = user?.search_queries?.length ? user?.search_queries : null;
  const [searchFilterOpen, setSearchFilterOpen] = useState(false);
  const [searchFilters, setSearchFilters] = useState<SearchFilterOptions>({
    supportQuery: '',
    limit: 20,
    page: 1,
    peerType: ['peerlistener', 'peer'],
  });
  const { supportQuery, limit, page, peerType, ...rest } = searchFilters;

  const textSearchQuery = useRecommendedExperiencesTextSearch({
    enabled:
      textSearchFlag.enabled && searchFilters.supportQuery !== undefined && searchFilters.supportQuery.trim() !== '',
    onSuccess: () => {
      setShowSearchQueryModal(false);
    },
    searchFilterOptions: searchFilters,
  });

  const textSearchResourcesQuery = useRecommendedResourcesTextSearch({
    limit: 100,
    query: searchFilters.supportQuery,
    enabled:
      textSearchFlag.enabled && searchFilters.supportQuery !== undefined && searchFilters.supportQuery.trim() !== '',
  });

  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [playerOpen, setPlayerOpen] = useState<number>();
  const [showReflectionTile, setShowReflectionTile] = useState(false);
  const [showReflectionModal, setShowReflectionModal] = useState(false);

  const { data: fomHierarchy, getTagGroupByName } = useTagGroups();
  const { data: callRequests } = useCallRequests(user?.caller_role_id);
  const {
    data: providerPlans,
    isLoading: providerPlansLoading,
    isFetching: providerPlansFetching,
  } = useExternalProviderPlan();

  const favoritedPeers = useFavoritedPeers({ limit: 10 });
  const availableListeners = useAvailableListeners();
  const newPeers = useNewPeers({ limit: 10 });
  // const searchQueries = user?.search_queries?.length ? user?.search_queries : null;
  // We only use the old recommended experiences query if the user has no search queries or if the text search feature flag is disabled
  const { recommendedExperiences } = useExperiences();

  const resources = useResources(!textSearchFlag.enabled);
  const currentReflection = useReflections();
  const reflectionDismiss = useReflectionDismissMutation();

  const providerPlan =
    providerPlans?.find((plan) => {
      return plan.id === user?.caller_role.active_subscription?.package.external_plan_id;
    }) ?? null;

  useEffect(() => {
    if (currentReflection && user?.caller_role_id) {
      setShowReflectionTile(true);
    }
  }, [currentReflection, setShowReflectionTile, user]);

  const audioStopAll = () => {
    document.querySelectorAll('audio').forEach((el) => {
      el.pause();
      el.currentTime = 0;
    });
  };

  const handleFilterChange = ({ tag_group_ids, tag_ids }: TagFilters) => {
    updateTagPreferences.mutate({
      tag_group_ids,
      tag_ids,
    });
    setShowFilterMenu(false);
  };

  const handleReflection = () => {
    setShowReflectionTile(false);
    setShowReflectionModal(true);
  };

  const handleReflectionDismiss = () => {
    reflectionDismiss.mutate(currentReflection?.data?.id);
    setShowReflectionTile(false);
  };

  const handleReflectionModalDismiss = () => {
    setShowReflectionModal(false);
  };

  const recommendedResources =
    textSearchFlag.enabled && textSearchResourcesQuery ? textSearchResourcesQuery : resources;
  const groupLevelResources = recommendedResources?.data?.data?.filter((resource) => resource.client_id);
  const globalResources = recommendedResources?.data?.data?.filter((resource) => !resource.client_id);

  const experiences = textSearchQuery ?? recommendedExperiences;

  // this is kinda a hack. someone please help me
  useEffect(() => {
    if (groupLevelResources && groupLevelResources.length > 0) {
      localStorage.setItem('hasGroupResources', 'true');
    }
  }, [groupLevelResources]);

  const client = user?.caller_role.active_subscription?.package?.client;
  const isUnlimited = user?.onUnlimitedPlan;
  // if we're on an unlimited plan we don't have any minutes
  const availableMinutes = isUnlimited
    ? undefined
    : user?.caller_role.is_call_units
      ? user?.caller_role.payment_data.available_minutes / CALL_UNITS_CALL_DEFAULT_LENGTH
      : user?.caller_role.payment_data.available_minutes;
  const getShowPaymentTile = useCallback(async (user: User) => {
    if (user.onUnlimitedPlan) {
      return false;
    }

    const hasStripePaymentMethod = !!user.caller_role?.stripe_payment_method_id;
    const cappedAccessProduct = user.caller_role?.active_subscription?.package?.package_products?.find(
      (pp: any) => pp.product?.key === 'CAPPED_ACCESS' && pp.status === 'enabled',
    );
    // if user is on capped access and we're not hiding the payment tile (config setting), then show
    // tile if the user has insufficient minutes credit and no payment method
    if (cappedAccessProduct) {
      const freeAvailableMinutes = user.caller_role.payment_data.available_minutes;
      const hasSufficientCredit = freeAvailableMinutes > NORMAL_CALL_LENGTH;
      return !cappedAccessProduct?.configuration?.hide_payment_tiles && !hasSufficientCredit && !hasStripePaymentMethod;
    }
    // This is either pay-as-you-go or member pay, show the tile if they don't have a card on file
    return !hasStripePaymentMethod;
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }
    getShowPaymentTile(user).then((isShowPaymentTile) => {
      setShowPaymentTile(isShowPaymentTile);
    });
    const videoDisplay = localStorage.getItem('isVideoDisplay');
    setIsVideo(videoDisplay === 'true' || videoDisplay === null ? true : false);
  }, [user, getShowPaymentTile]);

  const handleAddPayment = () => {
    setDismissPaymentTile(true);
    history.push(ROUTE_PATH.PAYMENT);
  };

  useEffect(() => {
    const storedFilters = localStorage.getItem('searchForSupportFilters');
    if (storedFilters) {
      const parsedFilters = JSON.parse(storedFilters);
      setSearchFilters(parsedFilters);
    }
  }, []);

  useEffect(() => {
    if (textSearchFlag.enabled) {
      if (deepLinkSearch) {
        setSearchFilters((prevFilters) => ({
          ...prevFilters,
          supportQuery: deepLinkSearch,
        }));
        setShowSearchQueryModal(false);
      } else if (
        !searchQueries ||
        (moment.utc(searchQueries[0].created_at).isBefore(moment().subtract(7, 'days')) &&
          sessionStorage.getItem('ignoreStaleSearch') !== 'true')
      ) {
        setShowSearchQueryModal(true);
      } else {
        setSearchFilters((prevFilters) => ({
          ...prevFilters,
          supportQuery: searchQueries[0].query,
        }));
        setShowSearchQueryModal(false);
      }
    }
  }, [user, textSearchFlag.enabled]);

  // go back to the top of the screen when you land on the page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const search = (text?: string, filters?: SearchFilterOptions) => {
    let storedFilters = localStorage.getItem('searchForSupportFilters');
    if (text && text.trim() === '') {
      return;
    }
    const queryCaches = queryClient.getQueryCache().getAll();
    // check if the query is already cached, and if it is, don't show the modal
    const hasCachedQuery = queryCaches.some((item) => {
      return item.queryKey[0] === 'textSearchExperiences' && item.queryKey[1] === filters && item.queryKey[2] === text;
    });
    if (hasCachedQuery) {
      setShowSearchQueryModal(false);
    }
    setSearchFilters((prev) => ({
      ...prev,
      ...filters,
      supportQuery: text || prev.supportQuery,
    }));
    if (storedFilters) {
      const parsedFilters = JSON.parse(storedFilters);
      parsedFilters.supportQuery = text;
      localStorage.setItem('searchForSupportFilters', JSON.stringify(parsedFilters));
    }
  };

  return (
    <div className={`bg-gray-100 w-screen min-h-screen ${showFilterMenu ? 'overflow-hidden' : ''}`}>
      <MobileMenuHeader
        onConnect={() => history.push(ROUTE_PATH.AVAILABLE_LISTENERS)}
        availableMinutes={availableMinutes}
      />
      <DesktopMenuHeader />
      <div className="w-full mx-auto">
        {textSearchFlag.enabled ? (
          <SearchInput
            query={searchFilters.supportQuery}
            search={(text) => search(text)}
            isLoading={textSearchQuery.isLoading}
            searchFilterOpen={searchFilterOpen}
            setSearchFilterOpen={setSearchFilterOpen}
          />
        ) : (
          <FilterBar
            open={showSearchQueryModal}
            openTextSearch={textSearchFlag.enabled ? changeSearchModalStatus : undefined}
            disabled={textSearchQuery?.isLoading}
            setShowFilterMenu={setShowFilterMenu}
            user={user}
            getTagGroupByName={getTagGroupByName}
            handleFilterChange={handleFilterChange}
            className="md:mb-0 md:border-t-0 md:mt-0"
          />
        )}

        <div
          className="
            grid grid-cols-1 mt-3 space-y-3
            md:grid-cols-8 md:space-y-0 md:mx-auto md:mt-6 md:pb-14 md:gap-6 md:max-w-7xl md:px-6
          "
        >
          {/* We can't use space-y-3 for this div - it breaks styles for dialog */}
          <div className="flex flex-col gap-3 col-span-3 order-1 md:order-2 md:gap-6">
            <CallConnectionCards callRequests={callRequests} />
            {client && client?.video_configuration && isVideo && (
              <div className="mb-4 md:mb-0 md:bg-white md:border md:rounded-lg md:p-4">
                <IntroducesVideoCard
                  videoSrc={client?.video_configuration?.video_url}
                  imageSrc={client?.video_configuration?.image_url}
                  handleCloseVideo={() => {
                    localStorage.setItem('isVideoDisplay', 'false');
                    setIsVideo(false);
                  }}
                  className="md:max-w-full md:max-h-full"
                />
              </div>
            )}
            {showReflectionTile && currentReflection?.data && (
              <div className="mx-auto md:bg-white md:border md:rounded-lg md:p-4 md:w-full">
                <Actionable
                  buttonContents="Reflect"
                  darken
                  description="Take a moment to reflect and earn rewards"
                  img={ReflectionTile}
                  onClick={handleReflection}
                  onExit={handleReflectionDismiss}
                  title="How are you today?"
                  width={dfMdMedia ? '100%' : undefined}
                />
              </div>
            )}
            {showPaymentTile && !dismissPaymentTile && (
              <div className="mx-auto md:bg-white md:border md:rounded-lg md:p-4 md:w-full">
                <Actionable
                  data-testid="payment-card"
                  buttonContents="Add"
                  darken
                  description="Before we connect you with Peer Listeners"
                  img={PaymentTile}
                  onClick={handleAddPayment}
                  onExit={() => setDismissPaymentTile(true)}
                  title="Add your payment method & get started"
                  width={dfMdMedia ? '100%' : undefined}
                />
              </div>
            )}

            <ConnectNowContainer availableListeners={availableListeners} />
            <MyPeersSection favoritedPeers={favoritedPeers} />
            {!textSearchFlag.enabled && <ExploreAreasSection className="hidden md:block" />}
          </div>
          <div className="col-span-5 order-2 space-y-3 md:order-1 md:space-y-6">
            <ExperienceSection
              user={user}
              textSearch={textSearchFlag.enabled}
              setPlayerOpen={setPlayerOpen}
              playerOpen={playerOpen}
              audioStopAll={audioStopAll}
              experiences={textSearchFlag.enabled ? experiences : recommendedExperiences}
            />
            {providerPlan && (
              <ResourcesSection
                title="My Counseling And Care"
                providerPlan={providerPlan}
                providerPlanFetching={providerPlansFetching}
                providerPlanLoading={providerPlansLoading}
              />
            )}
            {localStorage.getItem('hasGroupResources') === 'true' && (
              <ResourcesSection
                query={searchFilters.supportQuery}
                resourcesQuery={recommendedResources}
                groupLevelResources={groupLevelResources?.slice(0, RESORUCE_LIMIT)}
                title="My Programs"
                textSearchFlag={textSearchFlag.enabled}
              />
            )}
            <ResourcesSection
              query={searchFilters.supportQuery}
              resourcesQuery={recommendedResources}
              availableResources={globalResources?.slice(0, RESORUCE_LIMIT)}
              textSearchFlag={textSearchFlag.enabled}
              title={
                groupLevelResources || localStorage.getItem('hasGroupResources') === 'true'
                  ? 'More Programs'
                  : 'Resources'
              }
            />
            {!textSearchFlag.enabled && (
              <>
                <NewPeersSection newPeers={newPeers} />
                <ExploreAreasSection className="md:hidden" />
              </>
            )}
          </div>
        </div>
        <div className="h-4" />
        {playerOpen && <div className="h-24"></div>}
      </div>

      {showFilterMenu && (
        <FilterMenuModal
          open={showFilterMenu}
          selected={{
            tag_ids: user?.caller_role.tag_ids ?? [],
            tag_group_ids: user?.caller_role.tag_group_ids ?? [],
          }}
          onExit={() => setShowFilterMenu(false)}
          onChange={handleFilterChange}
          fomOptions={fomHierarchy}
        />
      )}
      {showReflectionModal && currentReflection?.data && (
        <ReflectionsModal open={showReflectionModal} handleReflectionModalDismiss={handleReflectionModalDismiss} />
      )}
      {textSearchFlag.enabled && showSearchQueryModal && (
        <SearchQueryModal
          onClose={() => {
            setShowSearchQueryModal(false);
            setSearchFilters((prevFilters) => ({
              ...prevFilters,
              supportQuery: searchQueries ? searchQueries[0].query : `I'm looking for support`,
            }));
            sessionStorage.setItem('ignoreStaleSearch', 'true');
          }}
          needsSearchQuery={!searchQueries}
          search={(text) => search(text)}
          isLoading={textSearchQuery?.isFetching ?? false}
        />
      )}
      {textSearchFlag.enabled && searchFilterOpen && (
        <SearchFiltersModal
          filters={searchFilters}
          setFilters={setSearchFilters}
          user={user}
          isOpen={searchFilterOpen}
          setIsOpen={setSearchFilterOpen}
          onClose={() => setSearchFilterOpen(false)}
          search={(filters) => search(undefined, filters)}
        />
      )}
    </div>
  );
};

const MyPeersSection: React.FC<{ favoritedPeers: UseQueryResult<PeerResponse, unknown> }> = ({ favoritedPeers }) => {
  const history = useHistory();

  const dfMdMedia = useMediaQuery('md');

  return (
    <BlockHorizontalScroll
      dataTestId="my-peer-section"
      className="md:border md:rounded-lg"
      contentClassName={`
        flex overflow-x-scroll gap-x-3
        md:flex-wrap md:gap-x-5 md:-mb-4
      `}
      isLoading={favoritedPeers.isFetching}
      title="My Peers"
      onViewAll={() => {
        history.push({ pathname: ROUTE_PATH.MY_PEERS });
      }}
      subtitle={favoritedPeers.data?.count ? `${favoritedPeers.data?.count} Peers are here for you` : ''}
    >
      {favoritedPeers.isLoading &&
        [...Array(10).keys()].map((int) => (
          <div key={int} className="max-w-full flex flex-col gap-y-3 justify-baseline items-start md:mb-4">
            <AvatarSkeleton variant={dfMdMedia ? 'normal' : 'large'} isPeerListener />
            <Skeleton variant="text" className="w-20 md:w-15 h-5" />
            <div className="w-0 h-0 hidden"></div>
            <div className="w-0 h-0 hidden"></div>
            <div className="w-0 h-0 hidden"></div>
          </div>
        ))}
      {favoritedPeers?.data?.data?.map((peer) => (
        <div
          key={peer.listener_role_id}
          className="min-w-max flex flex-col gap-y-3 justify-baseline items-center cursor-pointer md:mb-4"
          onClick={() => {
            history.push(`${ROUTE_PATH.PEER_DETAILS}?listenerId=${peer.listener_role_id}`);
          }}
        >
          <Avatar
            variant={dfMdMedia ? 'normal' : 'large'}
            available={peer.is_available}
            isPeerListener={peer.is_listener}
            image={peer.profile_photo_url_square}
          />
          <p
            data-testid={`peer-${peer.display_name}`}
            className="
              text-center text-gray-800 text-sm font-medium leading-tight truncate max-w-[100px]
              md:max-w-[64px]
            "
          >
            {peer.display_name}
          </p>
        </div>
      ))}
      {favoritedPeers.data?.count === 0 && !favoritedPeers.isLoading && (
        <div className="font-manrope text-center w-full pb-4 space-y-2">
          <p className="text-lg">Follow Peers to save them here!</p>
          <Link
            className="flex text-violet-950 text-base font-semibold items-center justify-center gap-1"
            to={ROUTE_PATH.RECOMMENDED_PEERS}
          >
            Say hello to a new Peer
            <CircledArrowRightIcon />
          </Link>
        </div>
      )}
      {!favoritedPeers.isLoading && favoritedPeers.data?.count !== 0 && (
        <Link className="md:mb-4" to={ROUTE_PATH.RECOMMENDED_PEERS}>
          <PlusWithCircle width={dfMdMedia ? 64 : 100} height={dfMdMedia ? 64 : 100} />
        </Link>
      )}
    </BlockHorizontalScroll>
  );
};

const ConnectNowContainer: React.FC<{ availableListeners: UseQueryResult<AvailableListenersResponse, unknown> }> = ({
  availableListeners,
}) => {
  const history = useHistory();
  const dfMdMedia = useMediaQuery('md');

  return (
    <BlockHorizontalScroll
      dataTestId="my-peer-section"
      className="md:border md:rounded-lg"
      contentClassName={`
        flex overflow-x-scroll gap-x-3
        md:flex-wrap md:gap-x-5 md:-mb-4
      `}
      isLoading={availableListeners.isLoading}
      title="Connect Now"
      onViewAll={() => {
        history.push({ pathname: ROUTE_PATH.AVAILABLE_LISTENERS });
      }}
      subtitle={
        availableListeners?.data?.count && availableListeners.data.count > 0
          ? 'Listeners available to connect right now'
          : 'No listeners available right now'
      }
    >
      {availableListeners.isLoading &&
        [...Array(4).keys()].map((int) => (
          <div key={int} className="max-w-full flex flex-col gap-y-3 justify-baseline items-start md:mb-4">
            <AvatarSkeleton variant={dfMdMedia ? 'normal' : 'large'} isPeerListener />
            <Skeleton variant="text" className="w-20 md:w-15 h-5" />
            <div className="w-0 h-0 hidden"></div>
            <div className="w-0 h-0 hidden"></div>
            <div className="w-0 h-0 hidden"></div>
          </div>
        ))}
      {availableListeners?.data?.data?.map((peer) => (
        <div
          key={peer.listener_role_id}
          className={`min-w-max flex flex-col gap-y-3 justify-baseline items-center cursor-pointer md:mb-4`}
          onClick={() => {
            history.push(`${ROUTE_PATH.PEER_DETAILS}?listenerId=${peer.listener_role_id}`);
          }}
        >
          <Avatar
            variant={dfMdMedia ? 'normal' : 'large'}
            available={peer.is_available}
            isPeerListener={peer.is_listener}
            image={peer.profile_photo_url_square}
          />
          <p
            data-testid={`peer-${peer.display_name}`}
            className="text-center text-gray-800 text-sm font-medium leading-tight truncate max-w-[100px] md:max-w-[64px]"
          >
            {peer.display_name}
          </p>
        </div>
      ))}
    </BlockHorizontalScroll>
  );
};

const NewPeersSection: React.FC<{ newPeers: UseQueryResult<PeerResponse, unknown> }> = ({ newPeers }) => {
  const history = useHistory();

  const dfMdMedia = useMediaQuery('md');

  return (
    <>
      <BlockHorizontalScroll
        className="md:border md:rounded-lg"
        isLoading={newPeers.isFetching}
        title="Recommended Peers"
        subtitle={newPeers.data ? `${newPeers.data.count} Peers have relatable experiences` : ''}
        onViewAll={() => {
          history.push(ROUTE_PATH.RECOMMENDED_PEERS);
        }}
        showNavigationButton={dfMdMedia}
        contentClassName="md:gap-4"
      >
        {newPeers.isLoading &&
          [...Array(10).keys()].map((int) => (
            <div key={int} className="min-w-max flex flex-col gap-y-3 justify-baseline items-start cursor-pointer">
              <AvatarSkeleton variant={dfMdMedia ? 'huge' : 'x-large'} isPeerListener />
              <Skeleton variant="text" className="w-24 h-5" />
              <Skeleton variant="text" className="w-40 h-5" />
            </div>
          ))}
        {newPeers.data &&
          newPeers.data.data.map((peer) => (
            <PeerCard
              key={peer.listener_role_id}
              className="cursor-pointer min-w-fit"
              name={peer.display_name}
              variant={dfMdMedia ? 'desktopSmall' : 'small'}
              available={peer.is_available}
              isPeerListener={peer.is_listener}
              img={peer.profile_photo_url_square}
              description={peer.about_me}
              // @ts-ignore
              areasLabels={peer?.challenge_areas?.map((challengeArea) => challengeArea.name)}
              traits={peer.tags.map((tag) => tag.name)}
              onClick={() => {
                history.push(`${ROUTE_PATH.PEER_DETAILS}?listenerId=${peer.listener_role_id}`);
              }}
            />
          ))}
      </BlockHorizontalScroll>
    </>
  );
};

export const ExperienceSection: React.FC<{
  textSearch: boolean;
  user?: User;
  experiences: UseQueryResult<RecommendedExperienceResponse | null, unknown>;
  audioStopAll: () => void;
  playerOpen?: number;
  setPlayerOpen: Dispatch<React.SetStateAction<number | undefined>>;
  title?: string;
  subtitle?: string;
}> = ({
  textSearch,
  user,
  experiences: recommendedExperiences,
  audioStopAll,
  playerOpen,
  setPlayerOpen,
  title,
  subtitle,
}) => {
  const playBackMutation = useListenerAudioPlaybackMutation();
  const playMutation = useListenerAudioPlayMutation();
  const queryClient = useQueryClient();
  const favoriteMutation = useFavoriteExperience();
  const unfavoriteMutation = useUnfavoriteExperience();
  const { getParentByParentId } = useTagGroups();
  const peerClientFlag = useFeatureFlag(Features.PEER_CLIENT_RELATIONSHIP);

  const [currentPlaybackId, setCurrentPlaybackId] = useState<number>();
  const [playing, setPlaying] = useState(false);

  const [scheduleModal, setScheduleModal] = useState<number>();
  const [scheduleAvailableNow, setScheduleAvailableNow] = useState(false);

  const history = useHistory();

  const dfMdMedia = useMediaQuery('md');

  const playerContainerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <BlockHorizontalScroll
        className="md:border md:rounded-lg pb-3"
        isLoading={recommendedExperiences.isFetching}
        title={title ? title : peerClientFlag ? 'Connections' : 'Experiences for you'}
        onViewAll={() => history.push(ROUTE_PATH.EXPERIENCES)}
        showViewAll={!peerClientFlag}
        subtitle={
          subtitle
            ? subtitle
            : recommendedExperiences?.data?.data.length
              ? 'Find and connect with someone who has been there'
              : 'No experiences found matching your search filters'
        }
        showNavigationButton={dfMdMedia && (recommendedExperiences.data?.data?.length ?? 0) > 2}
        contentClassName="md:gap-4 h-[380px]"
        recommendedExperiences={recommendedExperiences}
      >
        {recommendedExperiences.isLoading &&
          [1, 2, 3, 4].map((int) => (
            <div key={int}>
              <Skeleton className="h-80 w-80 pt-5" variant="rounded" />
              <Skeleton variant="text" className="w-24 h-5 pt-4" />
              <Skeleton variant="text" className="w-40 h-5 pt-4" />
              <Skeleton variant="text" className="w-24 h-5 pt-4" />
            </div>
          ))}
        {recommendedExperiences.data &&
          recommendedExperiences.data.data.map((experience, idx) => (
            <Experience
              experienceId={experience.id}
              className="shrink-0"
              variant={peerClientFlag.enabled ? 'experiencePeer' : dfMdMedia ? 'desktopSmall' : 'small'}
              imageUrl={experience.profile_photo_square_file_url}
              displayName={experience.name}
              audioUrl={experience.audio_url}
              caption={experience.excerpt}
              goToProfile={() => {
                history.push(`${ROUTE_PATH.PEER_DETAILS}?listenerId=${experience.listener_role_id}`);
              }}
              // @ts-ignore
              areas={experience.area_ids?.map((x) => getParentByParentId(x)?.name)}
              favorited={experience.is_favorite}
              onFollow={() => {
                if (peerClientFlag.enabled) {
                  if (experience.is_favorite) {
                    unfavoriteMutation.mutate(
                      {
                        tagId: 0,
                        listenerId: experience.listener_role_id,
                        audioId: experience.id,
                      },
                      {
                        onSuccess: () => {
                          /*
                            here I'm manually setting is_favorite to false on success so we keep the item in the list.
                            this way if they accidently unfavorited they can refavorite it easily
                            */
                          queryClient.setQueryData(['favoriteExperiences'], (oldFavoritesData: any) => {
                            let foundExperience: FavoriteExperience = oldFavoritesData.find(
                              (oldExperience: FavoriteExperience) => oldExperience.id === experience.id,
                            );
                            foundExperience.is_favorite = false;
                            return [
                              ...oldFavoritesData.map((oldExperience: FavoriteExperience) =>
                                oldExperience.id !== experience.id ? oldExperience : foundExperience,
                              ),
                            ];
                          });
                        },
                      },
                    );
                  } else {
                    favoriteMutation.mutate(
                      {
                        tagId: 0,
                        listenerId: experience.listener_role_id,
                        audioId: experience.id,
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['favoriteExperiences']);
                        },
                      },
                    );
                  }
                  experience.is_favorite = !experience.is_favorite;
                } else {
                  return undefined;
                }
              }}
              playing={playerOpen === experience.id && playing}
              setPlaying={setPlaying}
              onPlay={() => {
                audioStopAll();
                setPlayerOpen(experience.id);
                playMutation.mutate(
                  {
                    audioId: experience.id,
                    listenerId: experience.listener_role_id,
                  },
                  {
                    onSuccess: (listenerAudioPlayback) => {
                      setCurrentPlaybackId(listenerAudioPlayback.id);
                    },
                  },
                );
              }}
              key={idx}
              openPlayer={playerOpen === experience.id}
              setOpenPlayer={setPlayerOpen}
              onTimeUpdate={(e: React.SyntheticEvent<HTMLAudioElement>) => {
                const percent = Math.round((e.currentTarget.currentTime / experience.duration) * 100);
                // if the percent is divisible by 10
                if (percent % 10 === 0) {
                  currentPlaybackId &&
                    playBackMutation.mutate({
                      audioId: currentPlaybackId,
                      percentCompleted: percent,
                    });
                }
              }}
              available={experience.is_available}
              isPeerListener={experience.is_listener}
              playerContainer={playerContainerRef?.current}
              onConnect={() => {
                if (peerClientFlag.enabled) {
                  setScheduleModal(experience.listener_role_id);
                  setScheduleAvailableNow(experience.is_available);
                } else {
                  return undefined;
                }
              }}
              displayClient={user?.client_id === experience.client_id}
              clientLogo={experience.client_image_url}
              backgroundTags={experience.background_tags}
            />
          ))}
      </BlockHorizontalScroll>
      <div ref={playerContainerRef} />
      {scheduleModal && (
        <SchedulingModal
          open={!!scheduleModal}
          isNow={scheduleAvailableNow}
          onExit={() => {
            setScheduleModal(undefined);
          }}
          listenerId={scheduleModal}
        />
      )}
    </>
  );
};

const CallConnectionCards: React.FC<{
  callRequests: CallRequest[] | undefined;
}> = ({ callRequests }) => {
  const history = useHistory();

  const [isNow, setIsNow] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [originalRequestId, setOriginalRequestId] = useState<number>();
  const [listenerId, setListenerId] = useState<number>();
  const [postCallIdForPostCallModal, setPostCallIdForPostCallModal] = useState<number>();

  const { data: user } = useUser();
  // if the user has a timezone, use that, otherwise use the browser's timezone
  const userTimeZone = user?.timezone ? user.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZoneString = new Intl.DateTimeFormat('en-US', {
    timeZoneName: 'short',
    timeZone: userTimeZone,
  })
    .format()
    .split(' ')[1];
  const { data: incompletePostCalls } = usePostCalls(user?.caller_role_id, true);
  const postCallMutation = useUpdatePostCall();
  const { data: tagGroups } = useTagGroups();
  const { data: activeCalls } = useActiveCalls(user?.caller_role_id);

  const dfMdMedia = useMediaQuery('md');

  const postCallModalForm = useRef<PostCallFormRef>(null);

  const pendingRequests = callRequests?.filter(
    (request) => request.connections[0].status === 'matched' || request.status === 'backup_pending',
  );
  const unavailableRequests = callRequests
    ?.filter((request) => request.connections[0].status === 'canceled' || request.connections[0].status === 'passed')
    .filter((request) => pendingRequests?.includes(request) === false);
  let backupRequests = callRequests?.filter(
    (request) => request.connections[0].status === 'offered' && request.is_backup_request === true,
  );
  const scheduledCalls = callRequests?.filter(
    (request) => ['accepted', 'offered'].includes(request.connections[0].status) && request.is_backup_request === false,
  );

  const hasCard =
    (pendingRequests && pendingRequests.length > 0) ||
    (unavailableRequests && unavailableRequests.length > 0) ||
    (backupRequests && backupRequests.length > 0) ||
    (scheduledCalls && scheduledCalls.length > 0) ||
    (incompletePostCalls && incompletePostCalls.length > 0) ||
    (activeCalls && activeCalls.length > 0);

  useEffect(() => {
    if (postCallIdForPostCallModal && !dfMdMedia) {
      sessionStorage.setItem(
        `postCallForm-${postCallIdForPostCallModal}`,
        JSON.stringify({
          step: postCallModalForm?.current?.step,
          ...postCallModalForm?.current?.getValues(),
        }),
      );
      history.push(`${ROUTE_PATH.POSTCALLSEQUENCE}?postCallId=${postCallIdForPostCallModal}`);
    }
  }, [postCallIdForPostCallModal, dfMdMedia, history]);

  return hasCard ? (
    <>
      <div className="w-full p-4 bg-white mb-4 md:mb-0 md:border md:rounded-lg">
        <div className="text-gray-800 text-xl font-bold leading-[30px] pt-2 pb-6">My connections</div>
        <div className="gap-y-2 flex flex-col items-center">
          {activeCalls?.map((activeCall) => <ActiveCallCard key={activeCall.id} activeCall={activeCall} />)}
          {backupRequests?.map((backupRequest) => (
            <CallCardWithData
              key={backupRequest.id}
              callCardVariant="backup"
              callData={backupRequest}
              userTimeZone={userTimeZone}
              timeZoneString={timeZoneString}
            />
          ))}
          {pendingRequests?.map((pendingCall) => (
            <CallCardWithData
              key={pendingCall.id}
              callCardVariant="pending"
              callData={pendingCall}
              showRescheduleModal={showRescheduleModal}
              userTimeZone={userTimeZone}
              timeZoneString={timeZoneString}
            />
          ))}
          {scheduledCalls?.map((scheduledCall) => (
            <CallCardWithData
              key={scheduledCall.id}
              callCardVariant="scheduled"
              callData={scheduledCall}
              showRescheduleModal={showRescheduleModal}
              userTimeZone={userTimeZone}
              timeZoneString={timeZoneString}
            />
          ))}
          {unavailableRequests?.map((unavailableRequest) => (
            <CallCardWithData
              key={unavailableRequest.id}
              callCardVariant="unavailable"
              callData={unavailableRequest}
              showRescheduleModal={showRescheduleModal}
              userTimeZone={userTimeZone}
              timeZoneString={timeZoneString}
            />
          ))}
          {/* This is essentially the completed calls */}
          {incompletePostCalls?.map((incompletePostCall) => (
            <CallCard
              data-testid={`completed-call-${incompletePostCall.id}`}
              key={incompletePostCall.id}
              variant="completed"
              className="w-full md:max-w-full"
              title="Completed Call"
              secondaryText={moment
                .utc(incompletePostCall.call.ended_at)
                .tz(userTimeZone)
                .format(`[Today], hh:mm a [${timeZoneString}]`)}
              available={incompletePostCall.call.listener_role.available_now}
              menuItems={[
                {
                  title: 'Report',
                  onClick: () => {
                    history.push(
                      `${ROUTE_PATH.HELP}?option=call-report&call-report=true&call_id=${incompletePostCall.call.id}`,
                    );
                  },
                  icon: <SupportBubbleIcon />,
                },
                {
                  title: 'Review',
                  onClick: () => {
                    if (dfMdMedia) {
                      setPostCallIdForPostCallModal(incompletePostCall.id);
                    } else {
                      history.push(`${ROUTE_PATH.POSTCALLSEQUENCE}?postCallId=${incompletePostCall.id}`);
                    }
                  },
                  icon: <CommentsIcon />,
                },
                {
                  title: 'Dismiss',
                  onClick: () => {
                    postCallMutation.mutate({ postCallId: incompletePostCall.id, payload: {} });
                  },
                  icon: <TrashIcon />,
                },
              ]}
              onButtonClick={() => history.push(`${ROUTE_PATH.POSTCALLSEQUENCE}?postCallId=${incompletePostCall.id}`)}
              // @ts-ignore
              areaLabelVariants={tagGroups
                ?.filter((tagGroup) => incompletePostCall.call.request.tag_group_ids?.includes(tagGroup.id))
                .map((tagGroup) => tagGroup.name)}
              img={incompletePostCall.call.listener_role.profile_photo_square_file_url}
            />
          ))}
        </div>
      </div>
      {scheduleModal && (
        <SchedulingModal
          open={scheduleModal}
          isNow={isNow}
          onExit={() => {
            setIsNow(false);
            setListenerId(undefined);
            setOriginalRequestId(undefined);
            setScheduleModal(false);
          }}
          originalRequestId={originalRequestId}
          listenerId={listenerId!}
        />
      )}
      {postCallIdForPostCallModal && (
        <PostCallModal
          formRef={postCallModalForm}
          open={!!postCallIdForPostCallModal}
          onClose={() => setPostCallIdForPostCallModal(undefined)}
          postCallId={postCallIdForPostCallModal}
        />
      )}
    </>
  ) : null;

  function showRescheduleModal(callData: CallRequest) {
    setIsNow(callData.connections[0].listener_role.available_now);
    setListenerId(callData.connections[0].listener_role.id);
    setOriginalRequestId(callData.id);
    setScheduleModal(true);
  }
};

const ExploreAreasSection: React.FC<{ className: string }> = ({ className }) => {
  return (
    <BlockHorizontalScroll
      className={twMerge('md:border md:rounded-lg', className)}
      contentClassName="flex overflow-x-scroll gap-2 md:flex-wrap"
      title="Explore all the areas"
      showViewAll={false}
    >
      {Object.keys(AREA_LABEL_VARIANT).map((areaName: string) => (
        <Link key={areaName} to={`${ROUTE_PATH.AREA_EXPERIENCES}?${areaName}`} className="rounded-[100px]">
          <AreaChip className="md:text-base" variant={areaName as keyof typeof AREA_LABEL_VARIANT} />
        </Link>
      ))}
    </BlockHorizontalScroll>
  );
};
