import { useHistory } from 'react-router-dom';

import { Button } from '@kindlyhuman/component-library';

import { MobileMenuHeader, DesktopMenuHeader, SubHeader } from '../../components/MenuHeader';
import { FormBlock } from '../../components/common/form_block';

import { PLUSVirtualPrimaryCare, RecuroLogo, TelemedDetailPng } from '../../assets';

import { ROUTE_PATH } from '../../routes/route-paths';

import getPartnerResourceConfig from './partner-resource-config';
import { Spinner } from '../../components/common';
import { useExternalProviderPlan, useTelemedRedirect } from '../../hooks/useExternalProviderPlans';
import { useUser } from '../../hooks/useUser';
import { useMediaQuery } from '../../hooks/useMediaQuery';

export const CrisisPage: React.FC = () => {
  const { data: user, isLoading: userLoading } = useUser();

  // TODO: add this line in to get the ssoLinkUrl
  // const { data: ssoLinkUrl, isLoading: ssoLinkUrlPending } = useTelemedRedirect(user?.id || 0);
  const ssoLinkUrl = { redirect_url: 'https://member.recurohealth.com' };
  const ssoLinkUrlPending = false;

  const { data: providerPlans, isLoading: externalPlanLoading } = useExternalProviderPlan();
  const providerPlan =
    providerPlans?.find((plan) => {
      return plan.id === user?.caller_role.active_subscription?.package.external_plan_id;
    }) ?? null;
  console.log(providerPlan?.provider_types);
  const hasWorklife = !providerPlan?.provider_types.includes('crisis_lite');

  const partnerResourceConfig = getPartnerResourceConfig(user, providerPlan);
  const navigate = useHistory();

  const dfMdMedia = useMediaQuery('md');

  if (externalPlanLoading || userLoading) return <Spinner />;

  const longDescriptionText = hasWorklife
    ? 'Your benefit includes 24/7 access to compassionate counseling and resources so you’re always supported when navigating life’s challenges. From day-to-day challenges to a sudden critical event, Care Managers are available 24/7 to help you with personal, family, and work-related concerns. You also have access to 3  free in-person or virtual visits with a  licensed behavioral health clinician per need, per year as well referrals to family support, financial planning, and legal resources.'
    : 'Your benefit includes 24/7 access to compassionate counseling and resources so you’re always supported when navigating life’s challenges. From day-to-day challenges to a sudden critical event, Care Managers are available 24/7 to help you with personal, family, and work-related concerns. You also have access to 3  free in-person or virtual visits with a  licensed behavioral health clinician per need, per year.';

  const name = '+Care';
  const description = '24/7 access to Care Managers to help you navigate most health needs.';
  const longDescription = (
    <div className="flex flex-col gap-y-4">
      {longDescriptionText}
      <div>ONLINE ACCESS: Click the link below to schedule a visit online.</div>
      <div>
        PHONE ACCESS: Schedule a visit by phone by calling <div>855-6RECURO (855-673-2876)</div>
      </div>
    </div>
  );
  const providerText =
    'PLUS services are provided independently by Recuro Health. © 2023 Recuro Health, Inc. All rights reserved. Recuro Health, Recuro, and the Recuro logo are trademarks of Recuro Health, Inc. and may not be used without written permission. Recuro Health does not guarantee that a physician will write a prescription, and physicians do not prescribe DEA controlled substances, non-therapeutic drugs and certain other drugs which may be harmful because of their potential for abuse. Recuro Health affiliated physicians reserve the right to deny care for potential misuse of services. Medical services provided by physicians are subject to their professional judgment. Recuro Health operates subject to state regulation and some services may not be available in certain states.';
  const providerImage = (
    <div className="w-80 h-48 p-8 bg-[#F6F6F6] rounded-lg shadow border border-neutral-200 grid place-items-center">
      <img className="min-w-full mb-3" src={RecuroLogo} alt="Recuro Crisis Plan" />
    </div>
  );

  const getCTAContent = () => {
    if (partnerResourceConfig.needsAddress) {
      return (
        <>
          <div className="text-gray-800 text-sm font-medium leading-tight">
            We need your home address in order to activate this service.
          </div>
          <div className="mt-3">
            <Button
              slim
              variant="secondary"
              onClick={() => {
                navigate.push(ROUTE_PATH.PROFILE);
              }}
            >
              Add Address
            </Button>
          </div>
        </>
      );
    }

    if (partnerResourceConfig.waitForProcessingDate) {
      return (
        <div className="text-gray-800 text-sm font-medium leading-tight">
          You will have access to +Telemed beginning on {partnerResourceConfig.processingDateString}
        </div>
      );
    }

    return (
      <div className="flex flex-col gap-y-4 gap-x-4 justify-between">
        <a href={ssoLinkUrl?.redirect_url} target="_blank" rel="noopener noreferrer">
          <Button className="w-80" variant="primary" disabled={ssoLinkUrlPending}>
            Schedule Visit Online
          </Button>
        </a>
        <a href="tel:8556732876">
          <Button className="w-80" variant="primary">
            Schedule Visit by Phone
          </Button>
        </a>
      </div>
    );
  };

  return (
    <div className="w-full min-h-screen overflow-auto bg-cover bg-neutral-700 bg-opacity-5 pb-20">
      <DesktopMenuHeader />
      <MobileMenuHeader prompt={true} onBack={() => navigate.goBack()} />
      <div className="hidden md:block max-w-7xl px-6 mx-auto">
        <SubHeader
          className="hidden md:block pt-6 pb-8"
          title={
            <>
              <span className="font-normal">Need to talk with</span> {name}?
            </>
          }
          onBackButtonClick={dfMdMedia ? () => navigate.goBack() : undefined}
        />
        <div className="flex gap-6">
          <FormBlock className="flex justify-between gap-12 space-y-0">
            <div className="font-manrope text-dark">
              <p className="text-xl font-bold">{name}</p>
              <p className="font-medium mt-2">{description}</p>
              <p className="font-medium mt-2">{longDescription}</p>
              <div className="mt-8">{getCTAContent()}</div>
            </div>
            <img className="rounded-lg max-w-72 self-start" src={PLUSVirtualPrimaryCare} alt="physician-img" />
          </FormBlock>
          <FormBlock className="max-w-100">
            <p className="text-xl font-bold">Resource sponsor</p>
            <div className="py-2 px-8 mt-3">{providerImage}</div>
            <div className="mt-3 text-gray-800 text-sm font-medium leading-tight">{providerText}</div>
          </FormBlock>
        </div>
      </div>
      <div className="w-full h-full overflow-y-auto flex-col gap-y-4 grid place-items-center md:hidden">
        <div className="flex-col gap-y-4 grid place-items-center max-w-lg">
          <div className="bg-white min-w-full px-5 py-5">
            <div className="justify-start items-center inline-flex">
              <img src={TelemedDetailPng} className="w-full rounded-lg" alt="physician-img"></img>
            </div>
            <div className="self-stretch text-gray-800 text-4xl font-bold leading-10 mt-3">{name}</div>
            <div className="self-stretch text-gray-800 text-xl font-bold leading-normal mt-3">{description}</div>
            <div className="self-stretch text-gray-800 text-base font-medium leading-normal mt-3">
              {longDescription}
            </div>
          </div>
          <div className="bg-white min-w-full px-5 py-5 grid place-items-center">{getCTAContent()}</div>
          <div className="bg-white px-5 py-5 grid place-items-center pb-20">
            <div className="min-w-full h-7 text-gray-800 text-xl font-bold leading-loose">Powered by</div>
            <div className="py-2 px-8 mt-3">{providerImage}</div>
            <div className="mt-3 text-gray-800 text-sm font-medium leading-tight">{providerText}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
