import { Input, SpinnerIcon, FilterIcon } from '@kindlyhuman/component-library';
import { SearchIcon } from '../../assets/SearchIcon';
import { useEffect, useRef } from 'react';
import { Spinner } from '../../components/common';
import { set } from 'react-hook-form';

interface SearchInputProps {
  query?: string;
  search: (value: string) => void;
  isLoading: boolean;
  searchFilterOpen: boolean;
  setSearchFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SearchInput = ({ query, search, isLoading, searchFilterOpen, setSearchFilterOpen }: SearchInputProps) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (query && ref.current) {
      ref.current.value = query;
    }
  }, [query]);

  return (
    <div className="flex flex-col grow no-wrap gap-4 items-start pl-4 md:items-center">
      <div className="pt-4 text-center text-gray-800 text-lg font-bold leading-normal">How can we help you today?</div>
      <div className="md:max-w-2xl w-full flex no-wrap justify-center items-center gap-6">
        <Input
          onKeyUp={(event) => {
            // Number 13 is the "Enter" key on the keyboard
            if (event.keyCode === 13) {
              // Cancel the default action, if needed
              event.preventDefault();
              ref.current && search(ref.current?.value);
            }
          }}
          className="w-full"
          ref={ref}
          type="search"
          placeholder="How can we help?"
          onIconClick={() => {}}
        />
        {isLoading ? (
          <button onClick={() => ref.current && search(ref.current?.value)} className="border-2 mr-4 rounded-lg">
            <SpinnerIcon className="cursor-pointer h-11 w-11 p-0" />
          </button>
        ) : (
          <button onClick={() => ref.current && search(ref.current?.value)} className="border-2 p-3  rounded-lg">
            <SearchIcon className="h-5 w-5 text-white cursor-pointer" />
          </button>
        )}
        <button onClick={() => setSearchFilterOpen(true)} className="border-2 p-3 mr-4 rounded-lg">
          <FilterIcon className="h-5 w-5 text-white cursor-pointer" />
        </button>
      </div>
    </div>
  );
};
