import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { Switch, useLocation, useHistory, Route } from 'react-router-dom';
import PrivateRoute from './privateRoute';
import { ROUTE_PATH } from './route-paths';
import { Spinner } from '../components/common';

// Page Imports
import {
  VerificationPage,
  HomePage,
  Profile,
  MyPreferences,
  Help,
  Support,
  ResetPassword,
  NotFound404,
  Counseling,
  Telemed,
  WelcomePage,
  ActiveCall,
  PostCallSequnece,
  MyPlan,
  SignupPage,
  LoginPage,
  OnboardingStepper,
  DependentsPage,
  AvailableListenersPage,
  SelectPlan,
  PeerDetailPage,
  PwaRedirectPage,
} from '../containers';

// These could be added to the containers index
import { CallHistory } from '../containers/member-management/call-history';
import { PrivacyPolicyPage, TermsPage } from '../containers/terms-and-privacy-policy/terms-and-privacy-policy';
import AboutUsPage from '../containers/about-us/about-us';
import AccountSettingsPage from '../containers/account-settings/account-settings';
import { PaymentMethodPage } from '../containers/member-management/payment-method';
import { User, useUser } from '../hooks/useUser';
import { useNotifications } from '../hooks/useNotifications';
import { useClientCode } from '../hooks/useClientCode';
import { ResourceDetailPage } from '../containers/resources/resource-detail';
import { OnboardingPayment } from '../containers/onboarding/payment';
import AuthenticatePage from '../containers/authenticate/authenticate';
import { FavoriteExperiencesPage } from '../containers/favorite-experiences/favorite-experiences';
import { MyPeersPage } from '../containers/my-peers/my-peers';
import { RecommendedPeersPage } from '../containers/recommended-peers/recommended-peers';
import { ChangePasswordPage } from '../containers/change-password/change-password';
import { MemberManagementPage } from '../containers/member-management/member-management';
import { AreaExperiencesPage } from '../containers/area-experiences/area-experiences';
import { RecommendedExperiencesPage } from '../containers/recommended-experiences/recommended-experiences';
import { SupportPage } from '../containers/support/support';
import { ResourcePage } from '../containers/resources/resources';
import { CrisisPage } from '../containers/resources/crisis-detail';
import { VirtualUrgentPage } from '../containers/resources/urgent-detail';

declare global {
  interface Window {
    branch: any;
  }
}

const useOnboardingNavigation = (userDetails?: User) => {
  const { pathname } = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (userDetails?.is_partial) {
      history.replace(ROUTE_PATH.ONBOARDING);
    }
    // adding pathname to this dep array because we want to check this everytime a user hits a page in the app.
  }, [userDetails, history, pathname]);
};

const useGroupCodeNavigation = () => {
  const allowedURLS = ['/resource'];
  const { pathname } = useLocation();
  const groupId = pathname.split('/')[1];
  const isGroupId = Object.values(ROUTE_PATH).every((item) => {
    if (allowedURLS.some((url) => pathname.startsWith(url))) return false;
    return pathname !== item;
  });
  const history = useHistory();
  const { data: clientCode, isError: clientCodeError } = useClientCode({
    partnerCode: isGroupId ? groupId : undefined,
  });

  useEffect(() => {
    clientCodeError && history.replace(ROUTE_PATH['404']);
    if (clientCode) {
      sessionStorage.setItem('clientCode', groupId);
      history.replace(ROUTE_PATH.WELCOME_PAGE);
    }
  }, [clientCode, clientCodeError, groupId, history]);
};

const Routes: React.FunctionComponent = (): JSX.Element => {
  const { pathname, search } = useLocation();
  const { data: user, authenticateWithSSOToken, isLoading: userLoading, fetchStatus: userFetchStatus } = useUser();
  useOnboardingNavigation(user);

  const ssoRedirect = new URLSearchParams(search).get('sso_redirect');

  const ssoAuthentication = useCallback(
    (ssoRedirect: string | null) => {
      if (ssoRedirect !== null && authenticateWithSSOToken.data === undefined && authenticateWithSSOToken.isIdle) {
        authenticateWithSSOToken.mutate({ token: ssoRedirect });
      }
    },
    [authenticateWithSSOToken],
  );

  useNotifications();

  useEffect(() => {
    ssoAuthentication(ssoRedirect);
  }, [ssoRedirect, ssoAuthentication]);

  useGroupCodeNavigation();

  // this sends you to the top of the window when you change pages
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // if the user does not have an auth token we won't try to get the user, thus the fetch status will be idle.
  if (userFetchStatus !== 'idle' && userLoading) {
    return <Spinner />;
  }

  return (
    <Switch>
      {/* non authenticated routes */}
      <Route path={ROUTE_PATH.LOGIN} exact component={LoginPage}></Route>
      <Route path={ROUTE_PATH.SIGNUP} exact component={SignupPage}></Route>
      <Route path={ROUTE_PATH.LANDING_PAGE} exact component={WelcomePage}></Route>
      <Route path={ROUTE_PATH.WELCOME_PAGE} exact component={WelcomePage}></Route>
      <Route path={ROUTE_PATH.PWA_REDIRECT_PAGE} exact component={PwaRedirectPage}></Route>
      <Route path={ROUTE_PATH.RESET_PASSWORD} exact component={ResetPassword}></Route>
      <Route path={ROUTE_PATH.PLAN} exact component={MyPlan}></Route>
      {/* TODO should this be included in non-private routes? we have login on page to redirect but seems redundant w PrivateRoute */}
      <Route path={ROUTE_PATH.ONBOARDING} exact component={OnboardingStepper}></Route>
      <Route path={ROUTE_PATH.SELECT_PLAN} exact component={SelectPlan}></Route>
      <Route path={ROUTE_PATH.ONBOARDING_PAYMENT} exact component={OnboardingPayment}></Route>
      <Route path={ROUTE_PATH.VERIFICATION} exact component={VerificationPage}></Route>
      <Route path={ROUTE_PATH.AUTHENTICATE} exact component={AuthenticatePage}></Route>

      {/* authenticated routes */}
      <PrivateRoute path={ROUTE_PATH.ActiveCall} exact component={ActiveCall}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.ONBOARDING} exact component={OnboardingStepper}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.HOME} exact component={HomePage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.PEER_DETAILS} exact component={PeerDetailPage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.DEPENDENTS} exact component={DependentsPage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.FAVORITE_EXPERIENCES} exact component={FavoriteExperiencesPage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.AVAILABLE_LISTENERS} exact component={AvailableListenersPage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.PAYMENT} exact component={PaymentMethodPage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.ACCOUNT_SETTINGS} exact component={AccountSettingsPage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.MY_PEERS} exact component={MyPeersPage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.RECOMMENDED_PEERS} exact component={RecommendedPeersPage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.PROFILE} exact component={Profile}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.MY_PREFERENCES} exact component={MyPreferences}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.CHANGE_PASSWORD} exact component={ChangePasswordPage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.MEMBERSHIP_MANAGEMENT} exact component={MemberManagementPage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.RESOURCES} exact component={ResourcePage}></PrivateRoute>
      <PrivateRoute path={`${ROUTE_PATH.RESOURCE}/:name`} exact component={ResourceDetailPage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.HELP} exact component={Help} />
      <PrivateRoute path={ROUTE_PATH.SUPPORT} exact component={SupportPage} />
      <PrivateRoute path={ROUTE_PATH.ABOUT} exact component={AboutUsPage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.EXPERIENCES} exact component={RecommendedExperiencesPage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.AREA_EXPERIENCES} exact component={AreaExperiencesPage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.TERMS_AND_SERVICE} exact component={TermsPage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.PRIVACY_POLICY} exact component={PrivacyPolicyPage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.RETURN} exact component={HomePage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.COUNSELING} exact component={Counseling}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.TELEMED} exact component={Telemed}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.URGENT} exact component={VirtualUrgentPage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.CRISIS_LITE} exact component={CrisisPage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.POSTCALLSEQUENCE} exact component={PostCallSequnece}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.CALL_HISTORY} exact component={CallHistory}></PrivateRoute>

      {/* 404 page */}
      <Route path={ROUTE_PATH['404']} component={NotFound404}></Route>
    </Switch>
  );
};

export default Routes;
