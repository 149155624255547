import { useHistory, useLocation } from 'react-router-dom';

import { ROUTE_PATH } from '../../routes/route-paths';
import { useState } from 'react';
import { useTagGroups } from '../../hooks/useTagGroups';
import { UseInfiniteQueryResult, useQueryClient } from '@tanstack/react-query';
import {
  FavoriteExperience,
  RecommendedExperienceResponse,
  useFavoriteExperience,
  useInfiniteExperiences,
  useUnfavoriteExperience,
} from '../../hooks/useExperiences';
import {
  AreaChip,
  ChevronDownIcon,
  Experience as ExperienceComponent,
  BriefCaseIcon,
  CommentsIcon,
  HeartIcon,
  SmileIcon,
  UsersIcon,
  WalletIcon,
} from '@kindlyhuman/component-library';
import { useListenerAudioPlaybackMutation, useListenerAudioPlayMutation } from '../../hooks/useListenerAudio';
import { ResourceSection } from '../peer-details/peer-details';
import { useResources } from '../../hooks/useResources';
import { Skeleton } from '@mui/material';
import { AreaChipVariant } from '@kindlyhuman/component-library/dist/lib/components/AreaChip/AreaChip';
import { DesktopMenuHeader, MobileMenuHeader } from '../../components/MenuHeader';

export const variantIcons: Record<AreaChipVariant, React.ReactNode> = {
  Community: <UsersIcon />,
  Emotions: <SmileIcon />,
  Relationships: <CommentsIcon />,
  Work: <BriefCaseIcon />,
  Finances: <WalletIcon />,
  Health: <HeartIcon />,
};

export const AreaExperiencesPage = () => {
  const navigate = useHistory();
  const { data: tagGroups, isLoading } = useTagGroups();
  const { data: resources } = useResources();
  const areaName = useLocation().search.slice(1);
  const tagGroupId = tagGroups?.find((tagGroup) => tagGroup.name === areaName)?.id;
  const recommendedExperiencesQuery = useInfiniteExperiences(tagGroupId, !isLoading);

  return (
    <div className="pb-10 flex flex-col bg-neutral-700 bg-opacity-5 min-h-screen">
      <DesktopMenuHeader />
      <MobileMenuHeader prompt onBack={() => navigate.push(ROUTE_PATH.HOME)} />
      <Header areaName={areaName} />
      <Body recommendedExperiencesQuery={recommendedExperiencesQuery} areaName={areaName} />
      {resources && <ResourceSection resources={resources} />}
      <div className="pt-20" />
    </div>
  );
};

const Header: React.FC<{ areaName: string }> = ({ areaName }) => {
  return (
    <div className="px-4 py-4 flex-col justify-center items-start gap-8 inline-flex">
      <div className="self-stretch flex-col justify-start items-start flex">
        <div className="flex items-center">
          <div className="mr-2">{variantIcons[areaName as keyof typeof AreaChip]}</div>
          <div className="self-stretch text-gray-800 text-2xl font-bold leading-loose">{areaName}</div>
        </div>
        {areaName && (
          <div className="text-stone-500 text-sm font-medium leading-tight pb-6">
            Experiences connected to {areaName}
          </div>
        )}
      </div>
    </div>
  );
};

const Body: React.FC<{
  recommendedExperiencesQuery?: UseInfiniteQueryResult<RecommendedExperienceResponse, unknown>;
  areaName: string;
}> = ({ recommendedExperiencesQuery, areaName }) => {
  const experiences = recommendedExperiencesQuery?.data?.pages.map((page) => page.data).flat() || [];
  const [playerOpen, setPlayerOpen] = useState<number>();
  const [playing, setPlaying] = useState(false);
  const queryClient = useQueryClient();
  const playBackMutation = useListenerAudioPlaybackMutation();
  const playMutation = useListenerAudioPlayMutation();
  const [currentPlaybackId, setCurrentPlaybackId] = useState<number>();
  const favoriteMutation = useFavoriteExperience();
  const unfavoriteMutation = useUnfavoriteExperience();
  const { getParentByParentId } = useTagGroups();

  const audioStopAll = () => {
    document.querySelectorAll('audio').forEach((el) => {
      el.pause();
      el.currentTime = 0;
    });
  };

  return (
    <div className="flex flex-col gap-y-3">
      {experiences &&
        !recommendedExperiencesQuery?.isLoading &&
        experiences?.map((experience, idx) => {
          return (
            <ExperienceComponent
              variant="wide"
              className="bg-white"
              imageUrl={experience.profile_photo_square_file_url}
              displayName={experience.name}
              audioUrl={experience.audio_url}
              caption={experience.excerpt}
              experienceId={experience.id}
              hideFollow
              // @ts-ignore
              areas={experience.area_ids
                .map((x) => getParentByParentId(x)?.name)
                .sort((name) => (name === areaName ? -1 : 1))}
              favorited={experience.is_favorite}
              duration={experience.duration}
              onFollow={() => {
                if (experience.is_favorite) {
                  unfavoriteMutation.mutate(
                    {
                      tagId: 0,
                      listenerId: experience.listener_role_id,
                      audioId: experience.id,
                    },
                    {
                      onSuccess: () => {
                        /*
                          here I'm manually setting is_favorite to false on success so we keep the item in the list.
                          this way if they accidently unfavorited they can refavorite it easily
                          */
                        queryClient.setQueryData(['favoriteExperiences'], (oldFavoritesData: any) => {
                          let foundExperience: FavoriteExperience = oldFavoritesData.find(
                            (oldExperience: FavoriteExperience) => oldExperience.id === experience.id,
                          );
                          foundExperience.is_favorite = false;
                          return [
                            ...oldFavoritesData.map((oldExperience: FavoriteExperience) =>
                              oldExperience.id !== experience.id ? oldExperience : foundExperience,
                            ),
                          ];
                        });
                      },
                    },
                  );
                } else {
                  favoriteMutation.mutate(
                    {
                      tagId: 0,
                      listenerId: experience.listener_role_id,
                      audioId: experience.id,
                    },
                    {
                      onSuccess: () => {
                        queryClient.invalidateQueries(['favoriteExperiences']);
                      },
                    },
                  );
                }
                experience.is_favorite = !experience.is_favorite;
              }}
              playing={playerOpen === experience.id && playing}
              setPlaying={setPlaying}
              onPlay={() => {
                audioStopAll();
                setPlayerOpen(experience.id);
                playMutation.mutate(
                  {
                    audioId: experience.id,
                    listenerId: experience.listener_role_id,
                  },
                  {
                    onSuccess: (listenerAudioPlayback) => {
                      setCurrentPlaybackId(listenerAudioPlayback.id);
                    },
                  },
                );
              }}
              key={idx}
              openPlayer={playerOpen === experience.id}
              setOpenPlayer={setPlayerOpen}
              onTimeUpdate={(e: React.SyntheticEvent<HTMLAudioElement>) => {
                const percent = Math.round((e.currentTarget.currentTime / experience.duration) * 100);
                // if the percent is divisible by 10
                if (percent % 10 === 0) {
                  currentPlaybackId &&
                    playBackMutation.mutate({
                      audioId: currentPlaybackId,
                      percentCompleted: percent,
                    });
                }
              }}
              available={experience.is_available}
              isPeerListener={experience.is_listener}
            />
          );
        })}
      {recommendedExperiencesQuery?.isLoading &&
        Array(10)
          .fill(0)
          .map((_, index) => <ExperienceSkeletonWide key={index} />)}
      {experiences?.length === 0 && (
        <div className="flex flex-col justify-center items-center py-8">
          <div className="text-center text-violet-950 text-base font-bold leading-normal">No experiences found</div>
        </div>
      )}
      <div className="flex justify-center items-center py-8">
        <TextButton recommendedExperiencesQuery={recommendedExperiencesQuery} />
      </div>
    </div>
  );
};

const TextButton: React.FC<{
  recommendedExperiencesQuery?: UseInfiniteQueryResult<RecommendedExperienceResponse, unknown>;
}> = ({ recommendedExperiencesQuery }) =>
  recommendedExperiencesQuery?.hasNextPage && !recommendedExperiencesQuery?.isFetchingNextPage ? (
    <button
      onClick={() => recommendedExperiencesQuery?.fetchNextPage()}
      className="h-12 py-6 px-6 justify-start items-center gap-1 flex"
    >
      <div className="text-center text-violet-950 text-base font-bold leading-normal">See more</div>
      <ChevronDownIcon width={20} color="#24006B" />
    </button>
  ) : (
    <div className="pt-12" />
  );

export const ExperienceSkeletonWide: React.FC = () => (
  <div className="flex flex-col px-4 py-4 bg-white">
    <div className="flex items-center">
      <Skeleton variant="circular" className="mr-6 h-10 w-10" />
      <Skeleton variant="text" className="w-12 h-5" />
    </div>
    <Skeleton className="h-12 w-full" />
    <Skeleton className="h-12 w-32" />
    <Skeleton className="h-12 w-full" />
  </div>
);
