import { useEffect, useState } from 'react';
import { MobileHeader, DesktopHeader, Stepper, analytics, EventTypes } from '@kindlyhuman/component-library';
import { InterestsStep, PeersStep, ProfileStep } from '..';
import { User, useUser } from '../../hooks/useUser';
import { SetPassword } from './set-password';
import { twMerge } from 'tailwind-merge';
import WelcomeVideo from './components/welcome-video';
import { AddToHome } from './components/add-to-home';
import { isDesktop } from 'react-device-detect';
import { Features, useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useHistory } from 'react-router';
import { ROUTE_PATH } from '../../routes/route-paths';
import moment from 'moment';
import { CacheAxiosResponse } from 'axios-cache-interceptor';
import { useHubspotEventMutation } from '../../hooks/useEvent';
import useAuth from '../../hooks/useAuth';

const OnboardingStepper = () => {
  const { updateUser, data: user } = useUser();
  const textSearchFlag = useFeatureFlag(Features.TEXT_SEARCH);
  const hubspotMutation = useHubspotEventMutation();
  const [step, setStep] = useState(0);
  const [showWelcomeVideo, setShowWelcomeVideo] = useState<boolean>(false);
  const [showAddToHome, setShowAddToHome] = useState<boolean>(false);
  const { authToken } = useAuth();

  // we don't want the user landing on the needs password page when they don't even have an account yet.
  const history = useHistory();
  if (!authToken) {
    history.push(ROUTE_PATH.WELCOME_PAGE);
  }

  const handleNext = () => {
    setStep(step + 1);
  };

  // If text search is enabled, we skip the interests and peers steps
  const handleDone = () => {
    updateUser.mutate(
      { is_partial: false },
      {
        onSuccess: (data: CacheAxiosResponse<User, any>) => {
          hubspotMutation.mutate({ event: 'onboarding_completed' });
          analytics.trackEvent(
            EventTypes.COMPLETED_ONBOARDING,
            user?.client_name,
            data?.data?.gender,
            // getting the nearest decade
            Math.floor(moment().diff(data?.data?.date_of_birth, 'years') / 10) * 10,
          );
          if (isDesktop) {
            setShowWelcomeVideo(true);
          } else {
            setShowAddToHome(true);
          }
        },
      },
    );
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return <SetPassword onComplete={handleNext} />;
      case 1:
        return <ProfileStep onComplete={textSearchFlag.enabled ? handleDone : handleNext} />;
      case 2:
        return <InterestsStep onComplete={handleNext} />;
      case 3:
        return <PeersStep />;
    }
  };

  if (showWelcomeVideo) {
    return <WelcomeVideo />;
  }

  if (showAddToHome) {
    return <AddToHome onComplete={() => setShowWelcomeVideo(true)} />;
  }

  return (
    <>
      <div className="min-h-screen flex flex-col">
        <DesktopHeader className="hidden md:block relative z-10" />
        <MobileHeader className="md:hidden relative z-10" />
        {!textSearchFlag.enabled && (
          <div className="items-center flex flex-col py-4 border-b border-[#E6E6E6] bg-white border-opacity-70 relative z-10">
            <Stepper currentStep={step} steps={['Profile', 'Interests', 'Peers']} />
          </div>
        )}
        <div
          className="
            pb-20 bg-white h-full grow overflow-x-hidden
            md:bg-whiteSmoke
          "
        >
          <div
            className={twMerge(
              'px-5 mx-auto mt-5 md:mt-20',
              step <= 2 ? 'md:max-w-160' : 'md:max-w-7xl md:mx-auto md:px-6',
            )}
          >
            {renderStep()}
          </div>
        </div>
      </div>
    </>
  );
};

export default OnboardingStepper;
