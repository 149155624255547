import { useState, useRef, useMemo, useImperativeHandle, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { LeftArrowWithCircle } from '@kindlyhuman/component-library';
import { useMediaQuery } from '../../hooks/useMediaQuery';

interface HorizontalScrollSectionProps {
  children?: React.ReactNode;
  center?: boolean;
  className?: string;
  showNavigationButton?: boolean | null;
  isLoading?: boolean;
}

export interface HorizontalScrollSectionRef {
  resetScrollPosition: () => void;
}

export const HorizontalScrollSection = forwardRef<HorizontalScrollSectionRef, HorizontalScrollSectionProps>(
  ({ children, className, center = false, showNavigationButton, isLoading }, ref) => {
    const [position, setPosition] = useState<number>(0);
    const horizontalScrollSectionRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      resetScrollPosition: () => {
        if (isLoading) {
          setPosition(0);
        }
      },
    }));

    const dfMdMedia = useMediaQuery('md');

    const { maxPosition, transformValue } = useMemo(() => {
      const horizontalScrollEl = horizontalScrollSectionRef.current;

      if (horizontalScrollEl) {
        const blockCssStyles = window.getComputedStyle(horizontalScrollEl);
        const childCount = horizontalScrollEl.childElementCount ?? 0;
        const childWidth = (horizontalScrollEl.childNodes?.[0] as Element)?.clientWidth ?? 0;
        const blockPaddingLeft = parseFloat(blockCssStyles.getPropertyValue('padding-left')) ?? 0;
        const blockPaddingRight = parseFloat(blockCssStyles.getPropertyValue('padding-right')) ?? 0;
        const blockChildGap =
          (horizontalScrollEl?.scrollWidth - childCount * childWidth - blockPaddingLeft) / (childCount - 1) ?? 0;
        const blockWidth = horizontalScrollEl.clientWidth ?? 0;
        const maxTransformPosition = horizontalScrollEl?.scrollWidth - blockWidth + blockPaddingLeft ?? 0;

        return {
          maxPosition:
            childCount - Math.ceil((blockWidth - blockPaddingLeft - blockPaddingRight) / (childWidth + blockChildGap)),
          transformValue: dfMdMedia
            ? `translateX(-${Math.min(position * (childWidth + blockChildGap), maxTransformPosition)}px)`
            : '',
        };
      }

      return {
        maxPosition: 0,
        transformValue: '',
      };
    }, [position, dfMdMedia, horizontalScrollSectionRef]);

    return (
      <div className="relative overflow-hidden">
        {showNavigationButton && (
          <button
            className={twMerge('absolute top-[100px] left-2 cursor-pointer z-10 rotate-180', !position && 'hidden')}
            onClick={scrollLeft}
          >
            <LeftArrowWithCircle />
          </button>
        )}
        <div
          ref={horizontalScrollSectionRef}
          className={twMerge(
            'flex flex-row p-2 px-4 justify-start gap-x-3 transition-all',
            center ? 'items-center' : 'items-start',
            !showNavigationButton && 'overflow-x-scroll',
            className,
          )}
          style={{
            scrollbarWidth: 'none',
            transform: transformValue,
          }}
        >
          {children}
        </div>
        {showNavigationButton && (
          <button
            className={twMerge('absolute top-[100px] right-2 cursor-pointer z-10', position > maxPosition && 'hidden')}
            onClick={scrollRight}
          >
            <LeftArrowWithCircle />
          </button>
        )}
      </div>
    );

    function scrollLeft() {
      setPosition((currentValue) => currentValue - 1);
    }

    function scrollRight() {
      setPosition((currentValue) => currentValue + 1);
    }
  },
);
