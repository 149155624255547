import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, MobileHeader } from '@kindlyhuman/component-library';

import { ROUTE_PATH } from '../../../routes/route-paths';

import { SupportForm } from '../../../components/common/SupportForm';
import { MobileMenuHeader, DesktopMenuHeader } from '../../../components/MenuHeader';
import Toast from '../../../components/common/PopUpMessage';

const Support: React.FunctionComponent = (): JSX.Element => {
  const navigate = useHistory();

  const search = useLocation().search;

  const { callReport, callId, option, contactUs } = useMemo(() => {
    const urlSearchParams = new URLSearchParams(search);

    return {
      callReport: urlSearchParams.get('call-report'),
      callId: urlSearchParams.get('call_id'),
      option: urlSearchParams.get('option'),
      contactUs: urlSearchParams.get('error'),
    };
  }, [search]);

  return (
    <div className="w-screen min-h-screen bg-[#F6F6F6]">
      {callId ? (
        <MobileHeader
          prompt
          onBack={() => navigate.goBack()}
          goHome={() =>
            navigate.push({
              pathname: ROUTE_PATH.HOME,
            })
          }
        />
      ) : (
        <>
          <DesktopMenuHeader />
          <MobileMenuHeader prompt onBack={() => navigate.goBack()} />
        </>
      )}
      <div className="w-full mx-auto md:max-w-3xl">
        <div className="m-5">
          <div className="flex mt-8 mb-8 flex-col self-stretch gap-1">
            <h2 className="text-gray-900 text-2xl not-italic font-bold leading-8 font-manrope">Get help</h2>
            <p className="text-gray-900 text-base not-italic font-normal leading-6">
              Let us know how we can best provide support for you today.
            </p>
          </div>
        </div>

        <SupportForm
          option={option}
          callReport={callReport}
          callId={callId}
          contactUs={contactUs}
          bottomActionButton={({ isDirty, isSubmitting }) => (
            <div className="m-5 flex justify-center my-12.5">
              <Button
                className="w-full"
                variant="primary"
                type="submit"
                disabled={!isDirty || isSubmitting}
                loading={isSubmitting}
              >
                Submit
              </Button>
            </div>
          )}
          onSuccessSubmitted={() => {
            Toast.success('Your support request has been successfully submitted', undefined);
            navigate.push(ROUTE_PATH.HOME);
          }}
        />
      </div>
    </div>
  );
};

export default Support;
