import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { Button, CallCard, SpinnerIcon } from '@kindlyhuman/component-library';

import { ActiveCall, useEndCallMutation } from '../../../hooks/useCalls';
import { useUser } from '../../../hooks/useUser';
import { useTagGroups } from '../../../hooks/useTagGroups';
import moment from 'moment';
import { usePhoneFormat } from '../../../hooks/usePhoneFormat';

import { ROUTE_PATH } from '../../../routes/route-paths';
import { CALL_UNITS_CALL_DEFAULT_LENGTH } from '../../scheduling-modal/scheduling-modal';

export interface ActiveCallCardProps {
  activeCall: ActiveCall;

  className?: string;
}

export const ActiveCallCard: React.FC<ActiveCallCardProps> = ({
  activeCall,

  className,
}) => {
  const { data: user, isLoading: isUserLoading } = useUser();
  const { data: tagGroups, isLoading: isTagGroupsLoading } = useTagGroups();
  const formattedPhone = usePhoneFormat(activeCall?.phone_number ?? '');
  const endCallMutation = useEndCallMutation();

  const isCallUnits = user?.caller_role.is_call_units;

  return (
    <div
      data-testid={`active-call-${activeCall.id}`}
      className={twMerge(
        'border rounded-lg border-neutral-200 border-opacity-90 p-6 font-manrope space-y-6 w-full md:max-w-full',
        className,
      )}
    >
      {isUserLoading || isTagGroupsLoading ? (
        <div className="w-full h-full flex items-center justify-center">
          <SpinnerIcon />
        </div>
      ) : (
        <>
          <CallCard
            variant="scheduled"
            className="max-w-full border-0 p-0"
            available
            // @ts-ignore
            areaLabelVariants={tagGroups
              ?.filter((tagGroup) => activeCall?.request.tag_group_ids?.includes(tagGroup.id))
              ?.map((tagGroup) => tagGroup.name)}
            img={activeCall.listener_role.profile_photo_square_file_url}
            title={activeCall.listener_role.user.display_name}
            secondaryText={moment(activeCall.request.created_at).format(`[Now,] hh:mm a`)}
          />
          <div className="space-y-2">
            {!user?.onUnlimitedPlan && (
              <div className="flex items-center justify-between text-sm font-bold">
                Your balance
                <span className="text-neutral-700 font-medium">
                  {isCallUnits
                    ? `${user?.caller_role.payment_data.available_minutes / CALL_UNITS_CALL_DEFAULT_LENGTH} calls`
                    : `${user?.caller_role.payment_data.available_minutes} minutes`}
                </span>
              </div>
            )}
            <div className="flex items-center justify-between text-sm font-bold">
              Disconnected? Call
              <a href={`tel:${activeCall?.phone_number}`} className="text-[#240089]">
                {formattedPhone}
              </a>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <Button
              onClick={() => {
                activeCall && endCallMutation.mutate(activeCall.id);
              }}
              variant="secondary"
              loading={endCallMutation.isLoading}
            >
              End Call
            </Button>
            <Link to={`${ROUTE_PATH.HELP}?option=call-report&call_id=${activeCall?.id}`}>
              <Button variant="secondary">Report</Button>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};
