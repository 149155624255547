import { HTMLAttributes, useMemo } from 'react';

import { CalendarIcon, CallCard, CALL_CARD_VARIANT, TrashIcon } from '@kindlyhuman/component-library';
// import moment from 'moment';
import moment from 'moment-timezone';

import { CallRequest, useAcceptBackupConnectionMutation, useRequestCancelMutation } from '../../../hooks/useCalls';
import { useTagGroups } from '../../../hooks/useTagGroups';
import { useUser } from '../../../hooks/useUser';

type CallCardVariant = keyof typeof CALL_CARD_VARIANT;

export interface CallCardWithDataProps extends HTMLAttributes<HTMLDivElement> {
  callCardVariant: CallCardVariant;
  callData: CallRequest;
  showRescheduleModal?: (callData: CallRequest) => void;
  userTimeZone: string;
  timeZoneString: string;
}

export const CallCardWithData: React.FC<CallCardWithDataProps> = ({
  callCardVariant,
  callData,
  showRescheduleModal,
  userTimeZone,
  timeZoneString,
  className,
}) => {
  const { data: user } = useUser();
  const acceptBackupConnection = useAcceptBackupConnectionMutation();
  const cancelMutation = useRequestCancelMutation();
  const { data: tagGroups } = useTagGroups();

  const isCallUnits = user?.caller_role.is_call_units;

  const { menuItems, onButtonClick } = useMemo(() => {
    let onButtonClick;
    const menuItems = [
      ...(['pending', 'scheduled', 'unavailable'].includes(callCardVariant ?? '') && showRescheduleModal
        ? [
            {
              onClick: () => showRescheduleModal(callData),
              title: 'Reschedule',
              icon: <CalendarIcon width={20} height={20} />,
            },
          ]
        : []),
      {
        onClick: () => {
          cancelMutation.mutate(callData.id);
        },
        title: 'Cancel',
        icon: <TrashIcon />,
      },
    ];

    switch (callCardVariant) {
      case 'unavailable':
        if (showRescheduleModal) {
          onButtonClick = () => showRescheduleModal(callData);
        }
        break;
      case 'backup':
        onButtonClick = () => acceptBackupConnection.mutate(callData.connections[0].id);
        break;
    }

    return { menuItems, onButtonClick };
  }, [callData, callCardVariant, acceptBackupConnection, showRescheduleModal, cancelMutation]);

  const makeCallTimeText = () => {
    if (callData.connections[0].scheduled_at === null) {
      return isCallUnits ? `Now for ${callData.call_units_length} minutes` : 'Now';
    }

    const startTime = moment
      .utc(callData.connections[0].scheduled_at)
      .tz(userTimeZone)
      .format(`hh:mm a [${timeZoneString}]`);

    const endTime = moment
      .utc(callData.connections[0].scheduled_at)
      .tz(userTimeZone)
      .add(callData.call_units_length, 'minutes')
      .format(`hh:mm a [${timeZoneString}]`);

    const date = moment.utc(callData.connections[0].scheduled_at).tz(userTimeZone).format('ddd');

    if (
      moment.utc(callData.connections[0].scheduled_at).tz(userTimeZone).dayOfYear() ===
      moment().tz(userTimeZone).dayOfYear()
    ) {
      return isCallUnits ? `Today ${startTime} - ${endTime}` : `Today, ${startTime}`;
    } else {
      return isCallUnits ? `${date} ${startTime} - ${endTime}` : `${date}, ${startTime}`;
    }
  };

  return (
    <CallCard
      data-testid={`${callData.status}-call-${callData.id}`}
      variant={callCardVariant as CallCardVariant}
      className="w-full md:max-w-full"
      menuItems={menuItems}
      onButtonClick={onButtonClick}
      // @ts-ignore
      areaLabelVariants={
        tagGroups
          ?.filter((tagGroup) => callData.tag_group_ids?.includes(tagGroup.id))
          ?.map((tagGroup) => tagGroup.name) ?? []
      }
      img={callData.connections[0].listener_role.profile_photo_square_file_url}
      title={callData.connections[0].listener_role.user.display_name}
      secondaryText={makeCallTimeText()}
    />
  );
};
