import React, { useState } from 'react';
import { Button, MobileHeader, Input, CircledArrowRightIcon, EmailIcon, Dialog } from '@kindlyhuman/component-library';
import '@kindlyhuman/component-library/dist/style.css';
import { AxiosResponse } from 'axios';
import { Link, useHistory } from 'react-router-dom';

import { MemberSignupResponse, getPartialMember, useUser } from '../../hooks/useUser';
import { ROUTE_PATH } from '../../routes/route-paths';
import { emailRegex } from '../../components/common/constants';
import { isGroupSelfEnroll } from '../../hooks/usePackageDetails';
import rollbar from '../rollbar';
import { WelcomeSlogan } from '../../components/common/WelcomeSlogan';

/**
 * Validates an email address.
 * @param email - The email address to validate.
 * @returns False if the email is invalid, true if the email is valid.
 */
const validateEmail = (email: string) => !(email === '' || email === undefined || !emailRegex.test(email));

const SignupPage: React.FC = () => {
  const { signupUser } = useUser();
  const navigate = useHistory();

  const [emailAddress, setEmailAddress] = useState('');
  const [emailError, setEmailError] = useState<{ msg: string; disableInput: boolean } | null>(null);
  const [groupVerificationError, setGroupVerificationError] = useState<string | undefined>();
  const [groupIdValue, setGroupIdValue] = useState('');
  const [showGroupVerification, setShowGroupVerification] = useState(false);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const handleSetEmail = (email: string) => {
    const trimmedEmail = email.trim();
    setEmailAddress(trimmedEmail);

    // Clear the error if the email becomes valid
    if (validateEmail(trimmedEmail)) {
      setEmailError(null);
    }
  };

  const signup = (clientCode: string) =>
    signupUser.mutate(
      { email_address: emailAddress, package_code: clientCode },
      {
        onSuccess: (userResponse: AxiosResponse<MemberSignupResponse>) => {
          setShowGroupVerification(false);
          sessionStorage.setItem('partial_member_id', JSON.stringify(userResponse.data.id));
          navigate.push(`${ROUTE_PATH.VERIFICATION}`, {
            mode: 'welcome',
            email_address: emailAddress,
          });
        },
        onError: (error: any) => {
          setEmailError({ msg: error.response.data.description, disableInput: false });
          setShowGroupVerification(false);
        },
      },
    );

  const handleContinue = async () => {
    if (!validateEmail(emailAddress)) {
      setEmailError({ msg: 'Enter valid email address', disableInput: true });
      return;
    } else {
      setEmailError(null);
    }

    setInProgress(true);

    getPartialMember(
      { email_address: emailAddress },
      // onSuccess:
      (partialMember) => {
        // Yes - there already exists a partial member.  IE, they came in from an eligibility file.
        // then redirect to Verification page
        sessionStorage.setItem('partial_member_id', JSON.stringify(partialMember.user_id));
        navigate.push(`${ROUTE_PATH.VERIFICATION}`, {
          mode: 'welcome',
          email_address: emailAddress,
        });
      },
      (err) => {
        const clientCode = sessionStorage.getItem('clientCode');
        if (clientCode) {
          signup(clientCode);
        } else {
          switch (err.response?.status) {
            case 400:
              // @ts-ignore
              let error_message = err.response?.data.description;
              error_message = error_message.charAt(0).toUpperCase() + error_message.slice(1); // capitalize the error message.
              setEmailError({ msg: error_message, disableInput: false });
              break;
            case 404:
              setShowGroupVerification(true);
              break;
            default:
              setEmailError({
                msg: 'Something went wrong; please try again later.',
                disableInput: true,
              });
              rollbar.error(`Error looking for a partial member`, new Date(), {
                message: `Environment: ${process.env.REACT_APP_ENVTYPE}; Error: ${err}`,
              });
              break;
          }
        }
      },
      () => {
        setInProgress(false);
      },
    );
  };

  const handleGroupVerification = async () => {
    try {
      setInProgress(true);

      await isGroupSelfEnroll(
        groupIdValue,
        (isSelfEnroll) => {
          if (isSelfEnroll) {
            signup(groupIdValue);
          } else {
            setGroupVerificationError('Provided package code is invalid.');
          }
        },
        (err) => {
          setGroupVerificationError(err.response?.data.description);
        },
      );
    } finally {
      setInProgress(false);
    }
  };

  return (
    <>
      <MobileHeader className="md:hidden" />
      <div className="grid w-screen grid-cols-1 md:grid-cols-2 md:h-screen">
        <WelcomeSlogan className="hidden md:block" />
        <div className="pt-8 px-5 pb-32 md:bg-whiteSmoke md:flex md:items-center md:justify-center">
          <div className="w-full max-w-[480px] mx-auto">
            {!showGroupVerification ? (
              <SignUpForm
                {...{
                  emailAddress,
                  setEmailAddress: handleSetEmail,
                  emailError,
                  inProgress,
                  handleContinue,
                }}
              />
            ) : (
              <GroupValidation
                error={groupVerificationError}
                setError={setGroupVerificationError}
                inProgress={inProgress}
                onChange={setGroupIdValue}
                onSubmit={handleGroupVerification}
                setShowGroupVerification={setShowGroupVerification}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

type SignUpFormProps = {
  emailAddress: string;
  setEmailAddress: (email: string) => void;
  emailError: { msg: string; disableInput: boolean } | null;
  inProgress: boolean;
  handleContinue: () => void;
};

const SignUpForm: React.FC<SignUpFormProps> = ({
  emailAddress,
  setEmailAddress,
  emailError,
  inProgress,
  handleContinue,
}: SignUpFormProps) => {
  return (
    <>
      <div className="font-manrope text-gray-900 space-y-1 md:space-y-2">
        <p className="text-2xl font-bold leading-8">Create or activate your account</p>
        <p className="text-gray-900 text-base leading-6">
          If registered through an organization, use your email associated with your organization
        </p>
      </div>
      <div className="py-8 flex flex-col gap-4">
        <Input
          required
          id="email_address"
          label="EMAIL ADDRESS"
          onIconClick={() => {}}
          value={emailAddress}
          autoComplete="email"
          placeholder="Enter your email"
          startIcon={<EmailIcon />}
          error={!!emailError?.msg}
          helperText={emailError?.msg}
          onChange={(e) => {
            setEmailAddress(e.target.value);
          }}
        />
      </div>
      <div className="space-y-4">
        <Button
          data-id="continue-button"
          className="w-full"
          variant="primary"
          onClick={handleContinue}
          disabled={emailError?.disableInput || inProgress}
          loading={inProgress}
        >
          Continue
        </Button>
        <AlreadyHaveAnAccount />
      </div>
    </>
  );
};
type GroupValidationProps = {
  error: string | undefined;
  setError: (error: string | undefined) => void;
  inProgress: boolean;
  onChange: (groupId: string) => void;
  onSubmit: () => void;
  setShowGroupVerification: (showGroupVerification: boolean) => void;
};
const GroupValidation: React.FC<GroupValidationProps> = ({
  error,
  setError,
  inProgress,
  onChange,
  onSubmit,
  setShowGroupVerification,
}: GroupValidationProps) => {
  return (
    <>
      <div className="font-manrope text-gray-900 space-y-1 md:space-y-2">
        <div
          className="flex items-center gap-1 mb-6 text-primary text-sm font-bold leading-tight hover:cursor-pointer"
          onClick={() => {
            setShowGroupVerification(false);
            setError(undefined);
          }}
        >
          <CircledArrowRightIcon className="rotate-180" />
          Back
        </div>
        <p className="text-2xl font-bold leading-8">Your Organization</p>
        <div className="py-3 space-y-3">
          <p>If you belong to an organization, confirm your Group ID below.</p>
          <p>If you do not know your Group ID and no ID is shown below, contact your plan administrator.</p>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <Input
          required
          id="group_id"
          label="GROUP ID"
          className="py-3"
          type="text"
          error={!!error}
          helperText={error}
          placeholder="Enter Group ID"
          onIconClick={() => {}}
          onChange={(elem) => onChange(elem.target.value)}
          startIcon={<KeyIcon />}
        />
      </div>
      {error && (
        <div className="py-3 space-y-3">
          <p className="text-gray-900 text-base leading-6">
            If you’re registered through an organization, be sure to use your email associated with that organization.
          </p>
          <p className="text-gray-900 text-base leading-6">
            You can also check with your organization’s benefit manager to get your Group ID.
          </p>
        </div>
      )}
      <div className="py-3">
        <Button
          className="w-full"
          data-testid="verify-group-button"
          variant="primary"
          children="Verify Group ID"
          onClick={onSubmit}
          loading={inProgress}
          disabled={inProgress}
        />
        <AlreadyHaveAnAccount />
      </div>
    </>
  );
};

const AlreadyHaveAnAccount: React.FC = () => {
  return (
    <div className="flex justify-center items-center gap-2 mt-4">
      <div className="text-gray-800 text-sm font-normal leading-tight">Already have an account?</div>
      <Link className="flex items-center gap-1 text-primary text-sm font-bold leading-tight" to={ROUTE_PATH.LOGIN}>
        Log in
        <CircledArrowRightIcon />
      </Link>
    </div>
  );
};

function KeyIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g>
        <path
          fill="#222833"
          fillRule="evenodd"
          d="M20.53 2.47a.75.75 0 010 1.06l-.47.47 2.47 2.47a.75.75 0 010 1.06l-3.5 3.5a.75.75 0 01-1.06 0L15.5 8.56 11.562 12.5a5.75 5.75 0 11-1.06-1.06l4.468-4.47 3.5-3.5 1-1a.75.75 0 011.06 0zM16.56 7.5l1.94 1.94L20.94 7 19 5.06 16.56 7.5zM7 11.75a4.25 4.25 0 100 8.5 4.25 4.25 0 000-8.5z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default SignupPage;
