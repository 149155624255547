import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, useLocation } from 'react-router';
import { ROUTE_PATH } from './route-paths';
import useAuth from '../hooks/useAuth';

type props = {
  exact: boolean;
  path: string;
  component: any;
};

const PrivateRoute: React.FunctionComponent<props> = ({ exact, path, component: Component }): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState<Boolean>(true);
  const location = useLocation();
  const { authToken } = useAuth();

  const checkAuthentication = useCallback(async () => {
    try {
      const token = authToken;
      const clientCode = localStorage.getItem('clientCode');

      if (path === ROUTE_PATH.HELP && !token) {
        setIsAuthenticated(false);
      } else if (token || clientCode) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [path, authToken]);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  /* If the user is not authenticated, we will redirect them to the login page with the path (pathname) and query params (search) as a ?next= query param.
  Then, in the login page, we look for that query param and pass it to the authenticate function upon log in.
  If next is not null, it will decode that URI and redirect the user to that page on success,
  else it is null, we will direct them home per usual. */
  return isAuthenticated ? (
    <Route exact={exact} path={path} component={Component} />
  ) : (
    <Redirect to={{ pathname: ROUTE_PATH.LOGIN, search: `?next=${location.pathname + location.search}` }} />
  );
};

export default PrivateRoute;
