import React, { createContext, useEffect, useState, useRef } from 'react';

import Keycloak from 'keycloak-js';
import { useHistory, useLocation } from 'react-router';
import { Features, useFeatureFlag } from '../hooks/useFeatureFlag';
import { ROUTE_PATH } from '../routes/route-paths';
import useStickyState from '../hooks/useStickyState';
import { analytics, EventTypes } from '@kindlyhuman/component-library';
import { useQueryClient } from '@tanstack/react-query';
import { axiosPost } from '../api/axios-handler';

interface AuthContextProps {
  keycloak: Keycloak | null;
  authToken: string | null;
  setAuthToken: React.Dispatch<string | null>;
  setRefreshToken: React.Dispatch<string | null>;
  onUserAuthenticated: (props: OnUserAuthenticatedProps) => void;
  logout: () => void;
}

interface OnUserAuthenticatedProps {
  isListenerRole: boolean;
  isCallerRole: boolean;
  authToken: string;
  next: string | null;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_IDP_URL!,
  realm: process.env.REACT_APP_KEYCLOAK_IDP_REALM!,
  clientId: process.env.REACT_APP_KEYCLOAK_IDP_CLIENT_ID!,
  baseUrl: process.env.REACT_APP_BASE_URL!,
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const isFirstRun = useRef<boolean>(false);
  const [keycloak, setKeycloak] = useState<Keycloak | null>(null);
  const keycloakFeatureFlag = useFeatureFlag(Features.MWA_KEYCLOAK_LOGIN);
  const history = useHistory();
  const [authToken, setAuthToken] = useStickyState('authorization_token', null);
  const [refreshToken, setRefreshToken] = useStickyState('refresh_token', null);
  const queryClient = useQueryClient();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const onUserAuthenticated = ({ isListenerRole, isCallerRole, authToken, next }: OnUserAuthenticatedProps) => {
    if (isListenerRole && !isCallerRole) {
      history.push(ROUTE_PATH.PWA_REDIRECT_PAGE);
      return;
    } else if (!isCallerRole) {
      // not sure what to do here...redirect to a "you don't have a caller role contact someone" page?
    }

    authToken && setAuthToken(authToken);

    // If a next query param was provided to the login page in privateRoute.tsx, redirect to that page,
    // otherwise redirect to the home page.
    if (next) {
      history.push(decodeURIComponent(next));
    } else {
      history.push(ROUTE_PATH.HOME);
    }
  };

  const logout = () => {
    if (keycloakFeatureFlag.enabled && keycloak) {
      keycloak?.logout();
    } else {
      axiosPost(`/users/deauthenticate`, {});
    }
    localStorage.removeItem('authorization_token');
    let storedFilters = localStorage.getItem('searchForSupportFilters');
    if (storedFilters) {
      const parsedFilters = JSON.parse(storedFilters);
      delete parsedFilters.supportQuery;
      localStorage.setItem('searchForSupportFilters', JSON.stringify(parsedFilters));
    }
    setAuthToken(null);
    analytics.trackEvent(EventTypes.LOGOUT);
    queryClient.removeQueries(['me']);
    history.push(ROUTE_PATH.LOGIN);
  };

  useEffect(() => {
    if (isFirstRun.current) return;

    isFirstRun.current = true;
    const initKeycloak = async () => {
      const keycloakInstance: Keycloak = new Keycloak(keycloakConfig);

      keycloakInstance
        .init({
          onLoad: 'check-sso',
        })
        .then((authenticated: boolean) => {
          if (keycloakInstance.token) {
            const isAuthenticating = authToken === null;
            // if authToken is already set (in localstorage), but keycloak.check-sso returns false,
            // then defer to the localstorage token and set it into keycloak
            if (!authenticated && authToken) {
              keycloakInstance.token = authToken;
              keycloakInstance.refreshToken = refreshToken || undefined;
            } else {
              setAuthToken(keycloakInstance.token);
            }

            const isListenerRole = keycloakInstance.hasRealmRole('listener');
            const isCallerRole = keycloakInstance.hasRealmRole('caller');
            const next = queryParams.get('next');
            isAuthenticating &&
              onUserAuthenticated({ isListenerRole, isCallerRole, authToken: keycloakInstance.token, next });
          }
        })
        .catch((error) => {
          console.error('Keycloak initialization failed:', error);
          setAuthToken(null);
        })
        .finally(() => {
          setKeycloak(keycloakInstance);
        });
    };

    initKeycloak();
  }, []);

  return (
    <AuthContext.Provider value={{ keycloak, authToken, setAuthToken, setRefreshToken, onUserAuthenticated, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
