import React, { useEffect, createRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { PlayIcon } from '@kindlyhuman/component-library';

import { MyProgramDefaultPng } from '../../assets';

import { PlayerIcon, ExitIcon } from '../../components/common/svgs';

type props = {
  videoSrc?: string;
  title?: string;
  imageSrc?: string;
  handleCloseVideo?: () => void;
  className?: string;
};

let elem: any;

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
  }
}

// Home Welcome video
const IntroducesVideoCard: React.FunctionComponent<props> = ({ className, ...props }): JSX.Element => {
  const refVideo: any = createRef();
  const box: any = document.getElementById('video-full-screen');
  const palyVideo = () => {
    elem = document.getElementById('myvideo');
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      if (!box.hasChildNodes()) {
        box.appendChild(elem);
      }
      box.style.display = 'block';
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.webkitEnterFullscreen) {
      elem.webkitEnterFullscreen();
    }
    elem.play();
  };

  useEffect(() => {
    if (document.addEventListener) {
      document.addEventListener('fullscreenchange', exitHandler, false);
      document.addEventListener('mozfullscreenchange', exitHandler, false);
      document.addEventListener('MSFullscreenChange', exitHandler, false);
      document.addEventListener('webkitfullscreenchange', exitHandler, false);
    }

    return () => {
      document.removeEventListener('fullscreenchange', () => {});
      document.removeEventListener('mozfullscreenchange', () => {});
      document.removeEventListener('MSFullscreenChange', () => {});
      document.removeEventListener('webkitfullscreenchange', () => {});
      if (box.hasChildNodes()) {
        box.removeChild(box.children[0]);
      }
    };
  }, []); // eslint-disable-line

  const exitHandler = () => {
    if (!document['fullscreenElement']) {
      if (
        //@ts-ignore
        !document['webkitIsFullScreen'] &&
        //@ts-ignore
        !document['mozFullScreen'] &&
        !document['msFullscreenElement']
      ) {
        // Run code on exit
        box.style.display = 'none';
        if (elem) elem.pause();
      }
    }
  };

  const videoTimeUpdate = () => {
    const currentTimeUpdate = refVideo.current.currentTime;
    const duration = refVideo.current.duration;
    if (currentTimeUpdate === duration) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
      localStorage.setItem('isVideoDisplay', false.toString());
      props.handleCloseVideo && props.handleCloseVideo();
    }
  };

  return (
    <div className={twMerge('card max-w-[343px] max-h-[192px] m-auto relative', className)}>
      <div className="w-full absolute z-30 flex justify-end p-3">
        <ExitIcon
          data-testid="video-card-exit-icon"
          width={26}
          height={26}
          className="text-white cursor-pointer"
          onClick={props.handleCloseVideo}
        />
      </div>
      <div className="absolute z-10 w-full h-full rounded-md" role="button" onClick={palyVideo} id="welcome-video">
        <img
          className="h-52 w-full rounded-md"
          src={props.imageSrc ? props.imageSrc : MyProgramDefaultPng}
          alt="play"
        />
      </div>
      <div
        className={`
          w-full h-52 absolute z-20 items-center flex justify-center
          md:w-auto md:h-auto md:bottom-4 md:right-4
        `}
        role="button"
        onClick={palyVideo}
      >
        <div className="w-12 h-12 mr-5 md:hidden">
          <PlayerIcon width={50} height={50} className="text-primary" />
        </div>
        <div className="w-10 h-10 rounded-full bg-gray-50 border-gray-100 hidden md:flex items-center justify-center">
          <PlayIcon />
        </div>
      </div>

      <div className="h-52 bg-blue relative rounded-md">
        <video
          className="absolute h-52 w-full rounded-md"
          id="myvideo"
          onClick={palyVideo}
          role="button"
          playsInline
          ref={refVideo}
          onTimeUpdate={videoTimeUpdate}
        >
          <source src={props.videoSrc} />
        </video>
      </div>
    </div>
  );
};
export default IntroducesVideoCard;
