import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query';
import { Skeleton } from '@mui/material';

import { ChevronDownIcon, Experience as ExperienceComponent, SpinnerIcon } from '@kindlyhuman/component-library';

import { MobileMenuHeader, SubHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { FilterMenuModal, TagFilters } from '../../components/filter/FomSelectionFilter';

import { ROUTE_PATH } from '../../routes/route-paths';

import { useTagGroups } from '../../hooks/useTagGroups';
import { useUser } from '../../hooks/useUser';
import {
  RecommendedExperienceResponse,
  useFavoriteExperience,
  useInfiniteExperiences,
  useRecommendedExperiencesTextSearch,
  useUnfavoriteExperience,
} from '../../hooks/useExperiences';
import { useListenerAudioPlaybackMutation, useListenerAudioPlayMutation } from '../../hooks/useListenerAudio';
import { useResources } from '../../hooks/useResources';

import { ResourceSection } from '../peer-details/peer-details';
import { twMerge } from 'tailwind-merge';
import { SearchQueryModal } from '../../components/search/search-query-modal';
import { useMediaQuery } from '../../hooks/useMediaQuery';

export const RecommendedExperiencesPage = () => {
  const navigate = useHistory();
  const dfMdMedia = useMediaQuery('md');
  const { data: user, updateTagPreferences } = useUser();
  const { data: fomHierarchy } = useTagGroups();
  const { data: resources } = useResources();

  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [textSearchModal, setTextSearchModalStatus] = useState(false);

  // We defer first to a URL search term, then to the user's stored search, then otherwise undefined
  const { search: urlQuery } = useLocation();
  const deepLinkSearch = new URLSearchParams(urlQuery).get('search');
  const searchQueries = user?.search_queries?.length ? user?.search_queries : null;
  const [query, setQuery] = useState(deepLinkSearch || searchQueries?.[0]?.query || '');

  const recommendedExperiencesQuery = useInfiniteExperiences(undefined);

  const textSearchQuery = useRecommendedExperiencesTextSearch({
    enabled: query.trim() !== '',
    searchFilterOptions: {
      supportQuery: query,
    },
  });

  const handleFilterChange = ({ tag_group_ids, tag_ids }: TagFilters) => {
    updateTagPreferences.mutate({
      tag_group_ids,
      tag_ids,
    });
    setShowFilterMenu(false);
    setQuery('');
  };

  useEffect(() => {
    if (textSearchQuery?.isFetched) {
      setTextSearchModalStatus(false);
    }
  }, [textSearchQuery?.isFetched, setTextSearchModalStatus]);

  return (
    <div className="pb-10 flex flex-col bg-neutral-700 bg-opacity-5 min-h-screen">
      <DesktopMenuHeader />
      <MobileMenuHeader prompt onBack={() => navigate.push(ROUTE_PATH.HOME)} />
      <div className="w-full md:max-w-7xl md:mx-auto md:px-6">
        <SubHeader
          className="p-4 pb-10 md:px-0 md:pt-6 md:pb-8"
          title="Experiences for you"
          subtitle="Recommended experiences from your Peers"
          onBackButtonClick={dfMdMedia ? () => navigate.goBack() : undefined}
        />
        <Body textSearchQuery={textSearchQuery} recommendedExperiencesQuery={recommendedExperiencesQuery} />
        {resources && <ResourceSection resources={resources} />}
      </div>
      <div className="pt-20" />
      {showFilterMenu && (
        <FilterMenuModal
          open={showFilterMenu}
          selected={{
            tag_ids: user?.caller_role.tag_ids ?? [],
            tag_group_ids: user?.caller_role.tag_group_ids ?? [],
          }}
          onExit={() => setShowFilterMenu(false)}
          onChange={handleFilterChange}
          fomOptions={fomHierarchy}
        />
      )}
      {textSearchModal && (
        <SearchQueryModal
          onClose={() => {
            setQuery(searchQueries ? searchQueries[0].query : '');
            setTextSearchModalStatus(false);
          }}
          search={async (text) => {
            setQuery(text);
            await new Promise<void>((resolve) => setTimeout(() => resolve(), 1500));
          }}
          isLoading={textSearchQuery?.isFetching ?? false}
        />
      )}
    </div>
  );
};

const Body: React.FC<{
  recommendedExperiencesQuery?: UseInfiniteQueryResult<RecommendedExperienceResponse, unknown>;
  textSearchQuery?: UseQueryResult<RecommendedExperienceResponse, unknown>;
}> = ({ recommendedExperiencesQuery, textSearchQuery }) => {
  const experiences = recommendedExperiencesQuery?.data?.pages.map((page) => page.data).flat();

  const [playerOpen, setPlayerOpen] = useState<number>();
  const [playing, setPlaying] = useState(false);
  const [currentPlaybackId, setCurrentPlaybackId] = useState<number>();

  const playBackMutation = useListenerAudioPlaybackMutation();
  const playMutation = useListenerAudioPlayMutation();
  const favoriteMutation = useFavoriteExperience();
  const unfavoriteMutation = useUnfavoriteExperience();
  const { getParentByParentId } = useTagGroups();

  const dfMdMedia = useMediaQuery('md');

  const audioStopAll = () => {
    document.querySelectorAll('audio').forEach((el) => {
      el.pause();
      el.currentTime = 0;
    });
  };

  return (
    <div className="flex flex-col gap-y-3">
      <div className="w-full grid gap-3 md:grid-cols-2 lg:grid-cols-3 md:gap-6">
        {experiences
          ? experiences?.map((experience) => (
              <div className="flex flex-col">
                <ExperienceComponent
                  experienceId={experience.id}
                  variant={dfMdMedia ? 'desktopWide' : 'wide'}
                  hideFollow
                  className="bg-white"
                  imageUrl={experience.profile_photo_square_file_url}
                  displayName={experience.name}
                  audioUrl={experience.audio_url}
                  caption={experience.excerpt}
                  // @ts-ignore
                  areas={experience.area_ids.map((x) => getParentByParentId(x).name)}
                  favorited={experience.is_favorite}
                  duration={experience.duration}
                  onFollow={async () => {
                    if (experience.is_favorite) {
                      await unfavoriteMutation.mutateAsync({
                        tagId: 0,
                        listenerId: experience.listener_role_id,
                        audioId: experience.id,
                      });
                    } else {
                      await favoriteMutation.mutateAsync({
                        tagId: 0,
                        listenerId: experience.listener_role_id,
                        audioId: experience.id,
                      });
                    }

                    experience.is_favorite = !experience.is_favorite;
                  }}
                  playing={playerOpen === experience.id && playing}
                  setPlaying={setPlaying}
                  onPlay={() => {
                    audioStopAll();
                    setPlayerOpen(experience.id);
                    playMutation.mutate(
                      {
                        audioId: experience.id,
                        listenerId: experience.listener_role_id,
                      },
                      {
                        onSuccess: (listenerAudioPlayback) => {
                          setCurrentPlaybackId(listenerAudioPlayback.id);
                        },
                      },
                    );
                  }}
                  key={experience.id}
                  openPlayer={playerOpen === experience.id}
                  setOpenPlayer={setPlayerOpen}
                  onTimeUpdate={(e: React.SyntheticEvent<HTMLAudioElement>) => {
                    const percent = Math.round((e.currentTarget.currentTime / experience.duration) * 100);
                    // if the percent is divisible by 10
                    if (percent % 10 === 0) {
                      currentPlaybackId &&
                        playBackMutation.mutate({
                          audioId: currentPlaybackId,
                          percentCompleted: percent,
                        });
                    }
                  }}
                  available={experience.is_available}
                  isPeerListener={experience.is_listener}
                />
              </div>
            ))
          : [...Array(6).keys()].map((index) => <ExperienceSkeletonWide key={index} className="w-full" />)}
      </div>
      <div className="self-center py-8">
        <TextButton recommendedExperiencesQuery={recommendedExperiencesQuery} />
      </div>
    </div>
  );
};

const TextButton: React.FC<{
  recommendedExperiencesQuery?: UseInfiniteQueryResult<RecommendedExperienceResponse, unknown>;
}> = ({ recommendedExperiencesQuery }) =>
  recommendedExperiencesQuery?.hasNextPage ? (
    !recommendedExperiencesQuery?.isFetchingNextPage ? (
      <button
        onClick={() => recommendedExperiencesQuery?.fetchNextPage()}
        className="h-12 py-6 px-6 justify-start items-center gap-1 flex"
      >
        <div className="text-center text-violet-950 text-base font-bold leading-normal">See more</div>
        <ChevronDownIcon width={20} color="#24006B" />
      </button>
    ) : (
      <div className="w-full h-12 flex items-center justify-center">
        <SpinnerIcon />
      </div>
    )
  ) : (
    <div className="pt-12" />
  );

export interface ExperienceSkeletonWideProps {
  className?: string;
}

export const ExperienceSkeletonWide: React.FC<ExperienceSkeletonWideProps> = ({ className }) => (
  <div className={twMerge('flex flex-col px-4 py-4 bg-white', className)}>
    <div className="flex items-center">
      <Skeleton variant="circular" className="mr-6 h-10 w-10" />
      <Skeleton variant="text" className="w-12 h-5" />
    </div>
    <Skeleton className="h-12 w-full" />
    <Skeleton className="h-12 w-32" />
    <Skeleton className="h-12 w-full" />
  </div>
);
