import { Content } from '@kindlyhuman/component-library';
import { Skeleton } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
  TelemedDetailPng,
  CounselingDetailPng,
  PLUSCare,
  PLUSCounseling,
  PLUSVirtualPrimaryCare,
  PLUSVirtualUrgentCare,
} from '../../assets';
import { ExternalProviderPlan } from '../../hooks/useExternalProviderPlans';
import { ResourcesResponse, Resource } from '../../hooks/useResources';
import { useTagGroups } from '../../hooks/useTagGroups';
import { ROUTE_PATH } from '../../routes/route-paths';
import { BlockHorizontalScroll } from '../common/block_horizontal_scroll';
import { useMediaQuery } from '../../hooks/useMediaQuery';

interface ResourcesSectionProps {
  query?: string;
  resourcesQuery?: UseQueryResult<ResourcesResponse | null, unknown>;
  providerPlan?: ExternalProviderPlan | null;
  providerPlanLoading?: boolean;
  providerPlanFetching?: boolean;
  groupLevelResources?: Resource[] | null;
  availableResources?: Resource[] | null;
  textSearchFlag?: boolean;
  title: string;
}

export enum ResourceTypes {
  GLOBAL = 'global',
  GROUP = 'group',
}

export const ResourcesSection: React.FC<ResourcesSectionProps> = ({
  query,
  resourcesQuery,
  providerPlan,
  providerPlanLoading,
  providerPlanFetching,
  title,
  groupLevelResources,
  availableResources,
  textSearchFlag,
}) => {
  const history = useHistory();
  const { getParentByParentId } = useTagGroups();

  const resourceType = groupLevelResources ? ResourceTypes.GROUP : availableResources ? ResourceTypes.GLOBAL : null;

  const dfMdMedia = useMediaQuery('md');

  let resourceCount = 0;
  let loading = false;
  let fetching = false;
  let displayedResources = null;

  if (!providerPlan && resourcesQuery) {
    const { data: resources, isLoading, isFetching } = resourcesQuery;

    // Display only 3 resources of the appropriate type
    displayedResources = groupLevelResources ? groupLevelResources : availableResources;

    if (!isLoading && (!displayedResources || (displayedResources.length === 0 && !providerPlan))) {
      return null;
    }

    resourceCount = resources?.count || 0;
    loading = isLoading;
    fetching = isFetching;
  }

  if (providerPlan && providerPlanLoading && providerPlanFetching) {
    resourceCount = providerPlan.provider_types.length;
    loading = providerPlanLoading;
    fetching = providerPlanFetching;
  }

  let subtitle = '';

  if (textSearchFlag) {
    subtitle = groupLevelResources ? 'From your organization' : '';
  } else if (providerPlan) {
    subtitle = 'Included with your plan';
  } else {
    title = 'More help options';
    if (resourceCount) {
      const resourceText = resourceCount > 1 ? 'resources' : 'resource';
      subtitle = resourceCount ? `${resourceCount} ${resourceText} to explore` : '';
    }
  }

  return (
    <>
      <BlockHorizontalScroll
        className="md:border md:rounded-lg"
        isLoading={fetching}
        title={title}
        subtitle={subtitle}
        onViewAll={() => {
          history.push(
            { pathname: ROUTE_PATH.RESOURCES },
            {
              query: query,
              from: resourceType,
            },
          );
        }}
        showViewAll={true}
        showNavigationButton={
          dfMdMedia &&
          ((displayedResources && displayedResources?.length > 2) ||
            (providerPlan && providerPlan?.provider_types.length > 2))
        }
        contentClassName="md:gap-4"
      >
        {loading &&
          [1, 2].map((index) => <Skeleton sx={{ minWidth: '250px' }} variant="rounded" key={index} height={'220px'} />)}
        {!loading && providerPlan?.provider_types.includes('primary') && (
          <Link to={ROUTE_PATH.TELEMED}>
            <Content
              caption={
                providerPlan.provider === 'recuro'
                  ? 'Find out more about +Virtual Primary Care today'
                  : 'Find out more about +Telemed today.'
              }
              displayName={providerPlan.provider === 'recuro' ? '+Virtual Primary Care' : '+Telemed'}
              imageUrl={providerPlan.provider === 'recuro' ? PLUSVirtualPrimaryCare : TelemedDetailPng}
              variant={dfMdMedia ? 'desktopMedium' : 'medium'}
            />
          </Link>
        )}
        {!loading && providerPlan?.provider_types.includes('counseling') && (
          <Link to={ROUTE_PATH.COUNSELING}>
            <Content
              caption="Find out more about +Counseling now"
              displayName="+Counseling"
              imageUrl={providerPlan.provider === 'recuro' ? PLUSCounseling : CounselingDetailPng}
              variant={dfMdMedia ? 'desktopMedium' : 'medium'}
            />
          </Link>
        )}
        {!loading && providerPlan?.provider_types.includes('crisis') && (
          <Link to={ROUTE_PATH.CRISIS_LITE}>
            <Content
              caption="Find out more about +Care now"
              displayName="+Care"
              imageUrl={PLUSCare}
              variant={dfMdMedia ? 'desktopMedium' : 'medium'}
            />
          </Link>
        )}
        {!loading && providerPlan?.provider_types.includes('urgent') && (
          <Link to={ROUTE_PATH.URGENT}>
            <Content
              caption="Find out more about Urgent Care now"
              displayName="+Virtual Urgent Care"
              imageUrl={PLUSVirtualUrgentCare}
              variant={dfMdMedia ? 'desktopMedium' : 'medium'}
            />
          </Link>
        )}
        {displayedResources &&
          displayedResources.map((resource) => (
            <Link key={resource.id} to={`${ROUTE_PATH.RESOURCE}/${resource.id}_${resource.name.replaceAll(' ', '-')}`}>
              <Content
                caption={resource.description.substring(0, 50) + '...'}
                displayName={resource.name}
                imageUrl={resource.image_url}
                variant={dfMdMedia ? 'desktopMedium' : 'medium'}
                // @ts-ignore
                areas={resource.challenge_areas?.map((id) => getParentByParentId(id)?.name)}
              />
            </Link>
          ))}
      </BlockHorizontalScroll>
    </>
  );
};
