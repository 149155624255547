import { useMutation } from '@tanstack/react-query';
import { axiosPost, axiosPut } from '../api/axios-handler';

export interface ListenerAudioPlayback {
  audio_id: number;
  caller_role_id: number;
  created_at: string;
  id: number;
  package_id: number;
  percent_completed: number;
  tag_ids: number[];
}

export const useListenerAudioPlayMutation = () =>
  useMutation(
    ({ audioId, listenerId, tagIds: tag_ids }: { audioId: number; listenerId: number; tagIds?: number[] }) => {
      return axiosPost(
        `/listeners/${listenerId}/audio/${audioId}/play`,
        tag_ids
          ? {
              tag_ids,
            }
          : null,
      ).then((response) => response.data as ListenerAudioPlayback);
    },
  );

export const useListenerAudioPlaybackMutation = () =>
  useMutation(({ audioId, percentCompleted: percent_completed }: { audioId: number; percentCompleted: number }) => {
    return axiosPut(`/listener_audio_playback/${audioId}`, {
      percent_completed,
    });
  });
