import { ReactNode, useEffect, useLayoutEffect, useRef } from 'react';
import { Skeleton } from '@mui/material';
import { twMerge } from 'tailwind-merge';

import { CircledArrowRightIcon } from '@kindlyhuman/component-library';

import { HorizontalScrollSection, HorizontalScrollSectionRef } from './horizontal-scroll';
import { RecommendedExperienceResponse } from '../../hooks/useExperiences';
import { UseQueryResult } from '@tanstack/react-query';

export const BlockHorizontalScroll: React.FC<{
  dataTestId?: string;
  isLoading?: boolean;
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  center?: boolean;
  className?: string;
  contentClassName?: string;
  onViewAll?: () => void;
  showNavigationButton?: boolean | null;
  showViewAll?: boolean;
  recommendedExperiences?: UseQueryResult<RecommendedExperienceResponse | null, unknown>;
}> = ({
  dataTestId,
  title,
  subtitle,
  children,
  className,
  contentClassName,
  onViewAll,
  isLoading = false,
  center = false,
  showNavigationButton,
  showViewAll = true,
  recommendedExperiences,
}) => {
  const scrollSectionRef = useRef<HorizontalScrollSectionRef>(null);

  useLayoutEffect(() => {
    scrollSectionRef.current?.resetScrollPosition();
  }, [recommendedExperiences]);

  return (
    <div data-testid={dataTestId} className={twMerge('bg-white border-t border-b', className)}>
      {title && (
        <div className="flex items-start justify-between px-4 py-3 md:px-5 md:pt-5">
          <div>
            <p className="font-manrope font-bold text-xl md:text-2xl text-gray-800 flex-grow">{title}</p>
            {isLoading && <Skeleton variant="text" className="w-40 h-5 md:h-6 md:mt-1" />}
            {subtitle && !isLoading && (
              <div className="font-manrope text-stone-500 text-sm md:text-base md:mt-1 font-medium">{subtitle}</div>
            )}
          </div>
          {showViewAll && (
            <TextButton
              className="md:mt-3"
              onClick={onViewAll}
              title={
                <>
                  <span className="hidden md:inline-block text-nowrap">See all</span>
                  <span className="md:hidden">View all</span>
                </>
              }
            />
          )}
        </div>
      )}

      <HorizontalScrollSection
        ref={scrollSectionRef}
        center={center}
        className={twMerge('md:px-5 md:pb-5', contentClassName)}
        showNavigationButton={showNavigationButton}
        isLoading={isLoading}
      >
        {children}
      </HorizontalScrollSection>
    </div>
  );
};

const TextButton: React.FC<{
  title: ReactNode;
  onClick?: () => void;
  className?: string;
}> = ({ title, onClick, className }) => (
  <button
    onClick={onClick && onClick}
    className={twMerge(
      'flex font-manrope text-violet-950 text-base font-semibold leading-normal items-center cursor-pointer',
      className,
    )}
  >
    {title} &nbsp;
    <CircledArrowRightIcon />
  </button>
);
