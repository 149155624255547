import { useMemo } from 'react';

export const usePhoneFormat = (phoneNumber: string) =>
  useMemo(() => {
    const shortNumber = phoneNumber?.substring(1);

    const part1 = shortNumber?.substring(0, 1);
    const part2 = shortNumber?.substring(1, 4);
    const part3 = shortNumber?.substring(4, 7);
    const part4 = shortNumber?.substring(7);

    return `${part1}-${part2}-${part3}-${part4}`;
  }, [phoneNumber]);
