import { HTMLAttributes, useState } from 'react';

import { AreaLabel, Avatar, Button, CheckmarkIcon, PhoneIcon, TraitList } from '@kindlyhuman/component-library';

import { PeerDetail } from '../../hooks/usePeers';
import { useMediaQuery } from '../../hooks/useMediaQuery';

interface PeerDetailCardProps extends HTMLAttributes<HTMLDivElement> {
  peerDetails?: PeerDetail;
  canTakeCalls: boolean;
  handleFollowing: () => void;
  isFollowing: boolean;
  onScheduleCallButtonClick?: () => void;
  onConnectNowButtonClick?: () => void;
}

const PeerDetailCard: React.FunctionComponent<PeerDetailCardProps> = ({
  peerDetails,
  canTakeCalls,
  handleFollowing,
  isFollowing,
  onScheduleCallButtonClick,
  onConnectNowButtonClick,
}): JSX.Element => {
  const dfMdMedia = useMediaQuery('md');

  const [inProgress, setInProgress] = useState<boolean>(false);

  const traitList: string[] = peerDetails
    ? [...(peerDetails.profile_traits || []), ...(peerDetails.background_traits || [])]
    : [''];

  return (
    <>
      <div
        className="
          w-full flex flex-col items-center bg-white p-5 mb-2
          md:bg-transparent md:flex-row md:p-0 md:justify-between md:gap-5 md:mb-10
        "
      >
        <div className="flex flex-col items-center md:flex-row">
          <Avatar
            variant={dfMdMedia ? 'huge' : 'x-large'}
            image={peerDetails?.profile_photo_square_file_url || ''}
            available={peerDetails?.available_now}
            isPeerListener={canTakeCalls}
          />
          <div
            className="
              flex flex-col items-center mt-2 gap-4
              md:items-start md:ml-6 md:self-end md:gap-2
            "
          >
            <h3 className="text-xl font-bold text-primary md:text-3xl md:text-dark">
              {peerDetails?.user?.display_name ?? peerDetails?.user?.first_name}
            </h3>
            <div className="flex flex-wrap max-w-70vw justify-evenly gap-3">
              {peerDetails?.challenge_areas?.map((challengeArea) => (
                <AreaLabel
                  key={challengeArea.key}
                  className="md:text-base"
                  // @ts-ignore
                  variant={challengeArea.name}
                />
              ))}
            </div>
            <TraitList className="md:text-base" align={dfMdMedia ? 'left' : 'center'} traits={traitList} />
          </div>
        </div>
        <div
          className="
            self-stretch min-w-50 shrink-0 flex flex-col justify-between items-center mt-8 mb-4
            md:items-end md:my-0
          "
        >
          <Button variant={isFollowing ? 'secondary' : 'primary'} onClick={clickOnFollowingButton} loading={inProgress}>
            {isFollowing ? (
              <>
                <CheckmarkIcon className="mr-2" /> Following
              </>
            ) : (
              'Follow'
            )}
          </Button>
          {canTakeCalls && peerDetails?.user.display_name && (
            <div className="hidden md:flex items-center gap-3">
              <Button onClick={onScheduleCallButtonClick} variant="secondary">
                Schedule a Call
              </Button>
              {!!peerDetails?.available_now && (
                <button
                  data-testid="connect-now-button"
                  className="py-3 px-4 bg-white rounded-3xl border border-green-500 text-[#240089] font-semibold justify-center items-center font-manrope gap-2 flex"
                  onClick={onConnectNowButtonClick}
                >
                  <PhoneIcon height={24} width={24} color="#22c55e" />
                  <span className="text-center text-violet-950 text-base font-bold leading-normal">Connect now</span>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      {canTakeCalls && peerDetails?.user.display_name && (
        <div className="px-4 py-5 bg-white border-t border-b border-neutral-200 flex-col justify-start items-start gap-6 flex md:hidden">
          <div className=" text-gray-800 text-xl font-bold leading-[30px]">
            Connect with {peerDetails.user.display_name}
          </div>
          <div className="flex w-full justify-evenly flex-wrap gap-y-5 gap-x-2 items-center">
            <Button onClick={onScheduleCallButtonClick} variant="secondary">
              Schedule a Call
            </Button>
            {!!peerDetails?.available_now && (
              <button
                data-testid="connect-now-button"
                className="py-3 px-4 bg-white rounded-3xl border border-green-500 text-[#240089] font-semibold justify-center items-center font-manrope gap-2 flex"
                onClick={onConnectNowButtonClick}
              >
                <PhoneIcon height={24} width={24} color="#22c55e" />
                <span className="text-center text-violet-950 text-base font-bold leading-normal">Connect now</span>
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );

  async function clickOnFollowingButton() {
    try {
      setInProgress(true);

      await handleFollowing();
    } finally {
      setInProgress(false);
    }
  }
};

export default PeerDetailCard;
