import React from 'react';
import { useExternalProviderPlan, useTelemedRedirect } from '../../hooks/useExternalProviderPlans';
import { useUser } from '../../hooks/useUser';
import { TelemedPageMobile } from './telemed-detail-mobile';
import { Spinner } from '../../components/common';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import getPartnerResourceConfig from './partner-resource-config';

// User topic selection
const Telemed: React.FunctionComponent = (): JSX.Element => {
  const sm = useMediaQuery('sm');
  const md = useMediaQuery('md');

  const { data: user, isLoading: userLoading } = useUser();
  const { data: providerPlans, isLoading: externalPlanLoading } = useExternalProviderPlan();
  const providerPlan =
    providerPlans?.find((plan) => {
      return plan.id === user?.caller_role.active_subscription?.package.external_plan_id;
    }) ?? null;

  const { data: ssoLinkUrl, isLoading: ssoLinkUrlPending } = useTelemedRedirect(user?.id || 0);

  if (externalPlanLoading || userLoading) return <Spinner />;

  const partnerResourceConfig = getPartnerResourceConfig(user, providerPlan);

  return sm || md ? (
    <TelemedPageMobile
      providerPlan={providerPlan}
      partnerResourceConfig={partnerResourceConfig}
      ssoLinkUrl={providerPlan?.provider === 'recuro' ? 'https://member.recurohealth.com' : ssoLinkUrl?.redirect_url}
      ssoLinkUrlPending={providerPlan?.provider === 'recuro' ? false : ssoLinkUrlPending}
    />
  ) : (
    <TelemedPageMobile
      providerPlan={providerPlan}
      partnerResourceConfig={partnerResourceConfig}
      ssoLinkUrl={providerPlan?.provider === 'recuro' ? 'https://member.recurohealth.com' : ssoLinkUrl?.redirect_url}
      ssoLinkUrlPending={providerPlan?.provider === 'recuro' ? false : ssoLinkUrlPending}
    />
  );
};

export default Telemed;
