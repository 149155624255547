import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@kindlyhuman/component-library';

import { MobileMenuHeader, DesktopMenuHeader, SubHeader } from '../../components/MenuHeader';
import { FormBlock } from '../../components/common/form_block';

import { DialCare, CounselingDetailPng, Walmart, RecuroLogo, PLUSCounseling } from '../../assets';

import { ROUTE_PATH } from '../../routes/route-paths';

import { TeleHealthResourceProps } from './partner-resource-config';
import { useMediaQuery } from '../../hooks/useMediaQuery';

const getProviderData = (provider?: string) => {
  if (provider === 'dialcare') {
    return {
      name: '+Counseling',
      description: 'Your benefit includes the opportunity to speak with a licensed counselor.',
      longDescription: `
      Through our partnership with ${provider}, you have access to licensed clinical
      counselors to discuss more serious issues.
    `,
      providerText: 'THIS PLAN IS NOT INSURANCE and is not intended to replace health insurance.',
      providerImage: <img className="min-w-full" src={DialCare} alt="DialCare" />,
      bannerImage: CounselingDetailPng,
    };
  } else if (provider === 'me_md') {
    return {
      name: '+Counseling',
      description: 'Your benefit includes the opportunity to speak with a licensed counselor.',
      longDescription: `
      Through our partnership with ${provider}, you have access to licensed clinical
      counselors to discuss more serious issues.
    `,
      providerText: `
      Walmart Health is comprised of independent physician practices. Walmart Health
      Virtual Care (WHVC) offers talk therapy services via telehealth to patients
      nationwide. Telehealth services may vary by state. Telehealth services are
      provided in accordance with state law by licensed health care professionals,
      subject to the licensed professionals’ judgment. Talk therapy providers do not
      write prescriptions. WHVC is not an insurance product.
    `,
      providerImage: (
        <div className="w-80 h-48 p-8 bg-[#F6F6F6] rounded-lg shadow border border-neutral-200 grid place-items-center">
          <img className="min-w-full mb-3" src={Walmart} alt="Walmart Health Virtual Care" />
        </div>
      ),
      bannerImage: CounselingDetailPng,
    };
  } else {
    return {
      name: '+Counseling',
      description: 'Access to licensed counselors, therapists, and psychiatrists.',
      longDescription: (
        <div className="flex flex-col gap-y-4">
          Your benefit includes virtual access for you and your family to connect with licensed counselors, therapists,
          and psychiatrists for ongoing conditions. Visits are available within 48 hours.
          <div>ONLINE ACCESS: Click the link below to schedule a visit online.</div>
          <div>
            PHONE ACCESS: Schedule a visit by phone by calling <div>855-6RECURO (855-673-2876)</div>
          </div>
        </div>
      ),
      providerText:
        'PLUS services are provided independently by Recuro Health. © 2023 Recuro Health, Inc. All rights reserved. Recuro Health, Recuro, and the Recuro logo are trademarks of Recuro Health, Inc. and may not be used without written permission. Recuro Health does not guarantee that a physician will write a prescription, and physicians do not prescribe DEA controlled substances, non-therapeutic drugs and certain other drugs which may be harmful because of their potential for abuse. Recuro Health affiliated physicians reserve the right to deny care for potential misuse of services. Medical services provided by physicians are subject to their professional judgment. Recuro Health operates subject to state regulation and some services may not be available in certain states.',
      providerImage: (
        <div className="w-80 h-48 p-8 bg-[#F6F6F6] rounded-lg shadow border border-neutral-200 grid place-items-center">
          <img className="min-w-full mb-3" src={RecuroLogo} alt="Recuro Crisis Plan" />
        </div>
      ),
      bannerImage: PLUSCounseling,
    };
  }
};

export const CounselingPageMobile: React.FC<TeleHealthResourceProps> = ({
  providerPlan,
  partnerResourceConfig,
  ssoLinkUrl,
  ssoLinkUrlPending,
}) => {
  const navigate = useHistory();

  const dfMdMedia = useMediaQuery('md');

  const provider = providerPlan?.provider;

  const resourceData = useMemo(() => getProviderData(provider), [provider]);

  const getCTAContent = () => {
    if (partnerResourceConfig.needsAddress) {
      return (
        <>
          <div className="text-gray-800 text-sm font-medium leading-tight">
            We need your home address in order to activate this service.
          </div>
          <div className="mt-3">
            <Button
              slim
              variant="secondary"
              onClick={() => {
                navigate.push(ROUTE_PATH.PROFILE);
              }}
            >
              Add Address
            </Button>
          </div>
        </>
      );
    }

    if (partnerResourceConfig.waitForProcessingDate) {
      return (
        <div className="text-gray-800 text-sm font-medium leading-tight">
          You will have access to +Counseling beginning on {partnerResourceConfig.processingDateString}
        </div>
      );
    }

    let ctaButtonText = 'Loading...';

    if (!ssoLinkUrlPending) {
      ctaButtonText = provider === 'recuro' ? 'Schedule Visit Online' : 'Schedule A Call';
    }

    return (
      <div className="flex flex-col gap-y-4 gap-x-4 justify-between">
        <a href={ssoLinkUrl} target="_blank" rel="noopener noreferrer">
          <Button className="w-80" variant="primary" disabled={ssoLinkUrlPending}>
            {ctaButtonText}
          </Button>
        </a>
        {provider === 'recuro' && (
          <a href="tel:8556732876">
            <Button className="w-80" variant="primary">
              Schedule Visit by Phone
            </Button>
          </a>
        )}
      </div>
    );
  };

  return (
    <div className="w-full min-h-screen bg-neutral-700 bg-opacity-5 pb-20">
      <DesktopMenuHeader />
      <MobileMenuHeader prompt={true} onBack={() => navigate.goBack()} />
      <div className="hidden md:block max-w-7xl px-6 mx-auto">
        <SubHeader
          className="hidden md:block pt-6 pb-8"
          title={
            <>
              <span className="font-normal">Need to talk with</span> {resourceData.name}?
            </>
          }
          onBackButtonClick={dfMdMedia ? () => navigate.goBack() : undefined}
        />
        <div className="flex gap-6">
          <FormBlock className="flex justify-between gap-12 space-y-0">
            <div className="font-manrope text-dark">
              <p className="text-xl font-bold">{resourceData.name}</p>
              <p className="font-medium mt-2">{resourceData.description}</p>
              <p className="font-medium mt-2">{resourceData.longDescription}</p>
              <div className="mt-8">{getCTAContent()}</div>
            </div>
            <img className="rounded-lg max-w-72 self-start" src={resourceData.bannerImage} alt={resourceData.name} />
          </FormBlock>
          <FormBlock className="max-w-100">
            <p className="text-xl font-bold">Resource sponsor</p>
            <div className="py-2 px-8 mt-3">{resourceData.providerImage}</div>
            <div className="mt-3 text-gray-800 text-sm font-medium leading-tight">{resourceData.providerText}</div>
          </FormBlock>
        </div>
      </div>
      <div className="w-full h-full overflow-y-auto flex-col gap-y-4 grid place-items-center md:hidden">
        <div className="flex-col gap-y-4 grid place-items-center max-w-lg">
          <div className="bg-white min-w-full px-5 py-5">
            <div className="justify-start items-center inline-flex">
              <img src={resourceData.bannerImage} className="w-full rounded-lg" alt="physician-img" />
            </div>
            <div className="self-stretch text-gray-800 text-4xl font-bold leading-10 mt-3">{resourceData.name}</div>
            <div className="self-stretch text-gray-800 text-xl font-bold leading-normal mt-3">
              {resourceData.description}
            </div>
            <div className="self-stretch text-gray-800 text-base font-medium leading-normal mt-3">
              {resourceData.longDescription}
            </div>
          </div>
          <div className="bg-white min-w-full px-5 py-5 grid place-items-center">{getCTAContent()}</div>
          <div className="bg-white px-5 py-5 grid place-items-center pb-20">
            <div className="min-w-full h-7 text-gray-800 text-xl font-bold leading-loose">Powered by</div>
            <div className="py-2 px-8 mt-3">{resourceData.providerImage}</div>
            <div className="mt-3 text-gray-800 text-sm font-medium leading-tight">{resourceData.providerText}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
